import React from 'karet'
import { loc$ } from 'utils/i18n'
import K, * as U from 'karet.util'
import { Loader } from 'components'
import styles from './Button.css'

export default ({
  label,
  disabled,
  isLoading,
  onClick,
  className,
  large,
  noMargin,
  fullWidth,
  selected,
  theme = 'blue',
}) => (
  <div
    onClick={K(
      onClick,
      disabled,
      isLoading,
      (onClick, disabled, isLoading) => () =>
        !disabled && !isLoading && onClick && onClick()
    )}
    {...U.classes(
      className,
      styles.button,
      styles[`theme-${theme}`],
      U.ift(disabled, styles.disabled),
      U.ift(noMargin, styles.noMargin),
      U.ift(fullWidth, styles.fullWidth),
      U.ift(large, styles.large),
      U.ift(selected, styles.selected)
    )}
  >
    <span {...U.classes(U.ift(isLoading, styles.hidden))}>
      {label && loc$(label)}
    </span>
    {U.ift(
      isLoading,
      <Loader className={styles.loader} size={18} theme="white" />
    )}
  </div>
)
