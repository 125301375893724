import React from 'karet'
import K, * as U from 'karet.util'
import styles from './DateInput.css'

export default ({
  className,
  value,
  valid,
  disabled,
  onChange,
  setFocusableInput
}) => (
  <div {...U.classes(styles.dateinput, className, U.ift(U.not(valid), styles.invalid))}>
    <input
      ref={setFocusableInput}
      onChange={K(onChange, onChange => e => onChange(e.target.value))}
      type="text"
      value={value}
      disabled={disabled}
    />
  </div>
)
