export default [
  {
    isIndex: true,
    path: '/invoice',
    localizationKey: 'invoice.invoicesList'
  },
  {
    path: '/invoice/new',
    localizationKey: 'invoice.newInvoice'
  },
  {
    isAdmin: true,
    path: '/invoice/summary',
    localizationKey: 'invoice.summary.title'
  }
]
