import React from 'karet'
import K, * as U from 'karet.util'
import { waitForPromises } from 'utils/containers'
import { lang$ } from 'stores/user'
import { loc$, date$ } from 'utils/i18n'
import styles from './Home.css'
import * as api from 'api'
import moment from 'moment'

export default waitForPromises(
  () => ({
    files: api.getFilesByType('instructions')
  }),
  ({ files }) => {
    const files$ = K(
      files, lang$,
      (files, lang) => files
        .filter(({ translations }) => translations[lang])
        .map(({ translations, ...rest }) => ({
          ...rest,
          ...translations[lang]
        }))
        .sort((a, b) => moment(b.createdAt).diff(a.createdAt))
    )
    return (
      <div>
        <h1>{loc$('home.instructions.title')}</h1>
        {U.ifte(
          U.isEmpty(files$),
          <p>{loc$('home.instructions.noFiles')}</p>,
          (
            <div className={styles.files}>
              {U.seq(files$, U.mapIndexed((file, idx) => (
                <div key={idx} className={styles.file}>
                  <div><strong>{date$(file.createdAt)}</strong></div>
                  <a href={file.upload.url} target="_blank">{file.title}</a>
                </div>
              )))}
            </div>
          )
        )}
      </div>
    )
  }
)
