import React from 'karet'
import K, * as U from 'karet.util'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import { ProfileInfo, Button } from 'components'
import * as joinPayment from 'stores/join-payment'

export default ({ personalData, payment }) => {
  return (
    <div>
      <h1>{loc$('join.payTitle')}</h1>
      <p className={styles.description}>
        {loc$('join.payDescription')}
      </p>

      <h2>{loc$('join.personalData')}</h2>
      <ProfileInfo profile={personalData} joinFieldsOnly={true} />
      <Button label="join.editPersonalDataButton" onClick={() => joinPayment.goBackToEditPersonalData()} />
      
      <h2>{loc$('join.continueToPay')}</h2>
      <div className={styles.providers}>
        {U.seq(payment, U.view('providers'), U.mapIndexed((provider, idx) => (
          <form key={idx} method="POST" action={provider.url} className={styles.provider}>
            {provider.parameters.map(({ name, value }) => (
              <input key={name} type="hidden" name={name} value={value} />
            ))}
            <button className={styles.providerButton}>
              <img className={styles.providerLogo} src={provider.svg} />
            </button>
          </form>
        )))}
      </div>
    </div>
  )
}
