import React from 'karet'

export default function() {
  return (
    <div>
      <h1>TERVETULOA MEDIAKUNNAN JÄSENPALVELUJÄRJESTELMÄÄN!</h1>

      <p>Tervetuloa Mediakunnan sisäiseen jäsenpalvelujärjestelmään, joka on osuuskunnan sydän. Järjestelmän kautta voit hoitaa laskutuksen ja maksattaa palkan tilillesi. Osuuskunnan käyttäjät hoitavat kaiken asioinnin tämän järjestelmän kautta, eikä niitä voi hoitaa sähköpostitse tai puhelimitse.</p>

      <p>Näin palvelumme toimii:</p>
      <ol>
        <li>Täytä profiilitiedot huolella (tarkista esim. sähköpostiosoitteesi ja lisää viimeisin verokorttisi).</li>
        <li>Sovi työstä asiakkaasi kanssa.</li>
        <li>Tee sovitut työt ja luo uusi lasku jäsenpalvelunjärjestelmän kautta.</li>
        <li>Mediakunta laskuttaa palvelun asiakkaalta.</li>
        <li>Asiakas maksaa laskun.</li>
        <li>Saat työsopimuksen sähköpostiisi.</li>
        <li>Maksamme palkan tilillesi, silloin kuin haluat.</li>
      </ol>

      <p>Järjestelmän käytöstä ohjeistetaan tarkemmin palvelun yhteydessä. Ongelmatilanteissa lähetä viesti osoitteeseen: <a href="mailto:mediakunta@mediakunta.fi">mediakunta@mediakunta.fi</a>.</p>

      <p>Haluatko lähettää palautetta järjestelmästä? <a href="http://mediakunta.fi/ota-yhteytta/">Ota meihin yhteyttä</a>.</p>
    </div>
  )
}
