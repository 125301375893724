import React from 'karet'
import K, * as U from 'karet.util'
import { loc$, datetime$ } from 'utils/i18n'
import { replaceLink } from 'utils/misc'
import styles from './Invoices.css'

function formatEntry$(invoice, { event, data }) {
  switch (event) {
    case 'created':
      if (invoice.isCreditNote) {
        const originalInvoiceId = invoice.originalInvoiceId
        return replaceLink(
          loc$('invoice.history.events.creditNoteCreated', {
            originalInvoiceId,
            reason: invoice.creditReason,
          }),
          U.string`/invoice/view/${originalInvoiceId}`,
          false
        )
      } else {
        return loc$('invoice.history.events.created', {
          name: `${data.user.firstName} ${data.user.lastName}`,
        })
      }
    case 'edited':
      return loc$('invoice.history.events.edited', {
        name: `${data.user.firstName} ${data.user.lastName}`,
      })
    case 'approved':
      return loc$('invoice.history.events.approved', {
        name: `${data.user.firstName} ${data.user.lastName}`,
      })
    case 'rejected':
      return loc$('invoice.history.events.rejected', {
        name: `${data.user.firstName} ${data.user.lastName}`,
        reason: data.reason,
      })
    case 'billed':
      if (invoice.deliveryMethod === 'email') {
        return loc$('invoice.history.events.deliveredToEmail', {
          email: invoice.deliveryEmail,
        })
      } else if (invoice.deliveryMethod === 'letter') {
        return loc$('invoice.history.events.deliveredLetter')
      } else {
        if (invoice.postitaIsEinvoice) {
          return loc$('invoice.history.events.deliveredEinvoice')
        } else {
          return loc$('invoice.history.events.deliveredEinvoiceFallback', {
            email: invoice.reminderDeliveryEmail,
          })
        }
      }
    case 'marked_as_billed':
      return loc$('invoice.history.events.markedAsBilled', {
        name: `${data.user.firstName} ${data.user.lastName}`,
      })
    case 'payment':
      return loc$('invoice.history.events.payment', {
        amount: data.amount,
        currency: data.currency,
      })
    case 'paid':
    case 'marked_as_paid':
      return loc$('invoice.history.events.markedAsPaid', {
        name: `${data.user.firstName} ${data.user.lastName}`,
      })
    case 'canceled':
      return loc$('invoice.history.events.canceled', {
        name: `${data.user.firstName} ${data.user.lastName}`,
      })
    case 'reminded':
      if (invoice.deliveryMethod !== 'letter') {
        return loc$('invoice.history.events.remindedViaEmail', {
          email: invoice.reminderDeliveryEmail || invoice.deliveryEmail,
        })
      } else {
        return loc$('invoice.history.events.remindedViaLetter')
      }
    case 'credited':
      const { creditInvoiceId, reason } = data
      return replaceLink(
        loc$('invoice.history.events.credited', {
          creditInvoiceId,
          reason,
        }),
        U.string`/invoice/view/${creditInvoiceId}`,
        false
      )
    case 'credit_note_sent':
      if (invoice.deliveryMethod === 'email') {
        return loc$('invoice.history.events.creditNoteDeliveredToEmail', {
          email: invoice.deliveryEmail,
        })
      } else if (invoice.deliveryMethod === 'letter') {
        return loc$('invoice.history.events.creditNoteDeliveredLetter')
      } else {
        if (invoice.postitaIsEinvoice) {
          return loc$('invoice.history.events.creditNoteDeliveredEinvoice')
        } else {
          return loc$(
            'invoice.history.events.creditNoteDeliveredEinvoiceFallback'
          )
        }
      }
  }
  return event
}

export default ({ invoice }) => (
  <div>
    <h2>{loc$('invoice.history.title')}</h2>
    <table className={styles.invoiceHistory}>
      <thead>
        <tr>
          <th>{loc$('invoice.history.time')}</th>
          <th>{loc$('invoice.history.event')}</th>
        </tr>
      </thead>
      <tbody>
        {K(invoice, invoice =>
          invoice.log.map((entry, idx) => (
            <tr key={idx}>
              <td>{datetime$(entry.time)}</td>
              <td>{formatEntry$(invoice, entry)}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
)
