import React from 'karet'
import { loc$ } from 'utils/i18n'
import { waitForPromises } from 'utils/containers'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, TextArea, FileUploadInput } from 'utils/forms/controls'
import { showApiRequestError } from 'utils/errors'
import * as api from 'api'
import * as schema from 'shared/schema'
import { addToast } from 'stores/toasts'
import { updateProfile } from 'stores/user'
import { navigateTo } from 'stores/navigation'
import styles from './Profile.css'

const onSubmit =
  values => showApiRequestError(async () => {
    await updateProfile(values)
    addToast('success', 'profile.editSuccess')
    navigateTo('/profile')
  })

export default waitForPromises(
  () => ({ profile: api.getProfile() }),
  ({ profile }) => (
    <div>
      <h1>{loc$('profile.editHeader')}</h1>
      <Form
        schema={schema.profile}
        initialValues={profile}
        className={styles.form}
        onSubmit={onSubmit}
      >
        <FormField name="firstName">
          <TextInput label="user.info.firstName" />
        </FormField>
        <FormField name="lastName">
          <TextInput label="user.info.lastName" />
        </FormField>
        <FormField name="ssn">
          <TextInput label="user.info.ssn" />
        </FormField>
        <FormField name="phoneNumber">
          <TextInput label="user.info.phoneNumber" />
        </FormField>
        <FormField name="streetAddress">
          <TextInput label="user.info.streetAddress" />
        </FormField>
        <FormField name="postalCode">
          <TextInput label="user.info.postalCode" />
        </FormField>
        <FormField name="postOffice">
          <TextInput label="user.info.postOffice" />
        </FormField>
        <FormField name="bankIban">
          <TextInput label="user.info.bankIban" />
        </FormField>
        <FormField name="bankBic">
          <TextInput label="user.info.bankBic" />
          <div className={styles.inputDescription}>
            {loc$('user.info.bankBicDescription')}
          </div>
        </FormField>
        <FormField name="introduction">
          <TextArea label="user.info.introduction" />
        </FormField>
        <FormField name="taxCard">
          <FileUploadInput label="user.info.taxCard" folder="taxcard" extensions={['jpg', 'jpeg', 'png', 'pdf']} />
        </FormField>
        <FormSubmit>
          <Button label="profile.saveInfo" large />
        </FormSubmit>
      </Form>
    </div>
  )
)
