import React from 'karet'
import styles from './Toast.css'
import * as U from 'karet.util'
import { loc$ } from 'utils/i18n'

export default ({type, message, params, onClick}) => (
  <div
    {...U.classes(
      styles.toast,
      U.ifte(U.equals(type, 'success'),
        styles.success,
        styles.error)
    )}
    onClick={onClick}
  >
    <p>{loc$(message, params)}</p>
  </div>
)
