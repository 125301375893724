import React from 'karet'
import Kefir from 'kefir'
import { waitForPromises, withUnmount$ } from 'utils/containers'
import { loc, loc$ } from 'utils/i18n'
import { Button, Link } from 'components'
import { getReimbursement } from 'api'
import { isAdmin$ } from 'stores/user'
import { addToast } from 'stores/toasts'
import { createAction, persistentProperty } from 'utils/store'
import ReimbursementForm from './ReimbursementForm.jsx'
import * as api from 'api'
import * as U from 'karet.util'
import styles from './Reimbursements.css'

export default waitForPromises(
  ({ params: { reimbursementId }}) => ({ reimbursement: getReimbursement(reimbursementId) }),
  withUnmount$(({ reimbursement, unmount$ }) => {

    const [ approveReimbursement, approveReimbursement$ ] = createAction(() => api.approveReimbursement(reimbursement.id))
    const [ rejectReimbursement, rejectReimbursement$ ] = createAction(reason => api.rejectReimbursement(reimbursement.id, reason))

    const reimbursement$ = persistentProperty(Kefir.merge([
      Kefir.constant(reimbursement),
      approveReimbursement$.ignoreErrors(),
      rejectReimbursement$.ignoreErrors()
    ]).takeUntilBy(unmount$))

    const status$ = U.view('status', reimbursement$)

    return (
      <div>
        <div className={styles.splitHeader}>
          <h1>Reimbursement #{reimbursement.id}</h1>
          <div className={styles.controls}>
            {U.ift(U.and(isAdmin$, U.equals(status$, 'new')), (
              <Link to={`/reimbursement/edit/${reimbursement.id}`}>
                <Button
                  className={styles.button}
                  label="reimbursements.editReimbursement"
                  theme="blue"
                  noMargin
                />
              </Link>
            ))}
            {U.ift(U.and(isAdmin$, U.equals(status$, 'new')), (
              <Button
                onClick={() => {
                  if (confirm(loc('reimbursements.confirmApprove'))) {
                    approveReimbursement().then(() => addToast('success', 'reimbursements.approveSuccess'))
                  }
                }}
                className={styles.button}
                label="reimbursements.approve"
                theme="green"
                noMargin
              />
            ))}
            {U.ift(U.and(isAdmin$, U.equals(status$, 'new')), (
              <Button
                onClick={() => {
                  const reason = prompt(loc('reimbursements.confirmReject'))
                  if (reason) {
                    rejectReimbursement(reason).then(() => addToast('success', 'reimbursements.rejectSuccess'))
                  }
                }}
                className={styles.button}
                label="reimbursements.reject"
                theme="red"
                noMargin
              />
            ))}
          </div>
        </div>

        <ReimbursementForm
          initialValues={reimbursement}
          disabled={true}
        />
      </div>
    )
  })
)
