import R from 'ramda'
import Promise from 'bluebird'
import * as validatorFactories from '../validators'

// this is a hack to prevent bluebird from throwing warnings
function ValidationError(error) {
  this.error = error
}

ValidationError.prototype = Object.create(Error.prototype)
ValidationError.prototype.constructor = ValidationError

export default async function(val, schema, context = {}, data = {}) {
  if (R.isNil(val) || val.length === 0 || val === false) {
    const required = typeof schema.required === 'function'
      ? schema.required(data, context)
      : schema.required
    if (required) {
      return { valid: false, errors: [{ reason: 'REQUIRED' }] }
    } else {
      return { valid: true, errors: [] }
    }
  }
  
  const validators = schema.validators || []
  const validatorFns =
    validators
      .map(it => Array.isArray(it) ? it : [it])
      .map(([validator, params = {}]) => {
        const factory = validatorFactories[validator]
        if (!factory) {
          throw new Error(`invalid validator "${validator}"`)
        }
        const validatorFn = factory(params, context)
        return validatorFn
      })

  return await Promise.mapSeries(validatorFns, async validatorFn => {
    const validity = await validatorFn(val, data)
    if (!validity.valid) {
      throw new ValidationError(validity.error)
    }
  })
  .then(() => ({ valid: true, errors: [] }))
  .catch(ValidationError, ({error}) => ({ valid: false, errors: [error] }))
}
