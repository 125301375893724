import React from 'karet'
import * as U from 'karet.util'
import LanguageSelector from './LanguageSelector.jsx'
import { Link } from 'components'
import styles from './Header.css'
import { isLoggedIn$, me$, profilePicUrl$, logout } from 'stores/user'
import { navigateTo } from 'stores/navigation'

const onLogout = () => {
  logout().then(() => navigateTo('/'))
}

export default ({ linkToHome = true }) => (
  <div className={styles.header}>
    <div className={styles.container}>
      <h1
        {...U.classes(styles.logo, U.ift(linkToHome, styles.link))}
        onClick={U.ift(linkToHome, () => navigateTo('/'))}
      />
      <div className={styles.tools}>
        <LanguageSelector styles={styles} />
        {U.ift(isLoggedIn$, (
          <div className={styles.profile}>
            <Link to="/profile">
              <div>
                <div
                  className={styles.avatar}
                  style={{ backgroundImage: U.string`url(${profilePicUrl$})` }}
                />
                { me$.map(user => `${user.firstName} ${user.lastName}`) }
              </div>
            </Link>
          </div>
        ))}
        {U.ift(isLoggedIn$, (
          <div className={styles.logout}>
            <a onClick={onLogout}></a>
          </div>
        ))}
      </div>
    </div>
  </div>
)
