import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import { withEvents } from 'utils/containers'
import { loc$ } from 'utils/i18n'
import { login } from 'stores/user'
import { classNames as cx } from 'utils'
import styles from './Login.css'
import { addToast } from 'stores/toasts'
import { lang$, changeLanguageLocally } from 'stores/user'
import { navigateTo } from 'stores/navigation'

// TODO: handle onChange events in a nicer way
export default withEvents(({ events }) => {
  const email$ = Kefir.fromEvents(events, 'email:change').toProperty(R.always(''))
  const password$ = Kefir.fromEvents(events, 'password:change').toProperty(R.always(''))
  const onSubmit = e => {
    Kefir.combine([email$, password$])
      .take(1)
      .onValue(([email, password]) =>
        login(email, password)
          .then(() => navigateTo('/'))
          .then(() => addToast('success', 'login.success'))
          .catch(err => addToast('error', `errors.${err.code}`)))
    e.preventDefault()
  }

  const submitRowClasses = cx([styles.row, styles.rowSubmit])

  const availableLanguages = ['en', 'fi', 'sv']

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.login}>
        <h1 className={styles.logo}></h1>
        <h1>{loc$('login.login')}</h1>

        <form onSubmit={onSubmit}>
          <div className={styles.row}>
            <label>{loc$('login.email')}</label>
            <input type="text" value={email$} onChange={(evt) => events.emit('email:change', evt.target.value)} />
          </div>
          <div className={styles.row}>
            <label>{loc$('login.password')}</label>
            <input type="password" value={password$} onChange={(evt) => events.emit('password:change', evt.target.value)} />
          </div>
          <div className={submitRowClasses}>
            <button type="submit">{loc$('login.submit')}</button>
          </div>
        </form>
        <p style={{textAlign: 'center'}}><a href="javascript:alert('Mikäli olette unohtaneet salasananne, ole hyvä ja ota yhteyttä sähköpostitse mediakunta[at]mediakunta[piste]fi');">{loc$('login.forgottenPassword')}</a></p>

        <div className={styles.languages}>
          { lang$.map(currentLang =>
              availableLanguages.map((lang, idx) => (
                <a
                  className={cx([styles.language, currentLang === lang && styles.selected])}
                  onClick={() => changeLanguageLocally(lang)}
                  key={idx}>
                    {loc$(`languages.${lang}`)}
                </a>
              ))
            )
          }
        </div>
      </div>
    </div>
  )
})
