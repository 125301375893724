import React from 'karet'
import styles from './LoadingIndicator.css'

export default () => {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingBall}>
        <div className={styles.loadingBall1}></div>
        <div className={styles.loadingBall2}></div>
        <div className={styles.loadingBall3}></div>
      </div>
    </div>
  )
}
