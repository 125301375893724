import React from 'karet'
import K, * as U from 'karet.util'
import { Link } from 'components'
import { loc$, currency$ } from 'utils/i18n'
import { isAdmin$, balanceAfterFees$ } from 'stores/user'

export default ({ styles }) => (
    <div className={styles.navigation}>
      <div className={styles.container}>
        <Link to="/" activeClassName={styles.active}>{loc$('navigation.home')}</Link>
        {U.ift(isAdmin$, <Link to="/user" activeClassName={styles.active}>{loc$('navigation.users')}</Link>)}
        <Link to="/invoice" activeClassName={styles.active}>{loc$('navigation.invoices')}</Link>
        {U.ift(isAdmin$, <Link to="/reimbursement" activeClassName={styles.active}>{loc$('navigation.reimbursements')}</Link>)}
        <Link to="/contract" activeClassName={styles.active}>{loc$('navigation.contracts')}</Link>
        <Link to="/profile" activeClassName={styles.active}>{loc$('navigation.profile')}</Link>
        <Link to="/balance" className={styles.balance} activeClassName={styles.active}>
          {K(loc$('navigation.balance'), currency$(balanceAfterFees$), (loc, balance) =>
            `${loc}: ${balance}`
          )}
        </Link>
      </div>
    </div>
)
