import React, { fromKefir } from 'karet'
import K from 'karet.util'
import { waitForPromises } from 'utils/containers'
import { markdown } from 'markdown'
import { lang$ } from 'stores/user'
import * as api from 'api'
import styles from './InvoiceInstructions.css'

const InvoiceInstructions = waitForPromises(
    ({ lang }) => ({ instructions: api.getInvoiceInstructions(lang) }),
    ({ instructions }) => (
        <div className={styles.instructions} dangerouslySetInnerHTML={{ __html: markdown.toHTML(instructions) }} />
    ),
    (oldProps, newProps) => oldProps.lang !== newProps.lang
)

export default () => fromKefir(K(lang$, lang => <InvoiceInstructions lang={lang} />))
