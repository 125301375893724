import Decimal from 'decimal.js'
import { validateSeries } from 'shared/utils'

export default function({ minimumWithdraw = 0 }, { balanceAfterFees, reimbursements }) {
  return validateSeries(
    [amount => new Decimal(balanceAfterFees).greaterThanOrEqualTo(amount), 'NOT_ENOUGH_BALANCE'],
    [amount => new Decimal(amount).greaterThanOrEqualTo(minimumWithdraw), 'MINIMUM_WITHDRAW_NOT_MET', { minimumWithdraw }],
    [amount =>
      reimbursements
        ? new Decimal(amount).greaterThanOrEqualTo(reimbursements)
        : true,
      'MINIMUM_WITHDRAW_MUST_EXCEED_REIMBURSEMENTS',
      { reimbursements }
    ]
  )
}
