import moment from 'moment-timezone'

export default ssn => {
  if (!ssn) {
    return null
  }
  const match = ssn.toUpperCase().match(/^(\d{2})(\d{2})(\d{2})([-A])/)
  if (!match) {
    return null
  }
  const [ , day, month, shortYear, type ] = match
  const year = type === 'A' ? `20${shortYear}` : `19${shortYear}`
  const birthday = moment(`${year}-${month}-${day}`)
  birthday.add(1, 'month')
  birthday.date(1)
  return moment.utc().diff(birthday, 'years')
}
