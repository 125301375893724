import React from 'karet'

export default function() {
  return (
    <div>
      <h1>WELCOME TO THE MEDIAKUNTA MEMBERS’ SERVICE</h1>

      <p>Welcome to the Mediakunta members’ service, which is the core of the cooperative. You can use the system manage your billing and pay your salary to your account. The cooperative’s members use the system for to handle all billing and related matters, as these cannot be done by email or phone.</p>

      <p>This is how the service works:</p>
      <ol>
        <li>Fill out the profile information carefully (check your email address and add your latest tax card).</li>
        <li>Agree on your work assignments with your client.</li>
        <li>Do the agreed work and create a new invoice using the members’ service system.</li>
        <li>Mediakunta invoices the client.</li>
        <li>The customer pays the invoice.</li>
        <li>You receive the work contract by email.</li>
        <li>We pay your salary to your bank account whenever you want.</li>
      </ol>

      <p>More detailed instructions on using the service are provided when using it. In case of problems, contact us at <a href="mailto:mediakunta@mediakunta.fi">mediakunta@mediakunta.fi</a>.</p>

      <p>You are welcome to <a href="http://mediakunta.fi/ota-yhteytta/">contact us</a> with any feedback about the service.</p>
    </div>
  )
}
