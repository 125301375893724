module.exports={
  "join": {
    "steps": {
      "personalData": {
        "en": "Fill in your personal information",
        "fi": "Täytä henkilötiedot",
        "sv": ""
      },
      "paymentMethod": {
        "en": "Choose a payment method",
        "fi": "Valitse maksutapa",
        "sv": ""
      },
      "pay": {
        "en": "Pay the subscription fee",
        "fi": "Maksa liittymismaksu",
        "sv": ""
      },
      "done": {
        "en": "Welcome, new member!",
        "fi": "Tervetuloa jäseneksi",
        "sv": ""
      }
    },
    "title": {
      "en": "JOIN AS MEMBER",
      "fi": "LIITY JÄSENEKSI",
      "sv": ""
    },
    "personalDataTitle": {
      "en": "Fill in your personal information",
      "fi": "Täytä henkilötiedot",
      "sv": ""
    },
    "personalDataDescription": {
      "en": "Mediakunta is a co-operative of the Finnish Journalism Association, where the member represents his share. If you are not yet a member of the Journalist Association, <link>first join this</link>. The next step is to pay the cooperative capital and the subscription fee in the online banking system. After that, the services are at your disposal.",
      "fi": "Mediakunta on Suomen Journalistiliiton osuuskunta, jossa jäsen merkitsee osuutensa. Mikäli et kuulu vielä Journalistiliittoon, <link>liity ensiksi jäseneksi tästä</link>. Seuraavassa vaiheessa maksetaan osuuspääoma ja liittymismaksu verkkopankissa. Tämän jälkeen palvelut ovat käytössäsi.",
      "sv": ""
    },
    "verifyBelongToJournalistiliitto": {
      "en": "I verify that I am a member of the Association of Journalists",
      "fi": "Vakuutan, että olen journalistiliiton jäsen",
      "sv": ""
    },
    "verifyMemberAgreement": {
      "en": "I accept the <link>membership agreement</link>",
      "fi": "Hyväksyn <link>jäsensopimuksen</link>",
      "sv": ""
    },
    "verifyCooperativeRules": {
      "en": "I accept <link>Cooperative Rules</link>",
      "fi": "Hyväksyn <link>osuuskunnan säännöt</link>",
      "sv": ""
    },
    "continueToPay": {
      "en": "Go to payment",
      "fi": "Siirry maksamaan",
      "sv": ""
    },
    "personalData": {
      "en": "Personal information",
      "fi": "Henkilötiedot",
      "sv": ""
    },
    "payTitle": {
      "en": "Choose a payment method",
      "fi": "Valitse maksutapa",
      "sv": ""
    },
    "payDescription": {
      "en": "If your information is correct, please click on the payment method you selected to pay the subscription fee.",
      "fi": "Jos tietosi ovat täytetty oikein, siirry maksamaan liittymismaksu painamalla valitsemaasi maksutapakuvaketta.",
      "sv": ""
    },
    "editPersonalDataButton": {
      "en": "Edit information",
      "fi": "Muokkaa tietoja",
      "sv": ""
    },
    "productDescription": {
      "en": "Mediakunta subscription fee",
      "fi": "Mediakunnan liittymismaksu",
      "sv": ""
    },
    "thankYouTitle": {
      "en": "Welcome, new member!",
      "fi": "Tervetuloa jäseneksi",
      "sv": ""
    },
    "thankYouDescription": {
      "en": "We have sent your sign-in instructions and credentials to your system via email. If you do not receive the email in the next few hours, please contact customer service by email mediakunta@mediakunta.fi.",
      "fi": "Olemme lähettäneet teille kirjautumisohjeet ja tunnukset järjestelmään sähköpostiin. Jos et saa sähköpostia lähitunteina niin ota yhteyttä asiakaspalveluun sähköpostitse mediakunta@mediakunta.fi.",
      "sv": ""
    },
    "goToLoginButton": {
      "en": "Please log in",
      "fi": "Siirry kirjautumaan",
      "sv": ""
    },
    "cancelTitle": {
      "en": "Subscription fee canceled",
      "fi": "Liittymismaksu peruttu",
      "sv": ""
    },
    "cancelDescription": {
      "en": "Your payment has been successfully canceled.",
      "fi": "Maksusi on peruttu onnistuneesti.",
      "sv": ""
    },
    "backToBeginningButton": {
      "en": "Go back to the beginning",
      "fi": "Siirry takaisin alkuun",
      "sv": ""
    },
    "errorTitle": {
      "en": "Error in joining",
      "fi": "Virhe jäseneksi liittymisessä",
      "sv": ""
    },
    "errorDescription": {
      "en": "Something went wrong when joining. If the charge is not charged then please try again. If the fee was charged or the problem continues, please contact mediakunta@mediakunta.fi. Please include your error code <strong>\"${transactionId}\"</strong> so we can solve the problem more easily.",
      "fi": "Jokin meni pieleen liittymisessä. Jos maksua ei veloitettu niin koitathan uudelleen. Jos maksu veloitettiin tai ongelma jatkuu niin ota yhteyttä mediakunta@mediakunta.fi. Liitäthän viestiisi virhetunnisteen <strong>\"${transactionId}\"</strong> niin voimme selvittää ongelman helpommin.",
      "sv": ""
    }
  },
  "home": {
    "news": {
      "title": {
        "en": "News & current affairs",
        "fi": "Ajankohtaista",
        "sv": "Nyheter och aktuella ärenden"
      },
      "noFiles": {
        "en": "No news or current affairs",
        "fi": "Ei ajankohtaisia ilmoituksia",
        "sv": ""
      }
    },
    "instructions": {
      "title": {
        "en": "Instructions",
        "fi": "Ohjeet",
        "sv": "Instruktioner"
      },
      "noFiles": {
        "en": "No instructions",
        "fi": "Ei ohjeita",
        "sv": ""
      }
    }
  },
  "login": {
    "login": {
      "en": "Log in",
      "fi": "Kirjaudu sisään",
      "sv": "Logga in"
    },
    "email": {
      "en": "E-mail",
      "fi": "Sähköpostiosoite",
      "sv": "E-postadress"
    },
    "password": {
      "en": "Password",
      "fi": "Salasana",
      "sv": "Lösenord"
    },
    "submit": {
      "en": "Login",
      "fi": "Kirjaudu sisään",
      "sv": "Logga in"
    },
    "success": {
      "en": "Login successful",
      "fi": "Kirjautuminen onnistui",
      "sv": "Inloggningen lyckades"
    },
    "fail": {
      "en": "Login failed",
      "fi": "Kirjautuminen epäonnistui",
      "sv": "Inloggningen misslyckades"
    },
    "forgottenPassword": {
      "en": "Forgotten password?",
      "fi": "Unohtunut salasana?",
      "sv": "Har du glömt lösenordet?"
    }
  },
  "header": {
    "tools": {
      "siteLanguage": {
        "en": "Site language",
        "fi": "Sivun kieli",
        "sv": "Sidans språk"
      }
    }
  },
  "table": {
    "noRows": {
      "en": "No rows to be displayed",
      "fi": "Ei rivejä näytettävänä",
      "sv": "Inga rader att visa"
    }
  },
  "navigation": {
    "home": {
      "en": "Home",
      "fi": "Koti",
      "sv": "Hem"
    },
    "frontpage": {
      "en": "Frontpage",
      "fi": "Etusivu",
      "sv": "Framsida"
    },
    "news": {
      "en": "News",
      "fi": "Ajankohtaista",
      "sv": "Nyheter"
    },
    "manageFiles": {
      "en": "Manage files",
      "fi": "Hallinnoi tiedostoja",
      "sv": ""
    },
    "instructions": {
      "en": "Instructions",
      "fi": "Ohjeet",
      "sv": ""
    },
    "users": {
      "en": "Users",
      "fi": "Käyttäjät",
      "sv": "Användare"
    },
    "invoices": {
      "en": "Invoices",
      "fi": "Laskut",
      "sv": "Fakturor"
    },
    "reimbursements": {
      "en": "Reimbursements",
      "fi": "Kulukorvaukset",
      "sv": ""
    },
    "profile": {
      "en": "Profile",
      "fi": "Profiili",
      "sv": "Profil"
    },
    "balance": {
      "en": "My balance",
      "fi": "Oma saldo",
      "sv": "Balans"
    },
    "contracts": {
      "en": "Contracts",
      "fi": "Sopimukset",
      "sv": ""
    }
  },
  "languages": {
    "en": {
      "en": "English",
      "fi": "Englanti",
      "sv": "Engelska"
    },
    "fi": {
      "en": "Finnish",
      "fi": "Suomi",
      "sv": "Finska"
    },
    "sv": {
      "en": "Swedish",
      "fi": "Ruotsi",
      "sv": "Svenska"
    }
  },
  "user": {
    "usersList": {
      "en": "Users list",
      "fi": "Käyttäjälista",
      "sv": "Användarlista"
    },
    "newUser": {
      "en": "New user",
      "fi": "Uusi käyttäjä",
      "sv": "Ny användare"
    },
    "editUser": {
      "en": "Edit user",
      "fi": "Muokkaa käyttäjää",
      "sv": "Redigera användare"
    },
    "confirmDelete": {
      "en": "Are you sure you want to delete this user?",
      "fi": "Oletko varma että haluat poistaa käyttäjän?",
      "sv": "Är du säker på att du vill avlägsna användaren?"
    },
    "creationSuccess": {
      "en": "The user was created",
      "fi": "Käyttäjä luotu",
      "sv": "Användare skapad"
    },
    "editSuccess": {
      "en": "User information saved",
      "fi": "Käyttäjän tiedot tallennettu",
      "sv": "Användarens information har sparats"
    },
    "deleteSuccess": {
      "en": "The user was removed",
      "fi": "Käyttäjä poistettu",
      "sv": "Användaren avlägsnad"
    },
    "info": {
      "id": {
        "en": "#",
        "fi": "",
        "sv": ""
      },
      "name": {
        "en": "Name",
        "fi": "Nimi",
        "sv": "Namn"
      },
      "address": {
        "en": "Address",
        "fi": "Osoite",
        "sv": "Adress"
      },
      "language": {
        "en": "Language",
        "fi": "Kieli",
        "sv": "Språk"
      },
      "type": {
        "en": "Type",
        "fi": "Tyyppi",
        "sv": "Typ"
      },
      "admin": {
        "en": "Administrator",
        "fi": "Pääkäyttäjä",
        "sv": "Huvudanvändare"
      },
      "journalist": {
        "en": "Journalist",
        "fi": "Journalisti",
        "sv": "Journalist"
      },
      "email": {
        "en": "E-mail",
        "fi": "Sähköpostiosoite",
        "sv": "E-postadress"
      },
      "password": {
        "en": "Password",
        "fi": "Salasana",
        "sv": "Lösenord"
      },
      "passwordDescription": {
        "en": "The user will receive the password in his e-mail",
        "fi": "Käyttäjä saa salasanan sähköpostiinsa",
        "sv": "Användaren får ett lösenord till sin e-post"
      },
      "firstName": {
        "en": "First name",
        "fi": "Etunimi",
        "sv": "Förnamn"
      },
      "lastName": {
        "en": "Last name",
        "fi": "Sukunimi",
        "sv": "Efternamn"
      },
      "phoneNumber": {
        "en": "Phone number",
        "fi": "Puhelinnumero",
        "sv": "Telefonnummer"
      },
      "streetAddress": {
        "en": "Street address",
        "fi": "Katuosoite",
        "sv": "Adress"
      },
      "postalCode": {
        "en": "Postal code",
        "fi": "Postinumero",
        "sv": "Postnummer"
      },
      "postOffice": {
        "en": "Post office",
        "fi": "Postitoimipaikka",
        "sv": "Postort"
      },
      "isAdmin": {
        "en": "Administrator",
        "fi": "Ylläpitäjä",
        "sv": "Administratör"
      },
      "memberJoinedAt": {
        "en": "Joined at",
        "fi": "Liittymispäivä",
        "sv": "Medlem sedan"
      },
      "memberResignedAt": {
        "en": "Resigned at",
        "fi": "Eroamispäivä",
        "sv": "Resigned at"
      },
      "joined": {
        "en": "Joined",
        "fi": "Liittyi",
        "sv": "Anslöt sig"
      },
      "taxCard": {
        "en": "Tax card",
        "fi": "Verokortti",
        "sv": "Skattekort"
      },
      "taxPercentage": {
        "en": "Tax percentage",
        "fi": "Veroprosentti",
        "sv": "Skatteprocent"
      },
      "showTaxCard": {
        "en": "Show tax card",
        "fi": "Näytä verokortti",
        "sv": "Visa ditt skattekort"
      },
      "ssn": {
        "en": "Social security number",
        "fi": "Henkilötunnus",
        "sv": "Personnummer"
      },
      "bankIban": {
        "en": "Bank account (IBAN)",
        "fi": "Tilinumero (IBAN)",
        "sv": "Kontonummer (IBAN)"
      },
      "bankBic": {
        "en": "Bank BIC code",
        "fi": "Pankin BIC-koodi",
        "sv": "Bankens BIC-kod"
      },
      "bankBicDescription": {
        "en": "Only required if you use a foreign bank",
        "fi": "Täytä jos sinulla on ulkomaalainen pankki",
        "sv": "Fyll i endast om du har ett konto i utländsk bank"
      },
      "introduction": {
        "en": "Introduction text",
        "fi": "Esittelyteksti",
        "sv": ""
      },
      "jlMemberId": {
        "en": "Union of Journalists membership number",
        "fi": "Journalistiliiton jäsennumero",
        "sv": "Journalistförbundets medlemsnummer"
      },
      "picture": {
        "en": "Profile picture",
        "fi": "Profiilikuva",
        "sv": ""
      },
      "totalBilling": {
        "en": "Total billing",
        "fi": "Laskutus yhteensä",
        "sv": "Fakturerat totalt"
      },
      "pendingPayRequests": {
        "en": "Pay requests",
        "fi": "Palkkapyynnöt",
        "sv": ""
      }
    },
    "form": {
      "newUserSubmit": {
        "en": "Create user",
        "fi": "Luo käyttäjä",
        "sv": "Skapa en användare"
      },
      "editUserSubmit": {
        "en": "Edit user",
        "fi": "Muokkaa tietoja",
        "sv": "Redigera uppgifterna"
      }
    },
    "filters": {
      "searchDescription": {
        "en": "Filter users by name, email, ssn, etc",
        "fi": "Rajaa käyttäjiä nimen, sähköpostin, hetun, jne. mukaan",
        "sv": ""
      },
      "all": {
        "en": "All",
        "fi": "Kaikki",
        "sv": "Alla"
      },
      "actionNeeded": {
        "en": "Requires action",
        "fi": "Vaatii toimenpiteitä",
        "sv": ""
      },
      "waitingForPay": {
        "en": "Waiting for pay",
        "fi": "Odottaa palkanmaksua",
        "sv": ""
      },
      "isMember": {
        "en": "Member",
        "fi": "Jäsen",
        "sv": ""
      },
      "isNotMember": {
        "en": "Resigned",
        "fi": "Eronnut",
        "sv": ""
      }
    },
    "taxCardNotVerified": {
      "en": "The tax card of this user has not been verified. Please check the tax card and add the tax percentage for the user.",
      "fi": "Käyttäjän verokorttia ei ole tarkistettu. Tarkasta verokortti ja lisää käyttäjälle veroprosentti.",
      "sv": ""
    },
    "hasPayRequestsButTaxCardNotVerified": {
      "en": "This user has ${amount} € pay requests waiting for payment, but is missing tax percentage. Please check the tax card and add the tax percentage for the user first.",
      "fi": "Käyttäjällä on ${amount} € odottavia palkkapyyntöjä, mutta käyttäjän veroprosentti puuttuu. Tarkasta verokortti ja lisää veroprosentti ensin.",
      "sv": ""
    },
    "addTaxPercentage": {
      "en": "Add tax percentage",
      "fi": "Lisää veroprosentti",
      "sv": ""
    },
    "payRequestDate": {
      "en": "Date",
      "fi": "Päivämäärä",
      "sv": ""
    },
    "payRequestAmount": {
      "en": "Pay request",
      "fi": "Palkkapyyntö",
      "sv": ""
    },
    "payRequestTaxFreeShare": {
      "en": "Travel expenses",
      "fi": "Matkakulut",
      "sv": ""
    },
    "payRequestAgeForTaxing": {
      "en": "Age for taxing",
      "fi": "Ikä verotuksessa",
      "sv": ""
    },
    "payRequestTaxPercentage": {
      "en": "Tax percentage",
      "fi": "Veroprosentti",
      "sv": ""
    },
    "showPayslip": {
      "en": "Show payslip",
      "fi": "Näytä palkkalaskelma",
      "sv": ""
    },
    "closePayslip": {
      "en": "Close payslip",
      "fi": "Sulje palkkalaskelma",
      "sv": ""
    },
    "markPayRequestAsPaid": {
      "en": "Mark as paid",
      "fi": "Merkitse maksetuksi",
      "sv": ""
    },
    "rejectPayRequest": {
      "en": "Reject pay request",
      "fi": "Hylkää palkkapyyntö",
      "sv": ""
    },
    "confirmMarkPayRequestAsPaid": {
      "en": "Marking the pay request as paid will notify the user by email that his pay has been paid to his account. Mark pay request as paid?",
      "fi": "Palkkapyynnön merkitseminen maksetuksi lähettää käyttäjälle sähköpostin palkan maksusta. Merkitse palkkapyyntö maksetuksi?",
      "sv": ""
    },
    "markPayRequestAsPaidSuccess": {
      "en": "Pay request marked as paid",
      "fi": "Palkkapyyntö merkitty maksetuksi",
      "sv": ""
    },
    "confirmRejectPayRequest": {
      "en": "Rejecting the pay request will notify the user that his/her pay request was not accepted and will return the pay request amount to the user's balance. This action can't be undone. If you want to proceed, please provide a description why the pay request was rejected. ",
      "fi": "Kun hylkäät palkkapyynnön käyttäjä saa viesitn että hänen laskuansa ei hyväksytty ja palkkapyynnön määrä palautetaan käyttäjän saldolle. Tätä toimenpidettä ei voi perua. Jos haluat hylätä palkkapyynnön anna selitys miksi pyyntöä ei hyväksytty.",
      "sv": ""
    },
    "rejectPayRequestSuccess": {
      "en": "Pay request rejected",
      "fi": "Palkkapyyntö hylätty",
      "sv": ""
    },
    "saveAndNext": {
      "en": "Save and next",
      "fi": "Tallenna ja seuraava",
      "sv": ""
    },
    "downloadCsv": {
      "en": "Download CSV",
      "fi": "Lataa CSV",
      "sv": "Ladda CSV"
    }
  },
  "invoice": {
    "summary": {
      "title": {
        "en": "Summary",
        "fi": "Yhteenveto",
        "sv": ""
      },
      "month": {
        "en": "Month",
        "fi": "Kuukausi",
        "sv": ""
      },
      "billed": {
        "en": "Billed invoices",
        "fi": "Laskutetut laskut",
        "sv": ""
      },
      "billedSum": {
        "en": "Billed EUR",
        "fi": "Laskutettu EUR",
        "sv": ""
      },
      "billedSumVat": {
        "en": "Billed EUR (VAT)",
        "fi": "Laskutettu EUR (ALV)",
        "sv": ""
      },
      "paid": {
        "en": "Paid invoices",
        "fi": "Maksetut laskut",
        "sv": ""
      },
      "paidSum": {
        "en": "Paid EUR",
        "fi": "Maksettu EUR",
        "sv": ""
      },
      "paidSumVat": {
        "en": "Paid EUR (VAT)",
        "fi": "Maksettu EUR (ALV)",
        "sv": ""
      },
      "total": {
        "en": "Total",
        "fi": "Yhteensä",
        "sv": ""
      }
    },
    "commissions": {
      "title": {
        "en": "Invoice service fees",
        "fi": "Laskun hallintokorvaukset",
        "sv": ""
      },
      "amount": {
        "en": "Invoice portion (excl. VAT)",
        "fi": "Laskun osuus (veroton)",
        "sv": ""
      },
      "commission": {
        "en": "Service fee",
        "fi": "Hallintokorvaus",
        "sv": ""
      },
      "percentage": {
        "en": "Percentage",
        "fi": "Prosentti",
        "sv": ""
      },
      "reason": {
        "en": "Reason",
        "fi": "Peruste",
        "sv": ""
      },
      "reasons": {
        "invoice_less_than_300": {
          "en": "Invoice total amount without VAT is 300 € or less",
          "fi": "Laskun veroton summa on 300 € tai vähemmän",
          "sv": ""
        },
        "no_fee_for_expenses": {
          "en": "Service fee is not charged for tax deductible expenses",
          "fi": "Laskuun liittyvistä kuluista ei peritä hallintokorvausta",
          "sv": ""
        },
        "total_billing_under_24000": {
          "en": "User has billed 24 000 € or less",
          "fi": "Käyttäjä on laskuttanut 24 000 € tai vähemmän",
          "sv": ""
        },
        "total_billing_over_24000": {
          "en": "User has billed more than 24 000 €",
          "fi": "Käyttäjä on laskuttanut yli 24 000 €",
          "sv": ""
        },
        "total_billing_over_60000": {
          "en": "User has billed more than 60 000 €",
          "fi": "Käyttäjä on laskuttanut yli 60 000 €",
          "sv": ""
        }
      }
    },
    "history": {
      "title": {
        "en": "Invoice history",
        "fi": "Laskun historia",
        "sv": ""
      },
      "time": {
        "en": "Time",
        "fi": "Aika",
        "sv": ""
      },
      "event": {
        "en": "Event",
        "fi": "Tapahtuma",
        "sv": ""
      },
      "events": {
        "created": {
          "en": "Invoice created by user ${name}",
          "fi": "Käyttäjä ${name} loi laskun",
          "sv": ""
        },
        "creditNoteCreated": {
          "en": "Credit note for invoice <link>#${originalInvoiceId}</link> created: ${reason}",
          "fi": "Hyvityslasku luotu laskulle <link>#${originalInvoiceId}</link>: ${reason}",
          "sv": ""
        },
        "edited": {
          "en": "Invoice edited by user ${name}",
          "fi": "Käyttäjä ${name} muokkasi laskua",
          "sv": ""
        },
        "approved": {
          "en": "Invoice approved by user ${name}",
          "fi": "Käyttäjä ${name} hyväksyi laskun",
          "sv": ""
        },
        "rejected": {
          "en": "Invoice rejected by user ${name}: \"${reason}\"",
          "fi": "Käyttäjä ${name} hylkäsi laskun: \"${reason}\"",
          "sv": ""
        },
        "deliveredToEmail": {
          "en": "Invoice delivered to email ${email}",
          "fi": "Lasku lähetetty sähköpostiin ${email}",
          "sv": ""
        },
        "deliveredLetter": {
          "en": "Invoice delivered by letter",
          "fi": "Lasku lähetetty kirjeitse",
          "sv": ""
        },
        "deliveredEinvoice": {
          "en": "Invoice delivered by e-invoice",
          "fi": "Lasku lähetetty verkkolaskuna",
          "sv": ""
        },
        "deliveredEinvoiceFallback": {
          "en": "Invoice attempted to be delivered by e-invoice but client e-invoicing information was not found. Invoice sent as e-mail to ${email} instead.",
          "fi": "Lasku yritettiin lähettää verkkolaskuna, mutta asiakkaan verkkolaskutietoja ei löytynyt. Lasku lähetetty sähköpostilla osoitteeseen ${email}.",
          "sv": ""
        },
        "creditNoteDeliveredToEmail": {
          "en": "Credit note delivered to email ${email}",
          "fi": "Hyvityslasku lähetetty sähköpostiin ${email}",
          "sv": ""
        },
        "creditNoteDeliveredLetter": {
          "en": "Credit note delivered by letter",
          "fi": "Hyvityslasku lähetetty kirjeitse",
          "sv": ""
        },
        "creditNoteDeliveredEinvoice": {
          "en": "Credit note delivered by e-invoice",
          "fi": "Hyvityslasku lähetetty verkkolaskuna",
          "sv": ""
        },
        "creditNoteDeliveredEinvoiceFallback": {
          "en": "Credit note attempted to be delivered by e-invoice but client e-invoicing information was not found. Credit note sent as letter instead.",
          "fi": "Hyvityslasku yritettiin lähettää verkkolaskuna, mutta asiakkaan verkkolaskutietoja ei löytynyt. Hyvityslasku lähetetty kirjeenä.",
          "sv": ""
        },
        "payment": {
          "en": "Payment of ${amount} ${currency} registered to invoice",
          "fi": "Maksu summalla ${amount} ${currency} kirjattu laskulle",
          "sv": ""
        },
        "markedAsBilled": {
          "en": "Invoice marked as billed by user ${name}",
          "fi": "Käyttäjä ${name} merkitsi laskun laskutetuksi",
          "sv": ""
        },
        "markedAsPaid": {
          "en": "Invoice marked as paid by user ${name}",
          "fi": "Käyttäjä ${name} merkitsi laskun maksetuksi",
          "sv": ""
        },
        "canceled": {
          "en": "Invoice marked as canceled by user ${name}",
          "fi": "Käyttäjä ${name} merkitsi laskun peruutetuksi",
          "sv": ""
        },
        "remindedViaEmail": {
          "en": "Payment reminder sent to ${email}",
          "fi": "Maksumuistutus lähetetty sähköpostiin ${email}",
          "sv": ""
        },
        "remindedViaLetter": {
          "en": "Payment reminder sent via letter",
          "fi": "Maksumuistutus lähetetty kirjeitse",
          "sv": ""
        },
        "credited": {
          "en": "Credit note <link>#${creditInvoiceId}</link> created: ${reason}",
          "fi": "Hyvityslasku <link>#${creditInvoiceId}</link> luotu: ${reason}",
          "sv": ""
        }
      }
    },
    "formInstructionsHelp": {
      "en": "Need help?",
      "fi": "Tarvitsetko apua?",
      "sv": "Behöver du hjälp?"
    },
    "formInstructionsTaxation": {
      "en": "According to the law section 45§, granting intellectual property rights is free from value-added tax (VAT 0 %), except for photos and other services (VAT 25,5 %).",
      "fi": "Tekijänoikeuden luovutukset ovat alvittomia (alv 0 %) lain 45 §:n mukaan, poikkeuksena kuvat ja muut palvelut (alv 25,5 %).",
      "sv": "Överlåtelse av upphovsrätt är enligt lagen, 45§, momsfria (moms 0%), med undantag av bilder och andra tjänster (moms 25,5 %)."
    },
    "formInstructionsLink": {
      "en": "Open the instructions on how to create the bill here.",
      "fi": "Avaa laskun täyttöohjeet tästä.",
      "sv": "Här hittar du instruktioner för hur du gör en faktura."
    },
    "missingInformation": {
      "en": "You must provide your information before you can create invoices.",
      "fi": "Sinun pitää täyttää tietosi ennen kun voit luoda laskuja.",
      "sv": "Du måste fylla i dina uppgifter innan du kan skapa fakturor"
    },
    "fillMissingInformation": {
      "en": "Fill missing information",
      "fi": "Täytä puuttuvat tiedot",
      "sv": "Fyll i informationen som saknas"
    },
    "productsAndExpensesBilledFromCustomer": {
      "en": "Products and expenses billed from customer",
      "fi": "Asiakkaalta laskutettavat tuotteet ja kulut",
      "sv": "Produkter och utgifter som faktureras av kunden"
    },
    "expensesNotBilledFromCustomer": {
      "en": "Enter gig-related expenses to be deducted from Mediakunta (only per gig)",
      "fi": "Ilmoita tässä kohtaa Mediakunnalta vähennettävät keikkaan liittyvät matka- ja muut kulut",
      "sv": "Kostnader som kan avdras i beskattningen"
    },
    "expensesNotBilledFromCustomerDescription": {
      "en": "For example, kilometer allowances, daily allowances, other expenses related to the gig. Note. There must be a receipt/document with 1. your name, 2. your ID, 3. purpose  of the trip, 4. the dates, 5. the route, and 6. your signature. Use the attached <link>travel invoice</link> or create another file that you can save as PDF, JPG or PNG. Also save any receipts as PDF, PNG or JPG.",
      "fi": "Esim. kilometrikorvaukset, päivärahat, muut keikkaan liittyvät kulut. Huom. Kilometrikorvausten ja päivärahojen yhteydessä on oltava kuitti/dokumentti, jossa on 1. oma nimesi, 2. henkilötunnuksesi, 3. matkan tarkoitus, 4. päivämäärät, 5. matkareitti ja 6. allekirjoituksesi. Käytä oheista <link>matkalaskupohjaa</link> tai kirjaa vastaavat tiedot toiseen tiedostoon, jonka voit tallentaa PDF-, JPG- tai PNG-muodossa. Tallenna myös kuitit PDF-, JPG- tai PNG-muodossa.",
      "sv": "Till exempel kilometerersättningar, dagtraktamenten eller andra utgifter som uppdraget medfört. Observera att i anknytning till kilometerersättningar och dagtraktamenten ska finnas kvitto/dokument med 1) ditt namn, 2) ditt personnummer, 3) resans orsak, 4) datum, 5) resrutt och 6) din underskrift. Använd denna mall för reseräkning [länk] eller lämna in motsvarande uppgifter i en egenhändigt skapad fil med format pdf, jpg eller png."
    },
    "invoicesList": {
      "en": "Invoices",
      "fi": "Laskut",
      "sv": "Fakturor"
    },
    "newInvoice": {
      "en": "New invoice",
      "fi": "Uusi lasku",
      "sv": "Ny faktura"
    },
    "editInvoiceHeader": {
      "en": "Edit invoice #${id}",
      "fi": "Muokkaa laskua #${id}",
      "sv": "Redigera faktura #${id}"
    },
    "editInvoice": {
      "en": "Edit invoice",
      "fi": "Muokkaa laskua",
      "sv": "Redigera faktura"
    },
    "viewInvoice": {
      "en": "Invoice  #${id}",
      "fi": "Lasku #${id}",
      "sv": "Faktura #${id}"
    },
    "viewCreditInvoice": {
      "en": "Credit Note  #${id}",
      "fi": "Hyvityslasku #${id}",
      "sv": ""
    },
    "expenses": {
      "en": "Expenses",
      "fi": "Kulut",
      "sv": "Kostnader"
    },
    "products": {
      "en": "Products",
      "fi": "Tuotteet",
      "sv": "Produkter"
    },
    "addInvoice": {
      "en": "Invoice ready",
      "fi": "Lasku valmis",
      "sv": "Fakturan färdig"
    },
    "newInvoiceForUser": {
      "en": "New invoice for user ${name}",
      "fi": "Uusi lasku käyttäjälle ${name}",
      "sv": ""
    },
    "creatingInvoiceForUserNotification": {
      "en": "You are creating a new invoice for another user. All the information you enter can be viewed by the user <link>${name}</link> after you save the invoice.",
      "fi": "Olet tekemässä laskua toiselle käyttäjälle. Kaikki syöttämäsi tiedot näkyy käyttäjälle <link>${name}</link> kun tallennat laskun.",
      "sv": ""
    },
    "newInvoiceDraft": {
      "en": "Invoice draft: ${name}",
      "fi": "Laskuluonnos: ${name}",
      "sv": ""
    },
    "saveAsDraft": {
      "en": "Save as draft",
      "fi": "Tallenna luonnokseksi",
      "sv": "Spara som utkast"
    },
    "saveDraft": {
      "en": "Save draft",
      "fi": "Tallenna luonnos",
      "sv": ""
    },
    "copyDraft": {
      "en": "Copy draft",
      "fi": "Kopioi uudeksi laskuksi",
      "sv": ""
    },
    "saveAsDraftConfirm": {
      "en": "Give a name for the draft:",
      "fi": "Anna luonnokselle nimi:",
      "sv": ""
    },
    "createDraftBeforeLeave": {
      "en": "You have unsaved changes. Do you want to create a draft from the invoice that you can continue later?",
      "fi": "Sinulla on tallentamattomia muutoksia. Haluatko luoda laskusta luonnoksen jota voit jatkaa myöhemmin?",
      "sv": ""
    },
    "saveDraftBeforeLeave": {
      "en": "You have unsaved changes. Do you want to save the changes in your draft?",
      "fi": "Sinulla on tallentamattomia muutoksia. Haluatko tallentaa luonnoksesi muutokset?",
      "sv": ""
    },
    "draftDescription": {
      "en": "You have an unfinished invoice <strong>${name}</strong> which was created at <strong>${createdAt}</strong> and last modified at <strong>${updatedAt}</strong>.",
      "fi": "Sinulla on keskeneräinen lasku <strong>${name}</strong> joka luotiin <strong>${createdAt}</strong> ja on viimeksi muokattu <strong>${updatedAt}</strong>.",
      "sv": ""
    },
    "draftSaved": {
      "en": "Draft saved",
      "fi": "Luonnos tallennettu",
      "sv": ""
    },
    "editDraft": {
      "en": "Edit draft",
      "fi": "Muokkaa luonnosta",
      "sv": ""
    },
    "draftCopied": {
      "en": "Draft copied",
      "fi": "Luonnos kopioitu",
      "sv": ""
    },
    "deleteDraft": {
      "en": "Delete draft",
      "fi": "Poista luonnos",
      "sv": ""
    },
    "confirmDeleteDraft": {
      "en": "Are you sure you want to delete this draft?",
      "fi": "Oletko varma että haluat poistaa luonnoksen?",
      "sv": ""
    },
    "deleteDraftSuccess": {
      "en": "Draft deleted successfully",
      "fi": "Luonnos poistettu onnistuneesti",
      "sv": ""
    },
    "preview": {
      "en": "Preview PDF",
      "fi": "Esikatsele PDF",
      "sv": ""
    },
    "confirmNewInvoice": {
      "en": "Please check that all the information is filled correctly. After you create the invoice, you can no longer edit it. Do you want to create the invoice?",
      "fi": "Tarkista että kaikki tiedot ovat oikein. Kun lasku on luotu, et voi muokata sitä enää. Haluatko luoda laskun?",
      "sv": "Granska att uppgifterna är korrekta. Efter att du skapat fakturan kan du inte längre redigera den. Vill du skapa fakturan?"
    },
    "creationSuccess": {
      "en": "The invoice was created",
      "fi": "Lasku luotu",
      "sv": "Faktura skapad"
    },
    "editSuccess": {
      "en": "Invoice information saved",
      "fi": "Laskun tiedot tallennettu",
      "sv": "Fakturans uppgifter sparade"
    },
    "deleteSuccess": {
      "en": "The invoice was removed",
      "fi": "Lasku poistettu",
      "sv": "Faktura raderad"
    },
    "confirmDelete": {
      "en": "Are you sure you want to delete this invoice?",
      "fi": "Oletko varma että haluat poistaa tämän laskun?",
      "sv": "Är du säker på att du vill radera denna faktura?"
    },
    "downloadPdf": {
      "en": "Download PDF",
      "fi": "Lataa PDF",
      "sv": "Ladda ner PDF"
    },
    "downloadCertificatePdf": {
      "en": "Download Certificate",
      "fi": "Lataa työtodistus",
      "sv": ""
    },
    "cloneInvoice": {
      "en": "Copy as new invoice",
      "fi": "Kopioi uudeksi laskuksi",
      "sv": ""
    },
    "edit": {
      "en": "Edit invoice",
      "fi": "Muokkaa laskua",
      "sv": "Redigera faktura"
    },
    "approve": {
      "en": "Approve invoice",
      "fi": "Hyväksy lasku",
      "sv": "Godkänn fakturan"
    },
    "reject": {
      "en": "Reject invoice",
      "fi": "Hylkää lasku",
      "sv": "Godkänn inte fakturan"
    },
    "credit": {
      "en": "Credit invoice",
      "fi": "Hyvitä lasku",
      "sv": ""
    },
    "markAsBilled": {
      "en": "Mark as billed",
      "fi": "Merkitse laskutetuksi",
      "sv": ""
    },
    "markAsPaid": {
      "en": "Mark as paid",
      "fi": "Merkitse maksetuksi",
      "sv": "Markera betald"
    },
    "confirmApprove": {
      "en": "Approving an invoice will trigger the invoice to be sent to the client by mail. This action can't be undone. Are you sure you want to approve this invoice?",
      "fi": "Kun hyväksyt laskun niin järjestelmä lähettää laskun asiakkaalle postitse. Toimenpidettä ei voi perua. Oletko varma että haluat hyväksyä tämän laskun?",
      "sv": "När du godkänner fakturan skickar systemet en faktura till kunden per post. Åtgärden kan ej annulleras. Är du säker på att  du vill godkänna denna faktura?"
    },
    "confirmReject": {
      "en": "Rejecting an invoice will notify the user that his/her invoice was not accepted. This action can't be undone. If you want to proceed, please provide a description why the invoice was rejected.",
      "fi": "Kun hylkäät laskun käyttäjä saa viestin että hänen laskuansa ei hyväksytty. Tätä toimenpidettä ei voi perua. Jos haluat hylätä laskun anna selitys miksi laskua ei hyväksytty.",
      "sv": "Om du inte godkänner fakturan får användaren ett meddelande om att hens faktura ej godkänts. Åtgärden kan ej annulleras. Om du vill annullera faktura, ge en förklaring till varför du gjort det."
    },
    "confirmMarkAsBilled": {
      "en": "Usually it is not necessary to mark invoices as billed manually. Only use this if for some reason the invoice delivery failed and you delivered the invoice manually. This action can't be undone. Do you want to mark this invoice as billed?",
      "fi": "Yleensä ei ole tarpeellista merkitä laskua laskutetuksi manuaalisesti. Käytä tätä vain jos laskun lähettäminen epäonnistui jostain syystä ja lasku lähetettiin manuaalisesti. Tätä toimenpidettä ei voi perua. Haluatko merkitä laskun laskutetuksi?",
      "sv": ""
    },
    "confirmMarkAsPaid": {
      "en": "Marking the invoice as paid will allow the user who created the invoice to withdraw the paid amount as salary. This action can't be undone. Do you want to mark this invoice as paid?",
      "fi": "Laskun merkitseminen maksetuksi siirtää maksetun summan laskun tekijän tilille, joka voi pyytää maksetun summan palkkana. Tätä toimenpidettä ei voi perua. Haluatko merkitä laskun maksetuksi?",
      "sv": "När du markerar att fakturan är betald flyttas den fakturerade summan till användarens konto. Därifrån kan användaren lyfta summan som lön. Åtgärden kan inte annulleras. Vill du markera fakturan som betald?"
    },
    "confirmCredit": {
      "en": "Crediting an invoice can't be undone. A credit note will be automatically sent to the client. Are you sure you want to credit this invoice?",
      "fi": "Laskun hyvitystä ei voi perua. Hyvityslasku lähetetään automaattisesti asiakkaalle. Oletko varma että haluat hyvittää tämän laskun?",
      "sv": ""
    },
    "approveSuccess": {
      "en": "Invoice approved",
      "fi": "Lasku hyväksytty",
      "sv": "Fakturan godkänd"
    },
    "rejectSuccess": {
      "en": "Invoice rejected",
      "fi": "Lasku hylätty",
      "sv": "Fakturan annullerad"
    },
    "markAsBilledSuccess": {
      "en": "Invoice marked as billed",
      "fi": "Lasku merkitty laskutetuksi",
      "sv": ""
    },
    "markAsPaidSuccess": {
      "en": "Invoice marked as paid",
      "fi": "Lasku merkitty maksetuksi",
      "sv": "Fakturan är markerad som betald"
    },
    "creditSuccess": {
      "en": "Invoice credited",
      "fi": "Lasku hyvitetty",
      "sv": ""
    },
    "client": {
      "header": {
        "en": "Client information",
        "fi": "Asiakkaan tiedot",
        "sv": "Kundens information"
      },
      "name": {
        "en": "Client name",
        "fi": "Asiakkaan nimi",
        "sv": "Kundens namn"
      },
      "businessId": {
        "en": "Business ID",
        "fi": "Y-tunnus",
        "sv": "FO-nummer"
      },
      "contact": {
        "en": "Contact person",
        "fi": "Yhteyshenkilö",
        "sv": "Kontaktperson"
      },
      "address": {
        "en": "Address",
        "fi": "Osoite",
        "sv": "Adress"
      },
      "postalCode": {
        "en": "Postal code",
        "fi": "Postinumero",
        "sv": "Postnummer"
      },
      "postOffice": {
        "en": "Post office",
        "fi": "Postitoimipaikka",
        "sv": "Postort"
      }
    },
    "job": {
      "header": {
        "en": "Invoice details",
        "fi": "Laskun tiedot",
        "sv": "Fakturans uppgifter"
      },
      "invoiceLanguage": {
        "en": "Send invoice in language",
        "fi": "Lähetä lasku kielellä",
        "sv": "Välj fakturans språk"
      },
      "deliveryMethod": {
        "en": "Delivery method",
        "fi": "Toimitustapa",
        "sv": "Leveransmetod"
      },
      "deliveryMethodDescription": {
        "en": "We will attempt to send the invoice as an e-invoice, but in case the company's e-invoicing information is not found in the <a href=\"http://bizsearch.norfello.com/\" target=\"_blank\">company database</a> we will send the invoice via e-mail instead. Make sure the business ID of the company is entered correctly, or sending an e-invoice will fail.",
        "fi": "Yritämme lähettää laskun verkkolaskuna, mutta jos yrityksen verkkolaskutietoja ei löydy <a href=\"http://bizsearch.norfello.com/\" target=\"_blank\">yritystietokannasta</a>, lähetämme laskun sähköpostilla. Varmista että yrityksen y-tunnus on syötetty oikein tai verkkolaskun lähetys ei onnistu.",
        "sv": "Vi försöker skicka fakturan som e-faktura, men om företagets uppgifter för e- fakturering inte finns i <a href=\"http://bizsearch.norfello.com/\" target=\"_blank\">företagsdatabase</a>, skickar vi fakturan per e-post. Försäkra dig om att företagets FO-nummer är korrekt, annars kommer e-fakturering inte att lyckas."
      },
      "deliveryEmail": {
        "en": "E-mail where the invoice is sent to",
        "fi": "Sähköposti mihin lasku lähetetään",
        "sv": "Fakturamottagarens e-postadress"
      },
      "deliveryEmailLanguage": {
        "en": "Send e-mail in language",
        "fi": "Lähetä sähköposti kielellä",
        "sv": "Välj språk för e-postmeddelande"
      },
      "useEinvoiceAddress": {
        "en": "Specify the e-invoice address (OVT code) and operator ID",
        "fi": "Lisää tarvittaessa verkkolaskuosoite (OVT-tunnus) ja operaattorin välittäjätunnus",
        "sv": "Specificera vid behov e-faktureringsadress (OVT-nummer) och operatörens ID"
      },
      "einvoiceAddress": {
        "en": "E-invoice address (OVT ID)",
        "fi": "Verkkolaskuosoite (OVT-tunnus)",
        "sv": "E- faktureringsadress (OVT-nummer)"
      },
      "einvoiceOperatorId": {
        "en": "E-invoice operator ID",
        "fi": "Operaattorin välittäjätunnus",
        "sv": "Operatörens förmedlarkod"
      },
      "reminderDeliveryEmail": {
        "en": "E-mail where reminder invoices are sent to",
        "fi": "Sähköposti mihin muistutuslaskut lähetetään",
        "sv": "E-postadress till vilken betalningspåminnelser sänds"
      },
      "reminderDeliveryEmailLanguage": {
        "en": "Send reminder invoices in language",
        "fi": "Lähetä muistutuslaskut kielellä",
        "sv": "Välj språk för betalningspåminnelser"
      },
      "reminderDeliveryEmailDescription": {
        "en": "Reminder invoices are sent via e-mail instead of e-invoice.",
        "fi": "Muistutuslaskut lähtevät sähköpostilla verkkolaskun sijaan.",
        "sv": "Betalningspåminnelser sänds per e-post i stället för som e-faktura."
      },
      "dueDate": {
        "en": "Due date",
        "fi": "Eräpäivä",
        "sv": "Förfallodag"
      },
      "paymentTime": {
        "en": "Payment time",
        "fi": "Maksuaika",
        "sv": "Betalningstid"
      },
      "paymentTimeDescription": {
        "en": "Choose the payment schedule",
        "fi": "Valitse tästä, kuinka monen päivän päästä eräpäivä on",
        "sv": "Välj här efter hur många dagar sista betalningsdagen ska vara"
      },
      "date": {
        "en": "Date of work",
        "fi": "Työn päivämäärä",
        "sv": "Arbetets tidpunkt"
      },
      "hours": {
        "en": "Hours invested",
        "fi": "Tehdyt tunnit",
        "sv": "Antal utförda timmar"
      },
      "description": {
        "en": "Job description for the work certificate",
        "fi": "Työnkuvaus työtodistusta varten",
        "sv": "Beskrivning av arbetet för arbetsbetyget"
      },
      "descriptionDescription": {
        "en": "The client can not see this information",
        "fi": "Ei näy asiakkaalle",
        "sv": "Syns inte för kunden"
      },
      "additionalInfo": {
        "en": "Additional information for the client",
        "fi": "Lisätiedot asiakkaalle",
        "sv": "Tilläggsinformationen syns på fakturan"
      },
      "additionalInfoDescription": {
        "en": "This will be included in the invoice",
        "fi": "Näkyy laskussa asiakkaalle",
        "sv": "Tilläggsinformationen syns på fakturan"
      },
      "datesDescription": {
        "en": "Specify the dates of work and hours invested day by day basis and in format dd.mm.yyyy. This is not visible to the customer.",
        "fi": "Erittele työn päivämäärät ja työtunnit päiväkohtaisesti aikajärjestyksessä ja muodossa pp.kk.vvvv. Ei näy asiakkaalle.",
        "sv": "Specificera arbetets datum och antalet arbetstimmar per dag i kronologisk ordning, format: dd.mm.åååå. Denna information är inte synlig för kunden."
      }
    },
    "status": {
      "new": {
        "en": "New",
        "fi": "Uusi",
        "sv": "Ny"
      },
      "approved": {
        "en": "Approved",
        "fi": "Hyväksytty",
        "sv": "Godkänd"
      },
      "rejected": {
        "en": "Rejected",
        "fi": "Hylätty",
        "sv": "Inte godkänd"
      },
      "billed": {
        "en": "Billed",
        "fi": "Laskutettu",
        "sv": "Fakturerad"
      },
      "paid": {
        "en": "Paid",
        "fi": "Maksettu",
        "sv": "Betald"
      },
      "paid_mismatch": {
        "en": "Paid (wrong amount)",
        "fi": "Maksettu (väärä summa)",
        "sv": ""
      },
      "canceled": {
        "en": "Canceled",
        "fi": "Peruttu",
        "sv": "Annullerad"
      },
      "credited": {
        "en": "Credited",
        "fi": "Hyvitetty",
        "sv": "Krediterad"
      }
    },
    "invoiceType": {
      "all": {
        "en": "All invoices",
        "fi": "Kaikki laskut",
        "sv": "Alla fakturor"
      },
      "invoices": {
        "en": "Invoices",
        "fi": "Laskut",
        "sv": "Fakturor"
      },
      "creditNotes": {
        "en": "Credit notes",
        "fi": "Hyvityslaskut",
        "sv": "Kreditfakturor"
      }
    },
    "filters": {
      "show": {
        "en": "Show",
        "fi": "Näytä",
        "Sv": "Visa"
      },
      "all": {
        "en": "All",
        "fi": "Kaikki",
        "sv": "Alla"
      },
      "searchDescription": {
        "en": "Filter invoices by client, biller, reference number, etc",
        "fi": "Rajaa laskuja asiakkaan, laskuttajan, viitenumeron, jne. mukaan",
        "sv": "Filtrera fakturor enligt kund, avsändare, referensnummer etc."
      }
    },
    "info": {
      "id": {
        "en": "#",
        "fi": "#",
        "sv": "#"
      },
      "referenceNumber": {
        "en": "Reference number",
        "fi": "Viitenumero",
        "sv": "Referensnummer"
      },
      "status": {
        "en": "Status",
        "fi": "Tila",
        "sv": "Status"
      },
      "originalInvoice": {
        "en": "Original invoice",
        "fi": "Koskee laskua",
        "sv": ""
      },
      "created": {
        "en": "Created",
        "fi": "Luotu",
        "sv": "Skapad"
      },
      "updated": {
        "en": "Updated",
        "fi": "Päivitetty",
        "sv": "Uppdaterad"
      },
      "client": {
        "en": "Client",
        "fi": "Asiakas",
        "sv": "Kund"
      },
      "billerName": {
        "en": "Biller",
        "fi": "Laskuttaja",
        "sv": "Fakturautställare"
      },
      "totalPrice": {
        "en": "Total price",
        "fi": "Yhteensä",
        "sv": "Summa totalt"
      },
      "totalExpenses": {
        "en": "Expenses",
        "fi": "Kuluja",
        "sv": "Sammanlagda kostnader"
      },
      "workDates": {
        "en": "Work dates",
        "fi": "Työn ajankohta",
        "sv": "Arbetets tidpunkt"
      },
      "dueDate": {
        "en": "Due date",
        "fi": "Eräpäivä",
        "sv": "Förfallodag"
      },
      "startDate": {
        "en": "Start date",
        "fi": "Alkamispäivämäärä",
        "sv": "Startdatum"
      },
      "endDate": {
        "en": "End date",
        "fi": "Päättymispäivämäärä",
        "sv": "Slutdatum"
      },
      "productTitle": {
        "en": "Title",
        "fi": "Nimike",
        "sv": "Titel"
      },
      "productPrice": {
        "en": "Price",
        "fi": "Hinta",
        "sv": "Pris"
      },
      "productAmount": {
        "en": "Amount",
        "fi": "Määrä",
        "sv": "Antal"
      },
      "productVAT": {
        "en": "VAT-%",
        "fi": "ALV-%",
        "sv": "MOMS-%"
      },
      "expenseTitle": {
        "en": "Expense",
        "fi": "Kulu",
        "sv": "Kostnad"
      },
      "expenseAmount": {
        "en": "Amount (€)",
        "fi": "Määrä (€)",
        "sv": " Antal (€)"
      },
      "expenseTax": {
        "en": "Tax (€)",
        "fi": "Veroja (€)",
        "sv": "Skatter (€)"
      },
      "expenseTotal": {
        "en": "Total",
        "fi": "Yhteensä",
        "sv": "Summa kostnader"
      },
      "expenseReceipt": {
        "en": "Receipt",
        "fi": "Kuitti",
        "sv": "Kvitto"
      },
      "addExpense": {
        "en": "Add expenses",
        "fi": "Lisää kuluja",
        "sv": "Lägg till kostnad"
      },
      "addRow": {
        "en": "Add new row",
        "fi": "Lisää uusi rivi",
        "sv": "Lägg till en ny rad"
      },
      "addWork": {
        "en": "Add work",
        "fi": "Lisää työ",
        "sv": "Lägg till arbete"
      },
      "deleteRow": {
        "en": "Remove row",
        "fi": "Poista rivi",
        "sv": "Ta bort rad"
      },
      "deleteWork": {
        "en": "Remove work",
        "fi": "Poista työ",
        "sv": "Avlägsna arbete"
      },
      "deleteExpense": {
        "en": "Remove expense",
        "fi": "Poista kulu",
        "sv": "Avlägsna kostnad"
      },
      "hoursTotal": {
        "en": "Total hours",
        "fi": "Tunteja yhteensä",
        "sv": ""
      },
      "copyToClipboard": {
        "en": "Copy to clipboard",
        "fi": "Kopioi leikepöydälle",
        "sv": ""
      },
      "copiedToClipboard": {
        "en": "Copied to clipboard",
        "fi": "Kopioitu leikepöydälle",
        "sv": ""
      },
      "productsTotal": {
        "en": "Products total",
        "fi": "Tuotteet yhteensä",
        "sv": "Sammanlagda produkter"
      },
      "productsTotalWithTaxes": {
        "en": "With taxes",
        "fi": "Sisältäen verot",
        "sv": "Inklusive skatter"
      },
      "expensesTotal": {
        "en": "Expenses total",
        "fi": "Kulut yhteensä",
        "sv": "Sammanlagda kostnader"
      },
      "receipt": {
        "en": "Receipt",
        "fi": "Kuitti",
        "sv": "Kvitto"
      }
    },
    "deliveryMethod": {
      "letter": {
        "en": "Letter",
        "fi": "Kirje",
        "sv": ""
      },
      "email": {
        "en": "E-mail",
        "fi": "Sähköposti",
        "sv": ""
      },
      "einvoice": {
        "en": "E-Invoice",
        "fi": "Verkkolasku",
        "sv": ""
      }
    }
  },
  "reimbursements": {
    "reimbursementsList": {
      "en": "Reimbursements list",
      "fi": "Kulukorvauslista",
      "sv": ""
    },
    "editReimbursementHeader": {
      "en": "Edit reimbursement #${id}",
      "fi": "Muokkaa kulukorvausta #${id}",
      "sv": ""
    },
    "editReimbursement": {
      "en": "Edit reimbursement",
      "fi": "Muokkaa kulukorvausta",
      "sv": ""
    },
    "userName": {
      "en": "User",
      "fi": "Käyttäjä",
      "sv": "Användare"
    },
    "addSuccess": {
      "en": "The reimbursement was created",
      "fi": "Kulukorvaus luotu",
      "sv": ""
    },
    "editSuccess": {
      "en": "Reimbursement saved",
      "fi": "Kulukorvaus tallennettu",
      "sv": ""
    },
    "confirmApprove": {
      "en": "Are you sure you want to approve this reimbursement?",
      "fi": "Haluatko varmasti hyväksyä kulukorvauksen?",
      "sv": ""
    },
    "approveSuccess": {
      "en": "Reimbursement approved successfully",
      "fi": "Kulukorvaus hyväksytty",
      "sv": ""
    },
    "approve": {
      "en": "Approve",
      "fi": "Hyväksy",
      "sv": ""
    },
    "confirmReject": {
      "en": "Are you sure you want to reject this reimbursement?",
      "fi": "Haluatko varmasti hyälätä kulukorvauksen?",
      "sv": ""
    },
    "rejectSuccess": {
      "en": "Reimbursement was rejected successfully",
      "fi": "Kulukorvaus hylätty",
      "sv": ""
    },
    "reject": {
      "en": "Reject",
      "fi": "Hylkää",
      "sv": ""
    },
    "credit": {
      "en": "Credit",
      "fi": "Hyvitä",
      "sv": ""
    },
    "singular": {
      "en": "Reimbursement",
      "fi": "Kulukorvaus",
      "sv": "Kostnadsersättning"
    },
    "plural": {
      "en": "Reimbursements",
      "fi": "Kulukorvaukset",
      "sv": "Kostnadsersättningar"
    },
    "title": {
      "en": "Reimbursements",
      "fi": "Ilmoita tässä Mediakunnalta vähennettävät kulukorvaukset",
      "sv": "Meddela här kostnadsersättningar som ska avdras från Medielaget"
    },
    "titleDescription": {
      "en": "(for example general equipment costs). PLEASE NOTE: Do not not mark any gig-specific expenses here. You should inform Mediakunta about any gig-specific costs (i.e. kilometer and per diem allowances) when making the invoice.",
      "fi": "(esim. laitehankinnat). HUOMIO: Keikkaan liittyvät verovähennyskelpoiset kulut (esim. kilometrikorvaukset ja päivärahat) ilmoitetaan jo laskun teon yhteydessä. Niitä ei tarvitse ilmoittaa tässä uudestaan.",
      "sv": "(t.ex. införskaffningar, apparater). OBS: Kostnader som kan avdras i beskattningen, till exempel kilometerersättningar och dagpenningar, ska meddelas i samband med fakturan. Dem ska du inte meddela om på nytt här."
    },
    "submit": {
      "en": "Add reimbursement",
      "fi": "Lisää kulukorvaus",
      "sv": "Lägg till kostnadsersättning"
    },
    "info": {
      "id": {
        "en": "#",
        "fi": "#",
        "sv": "#"
      },
      "status": {
        "en": "Status",
        "fi": "Tila",
        "sv": "Status"
      },
      "title": {
        "en": "Title",
        "fi": "Selite",
        "sv": "Specifikation"
      },
      "total": {
        "en": "Total with taxes",
        "fi": "Summa veroineen",
        "sv": "Summa (inkl. skatt)"
      },
      "taxes": {
        "en": "Taxes portion (€)",
        "fi": "Verojen osuus (€)",
        "sv": ""
      },
      "receipt": {
        "en": "Receipt",
        "fi": "Kuitti",
        "sv": "Kvitto"
      },
      "image": {
        "en": "Image",
        "fi": "Kuva",
        "sv": "Bild"
      },
      "created": {
        "en": "Created",
        "fi": "Luotu",
        "sv": "Skapad"
      }
    },
    "status": {
      "new": {
        "en": "New",
        "fi": "Uusi",
        "sv": ""
      },
      "approved": {
        "en": "Approved",
        "fi": "Hyväksytty",
        "sv": ""
      },
      "rejected": {
        "en": "Rejected",
        "fi": "Hylätty",
        "sv": ""
      }
    },
    "filters": {
      "all": {
        "en": "All",
        "fi": "Kaikki",
        "sv": ""
      }
    }
  },
  "controls": {
    "title": {
      "en": "Controls",
      "fi": "Toiminnot",
      "sv": "Funktioner"
    },
    "view": {
      "en": "View",
      "fi": "Katso",
      "sv": "Titta"
    },
    "edit": {
      "en": "Edit",
      "fi": "Muokkaa",
      "sv": "Redigera"
    },
    "delete": {
      "en": "Delete",
      "fi": "Poista",
      "sv": "Stryk"
    }
  },
  "profile": {
    "header": {
      "en": "Profile",
      "fi": "Profiili",
      "sv": "Profil"
    },
    "editHeader": {
      "en": "Edit information",
      "fi": "Muokkaa tietoja",
      "sv": "Redigera uppgifter"
    },
    "editSuccess": {
      "en": "Profile information updated",
      "fi": "Profiilin tiedot päivitetty",
      "sv": "Profilens uppgifter uppdaterade"
    },
    "changePicture": {
      "en": "Change picture",
      "fi": "Vaihda kuva",
      "sv": "Byt bild"
    },
    "info": {
      "en": "Information",
      "fi": "Tiedot",
      "sv": "Information"
    },
    "edit": {
      "en": "Edit information",
      "fi": "Muokkaa tietoja",
      "sv": "Redigera uppgifter"
    },
    "changePassword": {
      "en": "Change password",
      "fi": "Vaihda salasana",
      "sv": "Ändra lösenord"
    },
    "passwordChanged": {
      "en": "Password changed",
      "fi": "Salasana vaihdettu",
      "sv": "Lösenordet ändrat"
    },
    "oldPassword": {
      "en": "Current password",
      "fi": "Nykyinen salasana",
      "sv": "Nuvarande lösenord"
    },
    "newPassword": {
      "en": "New password",
      "fi": "Uusi salasana",
      "sv": "Nytt lösenord"
    },
    "newPasswordAgain": {
      "en": "Confirm new password",
      "fi": "Varmista uusi salasana",
      "sv": "Bekräfta nytt lösenord"
    },
    "noTaxCard": {
      "en": "Tax card hasn't been provided",
      "fi": "Verokorttia ei ole lisätty",
      "sv": "Skattekortet är ej tillagt"
    },
    "saveInfo": {
      "en": "Save information",
      "fi": "Tallenna tiedot",
      "sv": "Spara uppgifterna"
    },
    "balance": {
      "en": "Balance",
      "fi": "Saldo",
      "sv": "Saldo"
    },
    "ownBalance": {
      "en": "Own balance",
      "fi": "Oma saldo",
      "sv": "Eget saldo"
    },
    "yourReservedBalanceIs": {
      "en": "Your reserved balance is",
      "fi": "Saldostasi on varattu",
      "sv": ""
    },
    "userReservedBalanceIs": {
      "en": "User's reserved balance is",
      "fi": "Käyttäjän saldosta on varattu",
      "sv": ""
    },
    "balanceHistory": {
      "en": "Balance history",
      "fi": "Saldohistoria",
      "sv": "Saldohistoria"
    },
    "withdrawAsSalary": {
      "en": "Withdraw salary",
      "fi": "Nosta palkkaa",
      "sv": "Lyft som lön"
    },
    "makeReimbursement": {
      "en": "Make a reimbursement",
      "fi": "Tee kulukorvaus",
      "sv": "Begär kostnadsersättning"
    },
    "withdraw": {
      "en": "Withdraw",
      "fi": "Nosta",
      "sv": "Lyft"
    },
    "confirmWithdraw": {
      "en": "Are you sure you want to withdraw ${amount} € as salary?",
      "fi": "Oletko varma että haluat nostaa ${amount} € palkkana?",
      "sv": ""
    },
    "salaryWithdraw": {
      "en": "Salary withdrawal",
      "fi": "Palkan nosto",
      "sv": "Lyft som lön"
    },
    "reimbursement": {
      "en": "Reimbursement",
      "fi": "Kulukorvaus",
      "sv": "Kostnadsersättning"
    },
    "reimbursementDescription": {
      "en": "You can withdraw your balance as a tax-free reimbursement if you have a receipt.",
      "fi": "Voit nostaa saldoa verottomana kulukorvauksena kuittia vastaan.",
      "sv": "Du kan lyfta saldo skattefritt som kostnadsersättning mot uppvisande av kvitto."
    },
    "reimbursementsShare": {
      "en": "reimbursements' share",
      "fi": "kulukorvausten osuus",
      "sv": ""
    },
    "withdrawSuccess": {
      "en": "Salary withdrawn",
      "fi": "Palkka nostettu",
      "sv": "Lönen lyft"
    },
    "invoice": {
      "en": "Invoice",
      "fi": "Lasku",
      "sv": "Faktura"
    },
    "withdrawalMinimumAmount": {
      "en": "The minimum amount to withdraw is the reimbursements balance.",
      "fi": "Minimisumma palkannostoon on vähintään kulukorvausten määrä.",
      "sv": ""
    },
    "withdrawalNoTaxCard": {
      "en": "You must upload your tax card before you can withdraw salary.",
      "fi": "Sinun pitää päivittää verokorttisi järjestelmään ennen kun voit nostaa palkkaa.",
      "sv": "Du måste ladda upp ditt skattekort innan du kan lyfta lön."
    },
    "uploadTaxCard": {
      "en": "Upload tax card",
      "fi": "Päivitä verokortti",
      "sv": "Ladda upp skattekortet"
    },
    "iWantToWithdraw": {
      "en": "I want to withdraw",
      "fi": "Haluan nostaa",
      "sv": "Jag vill lyfta"
    },
    "asSalary": {
      "en": "as salary",
      "fi": "palkkana",
      "sv": "som lön"
    },
    "grossSalary": {
      "en": "Gross balance",
      "fi": "Bruttosaldo",
      "sv": "Bruttolön"
    },
    "transactionTime": {
      "en": "Time",
      "fi": "Aika",
      "sv": "Tid"
    },
    "transactionAmount": {
      "en": "Amount",
      "fi": "Määrä",
      "sv": "Antal"
    },
    "transactionType": {
      "en": "Transaction",
      "fi": "Tapahtuma",
      "sv": "Transaktion"
    },
    "yourCurrentBalanceIs": {
      "en": "Your current balance is",
      "fi": "Tämänhetkinen saldosi on",
      "sv": "Ditt saldo är i dag"
    },
    "userCurrentBalanceIs": {
      "en": "User's current balance is",
      "fi": "Käyttäjän tämänhetkinen saldo on",
      "sv": ""
    },
    "changeProfilePicDescription": {
      "en": "You can change the profile picture by clicking the picture",
      "fi": "Voit vaihtaa profiilikuvan klikkaamalla kuvaa",
      "sv": "Du kan byta profilbild genom att klicka på bilden"
    },
    "userOverview": {
      "en": "User overview",
      "fi": "Käyttäjän yhteenveto",
      "sv": ""
    },
    "createInvoiceForUser": {
      "en": "Create invoice for user",
      "fi": "Luo lasku käyttäjälle",
      "sv": ""
    },
    "createContractForUser": {
      "en": "Add contract for user",
      "fi": "Lisää sopimus käyttäjälle",
      "sv": ""
    }
  },
  "balance": {
    "summary": {
      "en": "Summary",
      "fi": "Yhteenveto",
      "sv": "Sammanfattning"
    },
    "withdraw": {
      "en": "Withdraw",
      "fi": "Nosta palkkaa",
      "sv": "Lyft som lön"
    },
    "payslipCalculator": {
      "en": "Payslip calculator",
      "fi": "Palkkalaskuri",
      "sv": "Lönekalkylator"
    },
    "reimbursements": {
      "en": "Reimbursements",
      "fi": "Kulukorvaukset",
      "sv": "Kostnadsersättningar"
    },
    "summaryTitle": {
      "en": "Balance summary",
      "fi": "Saldon yhteenveto",
      "sv": "Sammanfattning av saldobalans"
    },
    "historyTitle": {
      "en": "Balance history",
      "fi": "Saldohistoria",
      "sv": "Saldohistoria"
    },
    "withdrawalsDisabled": {
      "en": "Balance withdrawal is not possible during 26.6. - 3.7. due to maintenance.",
      "fi": "Saldoa ei voi nostaa 26.6. - 3.7. välisenä aikana huoltotöiden takia.",
      "sv": ""
    },
    "taxCardNotChecked": {
      "en": "Your tax card is waiting for verification. Please check back later.",
      "fi": "Verokorttisi odottaa tarkistusta. Ole hyvä, ja yritä myöhemmin uudelleen.",
      "sv": ""
    },
    "balanceWithdrawnTitle": {
      "en": "Pay withdrawn",
      "fi": "Palkka nostettu",
      "sv": ""
    },
    "balanceWithdrawnMessage": {
      "en": "Your request for pay has been forwarded to the salary payer. You will receive a notification in your e-mail when the pay request has been processed.",
      "fi": "Palkkapyyntösi on välitetty palkanmaksajalle. Saat vielä ilmoituksen sähköpostiisi kun palkka on laskettu ja laitettu maksuun.",
      "sv": ""
    }
  },
  "balanceSummary": {
    "totalBalance": {
      "en": "Total balance",
      "fi": "Kokonaissaldo",
      "sv": "Totalbelopp"
    },
    "waitingPayment": {
      "en": "Invoices waiting for payment",
      "fi": "Maksua odottavat laskut",
      "sv": "Fakturor som inväntar betalning"
    },
    "unacceptedReimbursements": {
      "en": "Unaccepted reimbursements",
      "fi": "Hyväksymättömiä kulukorvauksia",
      "sv": "Icke godkända kostnadsersättningar"
    },
    "pendingPayRequests": {
      "en": "Pending pay requests",
      "fi": "Maksua odottavat palkkapyynnöt",
      "sv": "Väntande löneutbetalningar"
    },
    "availableBalance": {
      "en": "Available balance",
      "fi": "Nostettava saldo",
      "sv": "Tillgängligt saldo"
    },
    "balanceTitle": {
      "en": "Balance",
      "fi": "Saldo",
      "sv": "Saldobalans"
    },
    "reimbursementsTitle": {
      "en": "Tax-free share",
      "fi": "Verovapaaosuus",
      "sv": "Skattefri andel"
    }
  },
  "balanceWithdraw": {
    "withdrawAsSalary": {
      "en": "Withdraw your pay",
      "fi": "Nosta palkkaa",
      "sv": "Lyft lön"
    },
    "withdrawAsSalaryExplanation": {
      "en": "Enter the sum here you want to withdraw. You will then see it on your initial payslip on the right side of this page. Once you have checked your payslip, you can send the request to withdraw your pay.",
      "fi": "Syötä alla olevaan kenttään summa, jonka haluat nostaa palkkana. Näet sen jälkeen oikealla alustavan palkkalaskelmasi. Palkkalaskelmaan tutustumisen jälkeen voit lähettää palkkapyynnön.",
      "sv": "Skriv in den summa du önskar lyfta som lön. Den kommer då att synas på din preliminära lönekalkyl här till höger. När du har kontrollerat kalkylen kan du sända in begäran om utbetalning."
    },
    "withdrawAsSalaryExplanationMore": {
      "en": "NB! If you want separate payslips for each of your invoices, please make a separate withdrawal of pay for each of the invoices to ease the pay calculation.",
      "fi": "HUOM. Jos tarvitset palkkaerittelyyn palkat eriteltynä laskuittain, teethän palkanlaskennan helpottamiseksi jokaisesta laskusta erillisen nostopyynnön.",
      "sv": "OBS! Om du vill ha separat lönebesked för varje faktura, var god lyft löneersättningarna för respektive uppdrag separat, det underlättar beräkningen."
    },
    "payslipCalculator": {
      "en": "Payslip calculator",
      "fi": "Palkkalaskuri",
      "sv": ""
    },
    "payslipCalculatorExplanation": {
      "en": "With this calculator you can see how different numbers such as travel expenses, tax percentage and age affect your pay. Enter the sums below, you will then see your initial payslip on the right side of this page.",
      "fi": "Tällä laskurilla voit katsoa miten eri luvut kuten matkakulut, veroprosentti ja ikä vaikuttavat palkkaasi. Syötä alla olevaan kenttiin summat, näet sen jälkeen oikealla alustavan palkkalaskelmasi.",
      "sv": "Med hjälp av kalkylatorn kan du se hur din lön påverkas av olika variabler som resekostnader, skatteprocent och ålder. Skriv in siffrorna nedan, din preliminära löneberäkning visas till höger."
    },
    "payslipCalculatorPayRequest": {
      "en": "Pay amount",
      "fi": "Palkkapyyntö",
      "sv": "Lönesumma"
    },
    "payslipCalculatorTravelExpenses": {
      "en": "Travel and other expenses portion",
      "fi": "Matka- ja muiden kulujen osuus",
      "sv": "Rese- och andra kostnader sammanlagt"
    },
    "payslipCalculatorTaxPercentage": {
      "en": "Tax percentage",
      "fi": "Veroprosentti",
      "sv": "Skatteprocent"
    },
    "payslipCalculatorAge": {
      "en": "Age for taxing",
      "fi": "Ikä verotuksessa",
      "sv": "Din ålder"
    },
    "payslipCalculatorAgeUnit": {
      "en": "yr",
      "fi": "v",
      "sv": "år"
    },
    "payslipCalculatorAgeDescription": {
      "en": "In pay calculation the age is defined as the person's age at the end of the month prior to the month of the pay date.",
      "fi": "Palkanlaskennassa palkansaajan laskennalliseksi iäksi määritellään henkilön ikävuodet palkanmaksupäivää edeltävän kuukauden lopussa.",
      "sv": "I löneberäkningen definieras åldern som personens ålder vid utgången av den månad som föregår den månad då utbetalningen utförs."
    },
    "goToPayslipCalculator": {
      "en": "You can try how different variables affect your payslip with the <link>payslip calculator</link>.",
      "fi": "Voit kokeilla miten eri muuttujat vaikuttavat palkkalaskelmaasi <link>palkkalaskurilla</link>.",
      "sv": "Med hjälp av vår <link>lönekalkylator</link> kan du se hur olika variabler påverkar löneutbetalningen."
    },
    "payslip": {
      "en": "Payslip",
      "fi": "Palkkalaskelma",
      "sv": "Lönekalkyl"
    },
    "withdrawAmount": {
      "en": "Pay request",
      "fi": "Palkkapyyntö",
      "sv": "Begärt som lön"
    },
    "taxableIncome": {
      "en": "Taxable pay",
      "fi": "Veronalaista palkkaa",
      "sv": "Beskattningsbar lön"
    },
    "travelExpenses": {
      "en": "travel and other expenses",
      "fi": "matka- ja muut kulut",
      "sv": "rese- och andra kostnader"
    },
    "employersExpensesAreDeducted": {
      "en": "Empoyer’s costs deducted from pay",
      "fi": "Palkasta vähennetään työnantajan sivukulut",
      "sv": "Från lönen avdras arbetsgivarens kostnader"
    },
    "healthInsurancePayment": {
      "en": "health insurance ${percentage}",
      "fi": "sairausvakuutusmaksu ${percentage}",
      "sv": "sjukförsäkringsavgift ${percentage}"
    },
    "employersPensionContribution": {
      "en": "employer pension contribution (TyEL) ${percentage}",
      "fi": "työnantajan työeläkemaksu (TyEL) ${percentage}",
      "sv": "arbetsgivarens arbetspensionsavgift (ArPL) ${percentage}"
    },
    "employersUnemploymentInsurancePayment": {
      "en": "employer unemployment insurance contribution ${percentage}",
      "fi": "työnantajan työttömyysvakuutusmaksu ${percentage}",
      "sv": "arbetsgivarens arbetslöshetsförsäkringsavgift ${percentage}"
    },
    "employersAccidentInsurance": {
      "en": "accident insurance ${percentage}",
      "fi": "tapaturmavakuutusmaksu ${percentage}",
      "sv": "olycksfallsförsäkringsavgift ${percentage}"
    },
    "groupLifeInsurance": {
      "en": "group life insurance",
      "fi": "ryhmähenkivakuutusmaksu ${percentage}",
      "sv": "grupplivförsäkringsavgift ${percentage}"
    },
    "grossWage": {
      "en": "Gross pay",
      "fi": "Bruttopalkka",
      "sv": "Bruttolön"
    },
    "grossWageContractWorkShare": {
      "en": "from which contract work's share is",
      "fi": "josta urakkapalkan osuus on",
      "sv": "av vilken arbetsersättningens andel är"
    },
    "grossWageVacationCompensationShare": {
      "en": "and vacation compensation's share is",
      "fi": "ja lomakorvauksen osuus on",
      "sv": "och semesterersättningens andel är"
    },
    "deductedFromGrossWage": {
      "en": "Deductions from gross pay",
      "fi": "Bruttopalkasta vähennetään",
      "sv": "Från bruttolönen avdras"
    },
    "advancePayment": {
      "en": "PAYE (tax withheld at source) ${percentage}",
      "fi": "ennakonpidätys ${percentage}",
      "sv": "förskottsinnehållning ${percentage}"
    },
    "employeesPensionPayment": {
      "en": "Employee pension contribution (TyEL) ${percentage}",
      "fi": "työntekijän työeläkemaksu (TyEL) ${percentage}",
      "sv": "arbetstagarens arbetspensionsavgift (ArPL) ${percentage}"
    },
    "employeesUnemploymentInsurancePayment": {
      "en": "Employee unemployment insurance contribution ${percentage}",
      "fi": "työntekijän työttömyysvakuutusmaksu ${percentage}",
      "sv": "arbetstagarens arbetslöshetsförsäkringsavgift ${percentage}"
    },
    "netWage": {
      "en": "Net pay",
      "fi": "Nettopalkka",
      "sv": "Nettolön"
    },
    "isAddedToNetWage": {
      "en": "Additions to net pay",
      "fi": "Nettopalkkaan lisätään",
      "sv": "Tillägg till nettolönen"
    },
    "toBePaid": {
      "en": "Total payable",
      "fi": "MAKSETAAN",
      "sv": "Utbetalas netto"
    }
  },
  "fileUpload": {
    "selectFile": {
      "en": "Select file",
      "fi": "Valitse tiedosto",
      "sv": "Välj fil"
    },
    "preview": {
      "en": "Preview",
      "fi": "Esikatsele",
      "sv": "Förhandsgranska"
    },
    "remove": {
      "en": "Remove",
      "fi": "Poista",
      "sv": "Ta bort"
    },
    "changeFile": {
      "en": "Change file",
      "fi": "Vaihda tiedosto",
      "sv": "Byt fil"
    },
    "noFileSelected": {
      "en": "No file selected",
      "fi": "Ei tiedostoa valittu",
      "sv": "Ingen fil vald"
    },
    "uploading": {
      "en": "Uploading",
      "fi": "Lähetetään",
      "sv": "Skickas"
    }
  },
  "errors": {
    "REQUIRED": {
      "en": "The field is required",
      "fi": "Kenttä on pakollinen",
      "sv": "Obligatoriskt fält"
    },
    "FORBIDDEN": {
      "en": "Access denied",
      "fi": "Pääsy kielletty",
      "sv": "Tillträde förbjudet"
    },
    "INVALID_EMAIL": {
      "en": "E-mail is not valid",
      "fi": "Sähköposti ei ole oikeamuotoinen",
      "sv": "E-postadressen är ej korrekt"
    },
    "EMAIL_NOT_UNIQUE": {
      "en": "E-mail is already in use",
      "fi": "Sähköposti on jo käytössä",
      "sv": "E-postadressen är redan i bruk"
    },
    "INVALID_MIN_LENGTH": {
      "en": "The minimum length for the field is ${length} characters",
      "fi": "Kentän vähimmäispituus on ${length} merkkiä",
      "sv": "Minimilängden för fältet är ${length} tecken"
    },
    "NUMBERS_ONLY": {
      "en": "The field must only include numbers",
      "fi": "Kenttä saa sisältää vain numeroita",
      "sv": "Fältet får enbart innehålla siffror"
    },
    "INVALID_PRICE": {
      "en": "Invalid price format",
      "fi": "Epäkelpo hintamuoto",
      "sv": "Felaktig form på priset"
    },
    "INVALID_PASSWORD": {
      "en": "The password must be at least 5 characters",
      "fi": "Salasanan on oltava vähintään 5 merkkiä pitkä",
      "sv": "Lösenordet måste vara minst 5 tecken"
    },
    "INVALID_CREDENTIALS": {
      "en": "Wrong username or password supplied",
      "fi": "Väärä käyttäjätunnus tai salasana",
      "sv": "Fel användarnamn eller lösenord"
    },
    "FILE_IS_UPLOADING": {
      "en": "File is still uploading",
      "fi": "Tiedostoa vielä lähetetään",
      "sv": "Filen skickas fortfarande"
    },
    "INVALID_CURRENT_PASSWORD": {
      "en": "Invalid password",
      "fi": "Väärä salasana",
      "sv": "Fel lösenord"
    },
    "NOT_IDENTICAL": {
      "en": "The fields do not match",
      "fi": "Kentät eivät täsmää",
      "sv": "Fälten stämmer ej överens"
    },
    "START_DATE_LATER_THAN_END_DATE": {
      "en": "The end date must be later than the start date",
      "fi": "Loppumispäivän täytyy olla alkamispäivän jälkeen",
      "sv": "Slutdatum måste vara efter startdatum"
    },
    "INVALID_DATE_FORMAT": {
      "en": "The date is invalid",
      "fi": "Päivämäärä on virheellinen",
      "sv": "Datumet är ogiltigt"
    },
    "INVALID_START_DATE_FORMAT": {
      "en": "The start date is invalid",
      "fi": "Alkamispäivä on virheellinen",
      "sv": "Felaktigt startdatum"
    },
    "INVALID_END_DATE_FORMAT": {
      "en": "The end date is invalid",
      "fi": "Loppumispäivä on virheellinen",
      "sv": "Felaktigt slutdatum"
    },
    "SERVER_ERROR": {
      "en": "Something went wrong in the back-end system, please try again",
      "fi": "Taustajärjestelmässä tapahtui virhe, ole hyvä ja yritä uudelleen",
      "sv": "Något gick snett i systemet. Var god försök igen"
    },
    "INVALID_SSN": {
      "en": "Invalid social security number",
      "fi": "Virheellinen henkilötunnus",
      "sv": "Felaktigt personnummer"
    },
    "INVALID_DATA": {
      "en": "Invalid data",
      "fi": "Tiedot ovat virheellisiä",
      "sv": "Uppgifterna är inte korrekta"
    },
    "BALANCE_WITHDRAW_DISABLED": {
      "en": "Balance withdraw is disabled",
      "fi": "",
      "sv": ""
    },
    "NOT_ENOUGH_BALANCE": {
      "en": "Not enough balance",
      "fi": "Ei tarpeeksi saldoa",
      "sv": "Inte tillräckligt med saldo"
    },
    "MINIMUM_WITHDRAW_NOT_MET": {
      "en": "You must withdraw at least ${minimumWithdraw} euro",
      "fi": "Sinun on nostettava vähintään ${minimumWithdraw} euro",
      "sv": "Du måste lyfta minst ${minimumWithdraw} euro"
    },
    "MINIMUM_WITHDRAW_MUST_EXCEED_REIMBURSEMENTS": {
      "en": "You must withdraw at least the amount of your travel expenses totaling ${reimbursements} euros",
      "fi": "Sinun on nostettava vähintään matkakulujesi kokonaismäärä ${reimbursements} euroa",
      "sv": ""
    },
    "INVALID_IBAN": {
      "en": "Invalid IBAN account number",
      "fi": "IBAN-tilinumero on väärässä muodossa",
      "sv": "IBAN-kontonumret i fel form"
    },
    "INVALID_BIC": {
      "en": "Invalid BIC code",
      "fi": "BIC-koodi ei ole oikein",
      "sv": "BIC-koden stämmer ej"
    },
    "INVALID_EXTENSION": {
      "en": "Invalid file format.",
      "fi": "Väärä tiedostomuoto.",
      "sv": ""
    },
    "INVALID_PRODUCT_VAT": {
      "en": "Invalid VAT-%.",
      "fi": "Virheellinen ALV-kanta.",
      "sv": ""
    },
    "CREDIT_NOT_ENOUGH_BALANCE": {
      "en": "The user does not have enough balance to credit the invoice. If the user has open pay withdrawals or reimbursements, please reject them and try again.",
      "fi": "Käyttäjällä ei ole tarpeeksi saldoa tilillä hyvitykseen. Jos käyttäjällä on avoimia palkkapyyntöjä tai kulukorvauksia, hylkää ne ja yritä uudelleen.",
      "sv": ""
    },
    "CREDIT_NOT_ENOUGH_TAX_FREE_BALANCE": {
      "en": "The user does not have enough tax-free balance to credit the invoice. If the user has open pay withdrawals with tax-free shares, please reject them and try again.",
      "fi": "Käyttäjällä ei ole tarpeeksi verovapaata saldoa tilillä hyvitykseen. Jos käyttäjällä on avoimia palkkapyyntöjä verovapaalla osuudella, hylkää ne ja yritä uudelleen.",
      "sv": ""
    }
  },
  "emails": {
    "attachments": {
      "contract": {
        "en": "WorkContract.pdf",
        "fi": "Tyosopimus.pdf",
        "sv": "Arbetsavtal.pdf"
      }
    }
  },
  "balanceHistory": {
    "typeFormat": {
      "allocation": {
        "en": "Invoice #${invoiceId} billed",
        "fi": "Lasku #${invoiceId} laskutettu",
        "sv": "Faktura #${invoiceId} fakturerad"
      },
      "deposit": {
        "en": "Invoice #${invoiceId} paid",
        "fi": "Lasku #${invoiceId} maksettu",
        "sv": "Faktura #${invoiceId} betald"
      },
      "balanceFix": {
        "en": "Balance correction",
        "fi": "Saldon korjaus",
        "sv": ""
      },
      "credit": {
        "en": "Invoice #${invoiceId} credited",
        "fi": "Lasku #${invoiceId} hyvitetty",
        "sv": ""
      }
    },
    "expenses": {
      "en": "Travel expenses",
      "fi": "Matkakulut",
      "sv": "Resekostnader"
    },
    "commissions": {
      "en": "Service fee",
      "fi": "Hallintokorvaus",
      "sv": "Serviceavgift"
    }
  },
  "files": {
    "filesList": {
      "en": "Files",
      "fi": "Tiedostot",
      "sv": ""
    },
    "filters": {
      "all": {
        "en": "All",
        "fi": "Kaikki",
        "sv": "Alla"
      }
    },
    "types": {
      "news": {
        "en": "News",
        "fi": "Ajankohtaista",
        "sv": ""
      },
      "instructions": {
        "en": "Instructions",
        "fi": "Ohjeet",
        "sv": ""
      }
    },
    "info": {
      "id": {
        "en": "#",
        "fi": "#",
        "sv": "#"
      },
      "type": {
        "en": "Type",
        "fi": "Tyyppi",
        "sv": ""
      },
      "file": {
        "en": "File",
        "fi": "Tiedosto",
        "sv": ""
      },
      "showInFrontpage": {
        "en": "Show in frontpage",
        "fi": "Näytä etusivulla",
        "sv": ""
      },
      "created": {
        "en": "Created",
        "fi": "Luotu",
        "sv": "Skapad"
      },
      "titleTranslations": {
        "en": "Title translations",
        "fi": "Otsikon käännökset",
        "sv": ""
      }
    },
    "newFile": {
      "en": "New file",
      "fi": "Uusi tiedosto",
      "sv": ""
    },
    "editFile": {
      "en": "Edit file",
      "fi": "Muokkaa tiedostoa",
      "sv": ""
    },
    "saveFile": {
      "en": "Save file",
      "fi": "Tallenna tiedosto",
      "sv": ""
    },
    "creationSuccess": {
      "en": "File created",
      "fi": "Tiedosto luotu",
      "sv": ""
    },
    "editSuccess": {
      "en": "File updated",
      "fi": "Tiedosto päivitetty",
      "sv": ""
    },
    "confirmDelete": {
      "en": "Are you sure you want to delete this file?",
      "fi": "Oletko varma että haluat poistaa tiedoston?",
      "sv": ""
    },
    "deleteSuccess": {
      "en": "The file was removed",
      "fi": "Tiedosto poistettu",
      "sv": ""
    }
  },
  "general": {
    "yes": {
      "en": "Yes",
      "fi": "Kyllä",
      "sv": ""
    },
    "no": {
      "en": "No",
      "fi": "Ei",
      "sv": ""
    }
  },
  "pdfTemplate": {
    "invoice": {
      "invoice": {
        "en": "INVOICE",
        "fi": "LASKU",
        "sv": "FAKTURA"
      },
      "invoiceReminder": {
        "en": "INVOICE REMINDER",
        "fi": "MUISTUTUSLASKU",
        "sv": "BETALNINGSPÅMINNELSE"
      },
      "creditNote": {
        "en": "CREDIT NOTE",
        "fi": "HYVITYSLASKU",
        "sv": "KREDITFAKTURA"
      },
      "creditAdditionalInfo": {
        "en": "Credit to Mediakunta invoice #${originalInvoiceId}. Reason: ${reason}",
        "fi": "Hyvitys Mediakunnan laskuun #${originalInvoiceId}. Syy: ${reason}",
        "sv": "Kreditering för Medielagets faktura #${originalInvoiceId}. Anledning: ${reason}"
      },
      "billerName": {
        "en": "Mediakunta Cooperative",
        "fi": "Mediakunta Osuuskunta",
        "sv": "Andelslaget Medielaget"
      },
      "businessId": {
        "en": "Business ID",
        "fi": "Y-tunnus",
        "sv": "FO-nummer"
      },
      "taxCode": {
        "en": "Tax code",
        "fi": "Alv-tunnus",
        "sv": "Registreringsnummer för mervärdesskatt"
      },
      "invoiceDate": {
        "en": "Invoice date",
        "fi": "Laskun päiväys",
        "sv": "Fakturadatum"
      },
      "paymentDueDate": {
        "en": "Payment due",
        "fi": "Eräpäivä",
        "sv": "Förfallodag"
      },
      "referenceNumber": {
        "en": "Reference number",
        "fi": "Viitenumero",
        "sv": "Referensnummer"
      },
      "invoiceNumber": {
        "en": "Invoice number",
        "fi": "Laskunumero",
        "sv": "Fakturanummer"
      },
      "clientBusinessId": {
        "en": "Client Business Identity Code",
        "fi": "Asiakkaan y-tunnus",
        "sv": "Kundens FO-nummer"
      },
      "productName": {
        "en": "Item",
        "fi": "Selite",
        "sv": "Specifikation"
      },
      "productPrice": {
        "en": "Price",
        "fi": "à hinta",
        "sv": "à pris"
      },
      "productQuantity": {
        "en": "Quantity",
        "fi": "Määrä",
        "sv": "Antal"
      },
      "productPreTax": {
        "en": "Pre-tax",
        "fi": "Yhteensä veroton",
        "sv": "Totalt (utan skatt)"
      },
      "productVatPercentage": {
        "en": "VAT",
        "fi": "ALV-%",
        "sv": "Moms, %"
      },
      "productVatTotal": {
        "en": "VAT total",
        "fi": "ALV yhteensä",
        "sv": "Moms totalt"
      },
      "productTotalInclVat": {
        "en": "Incl. VAT",
        "fi": "Yhteensä veroineen",
        "sv": "Totalt, inklusive moms"
      },
      "unitQuantity": {
        "en": "pcs",
        "fi": "kpl",
        "sv": "st"
      },
      "noProducts": {
        "en": "Nothing to bill",
        "fi": "Ei laskutettavaa",
        "sv": "Ingenting att fakturera"
      },
      "total": {
        "en": "Total",
        "fi": "Yhteensä",
        "sv": "Totalt"
      },
      "taxRate": {
        "en": "Tax rate",
        "fi": "Verokanta",
        "sv": "Momssats"
      },
      "taxExempt": {
        "en": "Tax exempt",
        "fi": "Veroton",
        "sv": "Utan skatt"
      },
      "amountOfTax": {
        "en": "Amount of tax",
        "fi": "Veron määrä",
        "sv": "Skattens andel"
      },
      "taxable": {
        "en": "Taxable",
        "fi": "Verollinen",
        "sv": "Beskattningsbart"
      },
      "payeeBankDetails": {
        "en": "Payee bank details",
        "fi": "Saajan tilinumero",
        "sv": "Betalningsmottagarens kontonummer"
      },
      "payee": {
        "en": "Payee",
        "fi": "Saaja",
        "sv": "Betalningsmottagare"
      },
      "payerNameAndAddress": {
        "en": "Payer name and address",
        "fi": "Maksajan nimi ja osoite",
        "sv": "Betalarens namn och adress"
      },
      "enterPayeeReferenceNumber": {
        "en": "Use the reference number.",
        "fi": "Käytä maksaessa viitenumeroa.",
        "sv": "Använd referensnumret vid betalning"
      },
      "doNotPay": {
        "en": "Credit note, do not pay",
        "fi": "Hyvityslasku, ei veloitusta",
        "sv": "Kreditfaktura, ingen debitering"
      },
      "referenceNumberShort": {
        "en": "Reference",
        "fi": "Viitenro",
        "sv": "Referensnummer"
      },
      "paidFromAccountNumber": {
        "en": "Paid from account",
        "fi": "Tililtä nro",
        "sv": "Från konto"
      },
      "dueAt": {
        "en": "Due",
        "fi": "Eräpäivä",
        "sv": "Förfallodag"
      },
      "dueAtImmediately": {
        "en": "IMMEDIATELY",
        "fi": "HETI",
        "sv": "OMEDELBART"
      },
      "additionalInfo": {
        "en": "Details",
        "fi": "Lisätietoja",
        "sv": "Tilläggsuppgifter"
      }
    }
  },
  "contracts": {
    "contractList": {
      "en": "Contracts",
      "fi": "Sopimukset",
      "sv": "Kontrakt"
    },
    "info": {
      "id": {
        "en": "#",
        "fi": "#",
        "sv": "#"
      },
      "user": {
        "en": "User",
        "fi": "Käyttäjä",
        "sv": ""
      },
      "description": {
        "en": "Description",
        "fi": "Selite",
        "sv": "Beskrivning"
      },
      "file": {
        "en": "File",
        "fi": "Tiedosto",
        "sv": "Fil"
      },
      "created": {
        "en": "Added",
        "fi": "Lisätty",
        "sv": ""
      }
    },
    "newContract": {
      "en": "New contract",
      "fi": "Uusi sopimus",
      "sv": "Nytt kontrakt"
    },
    "editContract": {
      "en": "Edit contract",
      "fi": "Muokkaa sopimusta",
      "sv": ""
    },
    "saveContract": {
      "en": "Save contract",
      "fi": "Tallenna sopimus",
      "sv": "Spara kontrakt"
    },
    "saveSuccess": {
      "en": "Contract saved",
      "fi": "Sopimus tallennettu",
      "sv": ""
    },
    "editSuccess": {
      "en": "Contract updated",
      "fi": "Sopimus päivitetty",
      "sv": ""
    },
    "confirmDelete": {
      "en": "Are you sure you want to delete this contract?",
      "fi": "Oletko varma että haluat poistaa sopimuksen?",
      "sv": ""
    },
    "deleteSuccess": {
      "en": "The contract was removed",
      "fi": "Sopimus poistettu",
      "sv": ""
    },
    "newContractFromUserPage": {
      "en": "You can add contracts for members from their user page.",
      "fi": "Voit lisätä sopimuksia jäsenille heidän käyttäjäsivulta.",
      "sv": ""
    },
    "newContractForUser": {
      "en": "New contract for user ${name}",
      "fi": "Uusi sopimus käyttäjälle ${name}",
      "sv": ""
    },
    "creatingContractForUserNotification": {
      "en": "You are adding a new contract for another user. All the information you enter can be viewed by the user <link>${name}</link> after you save the contract.",
      "fi": "Olet lisäämässä sopimusta toiselle käyttäjälle. Kaikki syöttämäsi tiedot näkyy käyttäjälle <link>${name}</link> kun tallennat sopimuksen.",
      "sv": ""
    }
  }
}
