import { createAction, persistentProperty } from 'utils/store'

const [sortTable, onTableSorted$] = createAction()
const [changeInvoiceStatusFilter, changeInvoiceStatusFilter$] = createAction()
const [changeInvoiceTypeFilter, changeInvoiceTypeFilter$] = createAction()

const sortedBy$ = persistentProperty(
  onTableSorted$.scan((prev, next) => {
    const direction =
      prev.key === next.key && prev.direction !== 'DESC' ? 'DESC' : 'ASC'
    return { key: next.key, sortFn: next.sortFn, direction }
  }),
  { key: 'id', direction: 'DESC' }
)

const invoiceStatusFilter$ = persistentProperty(
  changeInvoiceStatusFilter$,
  'all'
)
const invoiceTypeFilter$ = persistentProperty(changeInvoiceTypeFilter$, 'all')

export {
  sortedBy$,
  sortTable,
  changeInvoiceStatusFilter,
  changeInvoiceTypeFilter,
  invoiceStatusFilter$,
  invoiceTypeFilter$,
}
