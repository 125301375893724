import Kefir from 'kefir'
import * as U from 'karet.util'
import * as L from 'partial.lenses'
import { persistentProperty, createAction } from 'utils/store'
import * as api from 'api'

export const [ clearAllData, clearAllData$ ] = createAction(() => clearAllPersistedData())
export const [ clearPayment, clearPayment$ ] = createAction(() => removePersonalDataAndPayment())

export const [ createPayment, createAndStorePayment$ ] =
  createAction(personalData =>
    api.createJoinPayment(personalData)
      .then(payment => persistPersonalDataAndPayment(personalData, payment)))

export const [ goBackToEditPersonalData, goBackToEditPersonalData$ ] =
  createAction(async () => {
    const personalData = await personalData$.take(1).toPromise()
    removePersonalDataAndPayment()
    await updateUnvalidatedPersonalData(personalData)
    return null
  })

const paymentAndPersonalData$ = persistentProperty(
  Kefir.merge([
    Kefir.constant(getInitialPayment()),
    createAndStorePayment$,
    goBackToEditPersonalData$,
    clearPayment$.map(() => null),
    clearAllData$.map(() => null)
  ])
)

export const personalData$ = paymentAndPersonalData$.map(L.get('personalData'))
export const payment$ = paymentAndPersonalData$.map(L.get('payment'))

export const step$ = payment$
  .map(Boolean)
  .map(hasPayment => hasPayment ? 'paymentMethod' : 'personalData')

export const [ updateUnvalidatedPersonalData, updateUnvalidatedPersonalData$ ] =
  createAction(personalData => persistUnvalidatedPersonalData(personalData))

export const unvalidatedPersonalData$ = persistentProperty(
  Kefir.merge([
    Kefir.constant(getUnvalidatedPersonalData()),
    updateUnvalidatedPersonalData$,
    clearAllData$.map(() => null)
  ])
)

function getInitialPayment() {
  if (window.sessionStorage) {
    try {
      const paymentJson = window.sessionStorage.getItem('join:payment')
      if (paymentJson) {
        return JSON.parse(paymentJson)
      }
    } catch (err) {}
  }
  return null
}

function getUnvalidatedPersonalData() {
  if (window.sessionStorage) {
    try {
      const personalDataJson = window.sessionStorage.getItem('join:unvalidatedPersonalData')
      if (personalDataJson) {
        return JSON.parse(personalDataJson)
      }
    } catch (err) {}
  }
  return null
}

function persistUnvalidatedPersonalData(data) {
  if (window.sessionStorage) {
    try {
      window.sessionStorage.setItem('join:unvalidatedPersonalData', JSON.stringify(data))
    } catch (err) {}
  }
  return data
}

function persistPersonalDataAndPayment(personalData, payment) {
  const data = {
    personalData,
    payment
  }
  if (window.sessionStorage) {
    try {
      window.sessionStorage.setItem('join:payment', JSON.stringify(data))
    } catch (err) {}
  }
  return data
}

async function removePersonalDataAndPayment() {
  if (window.sessionStorage) {
    try {
      window.sessionStorage.removeItem('join:payment')
    } catch (err) {}
  }
}

function clearAllPersistedData() {
  if (window.sessionStorage) {
    try {
      window.sessionStorage.removeItem('join:unvalidatedPersonalData')
      window.sessionStorage.removeItem('join:payment')
    } catch (err) {}
  }
}
