import Decimal from 'decimal.js'
import { validateOne } from 'shared/utils'

const letterToNum = char => char.charCodeAt(0) - 55

export default function() {
  return validateOne(val => {
    if (typeof val !== 'string') {
      return false
    }
    const iban = val.replace(/\s/g, '').toUpperCase()
    if (!iban.match(/^[A-Z]{2}[0-9A-Z]+$/)) {
      return false
    }
    if (iban.substr(0, 2) === 'FI' && (!iban.match(/^FI\d+$/) || iban.length !== 18)) {
      return false
    }
    const countryCodeAndChecksumMovedToEnd = `${iban.substr(4)}${iban.substr(0, 4)}`
    const lettersConvertedToNumbers = countryCodeAndChecksumMovedToEnd.replace(/[A-Z]/g, letterToNum)
    const checksum = new Decimal(lettersConvertedToNumbers).mod(97).toString()
    return checksum === '1'
  }, 'INVALID_IBAN')
}
