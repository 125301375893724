import React from 'karet'
import Header from '../App/Header.jsx'
import Content from '../App/Content.jsx'
import Footer from '../App/Footer.jsx'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'

export default ({ children }) => (
  <div>
    <Header linkToHome={false} />
    <Content>
      <div className={styles.title}>{loc$('join.title')}</div>
      {children}
    </Content>
    <Footer />
  </div>
)
