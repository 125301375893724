import React from 'karet'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import JoinSteps from './JoinSteps.jsx'
import * as joinPayment from 'stores/join-payment'
import { Link, Button } from 'components'

export default () => {
  joinPayment.clearPayment()

  return (
    <div>
      <JoinSteps step="pay" />
      <h1>{loc$('join.cancelTitle')}</h1>
      <p>{loc$('join.cancelDescription')}</p>
      <Link to="/join"><Button label="join.backToBeginningButton" /></Link>
    </div>
  )
}
