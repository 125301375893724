import React from 'karet'
import { loc$, currency$ } from 'utils/i18n'
import * as U from 'karet.util'
import styles from './BalanceSummary.css'

export default ({ balanceInfo }) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th className={styles.corner}></th>
        <th>{loc$('balanceSummary.balanceTitle')}</th>
        <th>{loc$('balanceSummary.reimbursementsTitle')}</th>
      </tr>
    </thead>
    <tbody>
      <tr className={styles.baseTotal}>
        <th>{loc$('balanceSummary.totalBalance')}</th>
        <td>{U.seq(balanceInfo, U.view(['total', 'balance']), currency$)}</td>
        <td>{U.seq(balanceInfo, U.view(['total', 'reimbursements']), currency$)}</td>
      </tr>
      <tr className={styles.awaitingAddition}>
        <th>{loc$('balanceSummary.waitingPayment')}</th>
        <td>{U.seq(balanceInfo, U.view(['allocation', 'balance']), currency$)}</td>
        <td>{U.seq(balanceInfo, U.view(['allocation', 'reimbursements']), currency$)}</td>
      </tr>
      <tr className={styles.awaitingReduction}>
        <th>{loc$('balanceSummary.unacceptedReimbursements')}</th>
        <td>{U.seq(balanceInfo, U.view(['reserved', 'balance']), currency$)}</td>
        <td>{U.seq(balanceInfo, U.view(['reserved', 'reimbursements']), currency$)}</td>
      </tr>
      <tr className={styles.pendingPayRequests}>
        <th>{loc$('balanceSummary.pendingPayRequests')}</th>
        <td>{U.seq(balanceInfo, U.view(['payRequests', 'balance']), currency$)}</td>
        <td>{U.seq(balanceInfo, U.view(['payRequests', 'reimbursements']), currency$)}</td>
      </tr>
      <tr className={styles.total}>
        <th>{loc$('balanceSummary.availableBalance')}</th>
        <td>{U.seq(balanceInfo, U.view(['available', 'balance']), currency$)}</td>
        <td>{U.seq(balanceInfo, U.view(['available', 'reimbursements']), currency$)}</td>
      </tr>
    </tbody>
  </table>
)
