export default {
  fields: {
    firstName: {
      required: true,
      validators: [
        ['minLength', { length: 2 }]
      ]
    },
    lastName: {
      required: true,
      validators: [
        ['minLength', { length: 2 }]
      ]
    },
    ssn: {
      required: true,
      validators: ['validSSN']
    },
    phoneNumber: {
      required: true,
      validators: ['isString']
    },
    streetAddress: {
      required: true,
      validators: ['isString']
    },
    postalCode: {
      required: true,
      validators: ['isNumber']
    },
    postOffice: {
      required: true,
      validators: ['isString']
    },
    bankIban: {
      required: true,
      validators: ['isString', 'validIban'],
      dependencies: ['bankBic']
    },
    bankBic: {
      required: ({ bankIban }) => bankIban && !bankIban.toString().match(/^FI/i),
      validators: ['isString', 'validBic']
    },
    introduction: {
      validators: ['isString']
    },
    taxCard: {
      required: false,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb']
      }
    }
  }
}
