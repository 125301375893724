import React from 'karet'
import * as U from 'karet.util'
import styles from './FileUploadButton.css'
import { idGenerator } from 'utils'

const fileUploadId = idGenerator()

export default ({ extensions, children, className, onSelectFile }) => {
  const id = `file-upload-${fileUploadId.next().value}`

  const onInputMount = input => {
    if (input) {
      input.addEventListener('change', () => {
        if (input.files && input.files[0]) {
          onSelectFile(input.files[0])
        }
      })
    }
  }

  return (
    <div {...U.classes(styles.fileUploadButton, className)}>
      <div className={styles.selectFile}>
        <input
          id={id}
          ref={onInputMount}
          type="file"
          accept={extensions.map(ext => `.${ext}`).join(',')}
          className={styles.fileInput}
        />
        <label htmlFor={id} className={styles.label}>{children}</label>
      </div>
    </div>
  )
}
