export default [
  {
    isIndex: true,
    path: '/balance',
    localizationKey: 'balance.summary',
  },
  {
    path: '/balance/withdraw',
    localizationKey: 'balance.withdraw',
  },
  {
    path: '/balance/payslip-calculator',
    localizationKey: 'balance.payslipCalculator',
  },
  {
    path: '/balance/reimbursements',
    localizationKey: 'balance.reimbursements',
  },
]
