import Promise from 'bluebird'
import validateField from './validateField'

export default function(values, schema, context) {
  const validationPromises =
    Object.keys(schema).map((field) =>
      validateField(values[field], schema[field], context)
        .then(validity => ({ field, validity })))

  return Promise.reduce(validationPromises, (result, { field, validity }) => {
    if (validity.valid) {
      return result
    } else {
      return {
        valid: false,
        errors: {
          ...result.errors,
          [field]: validity.errors
        }
      }
    }
  }, { valid: true, errors: {} })
}
