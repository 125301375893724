import React from 'karet'
import { classes, ift, default as K } from 'karet.util'
import styles from './Loader.css'

export default ({ size = 16, theme, className }) => (
  <div
    {...classes(
      className,
      styles.loader,
      ift(theme, K(theme, theme => styles[`theme-${theme}`]))
    )}
    style={{ fontSize: `${size}px` }}
  />
)
