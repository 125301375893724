import React from 'karet'
import Decimal from 'decimal.js'
import * as U from 'karet.util'
import R from 'ramda'

import { Link, Button } from 'components'
import { balanceAfterFees$, withdrawBalance, hasTaxCard$, reimbursementsBalance$ } from 'stores/user'
import { createAction } from 'utils/store'
import { addToast } from 'stores/toasts'
import { loc, loc$ } from 'utils/i18n'
import { decimalFromLocalized, decimalToLocalized } from 'transformers'

import styles from './WithdrawWidget.css'

export default ({minimum}) => {
  const [ changeAmount, onChangeAmount$ ] = createAction()

  const amount$ = reimbursementsBalance$
    .map(balance => new Decimal(balance))
    .filter(balance => balance.gt(0))
    .map(balance => decimalToLocalized(balance.toString()))
    .merge(onChangeAmount$
      .map(val => R.replace(/[^0-9,.]/g, '', val))
      .combine(balanceAfterFees$, (val, balance) => {
        try {
          return new Decimal(decimalFromLocalized(val)).gt(balance) ? decimalToLocalized(balance) : val
        } catch(err) {
          return val
        }
      }))
    .toProperty(R.always(''))

  const buttonDisabled$ = amount$
    .combine(reimbursementsBalance$, (val, reimbursementsBalance) => {
      try {
        const amount = new Decimal(decimalFromLocalized(val))
        const isLessThanMinimumWithdraw = amount.lt(minimum)
        const isLessThanReimbursementsBalance = amount.lt(reimbursementsBalance)
        return isLessThanMinimumWithdraw || isLessThanReimbursementsBalance
      } catch (err) {
        return true
      }
    })
    .skipDuplicates()
    .toProperty(R.always(true))

  const onWithdraw = () => {
    amount$.take(1).onValue(amount => {
      if (confirm(loc('profile.confirmWithdraw', {amount}))) {
        withdrawBalance(amount.replace(',', '.'))
          .then(() => addToast('success', 'profile.withdrawSuccess'))
          .then(() => changeAmount(''))
          .catch(err => addToast('error', `errors.${err.code}`))
      }
    })
  }

  return (
    <div className={styles.widget}>
      {U.ifte(hasTaxCard$,
        (
          <div>
            <div>{loc$('profile.iWantToWithdraw')} <input type="text" value={amount$} placeholder="0,00" onChange={(e) => changeAmount(e.target.value)} /> € {loc$('profile.asSalary')}.</div>
            <Button theme="blue-nohover" disabled={buttonDisabled$} className={styles.confirm} onClick={onWithdraw} label="profile.withdraw" fullWidth />
          </div>
        ),
        (
          <div>
            <div>{loc$('profile.withdrawalNoTaxCard')}</div>
            <Link to="/profile/edit"><Button className={styles.confirm} label={'profile.uploadTaxCard'} fullWidth /></Link>
          </div>
        )
      )}
    </div>
  )
}
