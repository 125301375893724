import R from 'ramda'
import * as L from 'partial.lenses'
import React from 'karet'
import Kefir from 'kefir'
import Promise from 'bluebird'
import styles from './Form.css'
import * as U from 'karet.util'
import { withEvents } from 'utils/containers'
import { groupBy, validateField } from 'shared/utils'
import { createAction, persistentProperty } from 'utils/store'
import { doAction } from 'shared/utils'
import * as transformers from 'transformers'

export default withEvents(React.createClass({
  getInitialState() {
    const { events, disabled = false } = this.props
    const [ focusInput, focusInput$ ] = createAction()

    const formSubmitStart$ = Kefir.fromEvents(events, 'form:submit:start')
    const formSubmitEnd$ = Kefir.fromEvents(events, 'form:submit:end')
    const formValidate$ = Kefir.fromEvents(events, 'form:validate')
    const formUnmount$ = Kefir.fromEvents(events, 'form:unmount')

    const submitting$ = persistentProperty(
      Kefir.merge([
        formSubmitStart$.map(R.always(true)),
        formSubmitEnd$.map(R.always(false))
      ])
      .toProperty(R.always(false))
      .takeUntilBy(formUnmount$)
    )

    const disabled$ = U.toProperty(disabled)

    const inputMount$ = Kefir.fromEvents(events, 'input:mount')
    const inputUnmount$ = Kefir.fromEvents(events, 'input:unmount')
    const inputValue$ =
      Kefir.fromEvents(events, 'input:value')
        .filterBy(submitting$.map(R.not))
        .filterBy(disabled$.map(R.not))
    const inputBlur$ =
      Kefir.fromEvents(events, 'input:blur')
        .filterBy(submitting$.map(R.not))
        .filterBy(disabled$.map(R.not))
    const inputError$ = Kefir.fromEvents(events, 'input:error')
    const dependencyChanged$ = Kefir.fromEvents(events, 'input:dependency')

    const inputInit$ =
      inputMount$
        .map(({ name, schema, defaultValue, defaultTo }) => ({
          name,
          schema,
          defaultValue,
          defaultTo,
          value: this.getFieldInitialValue(name, schema, defaultValue),
          valid: false,
          errors: [],
          dirty: false,
          validated: false
        }))

    // TODO: handle if server responds with an error
    const inputDirty$ =
      inputValue$
        .filter(R.prop('validate'))
        .map(R.merge({ debounce: 500 }))
        .map(doAction(({ schema }) => {
          if (schema.dependencies) {
            schema.dependencies.forEach(name => events.emit('input:dependency', { name }))
          }
        }))
        .merge(inputBlur$
          .filter(R.prop('validate'))
          .map(R.merge({
            debounce: 0,
            validateIf: ({ dirty, validated }) => !dirty && !validated
          }))
          .merge(dependencyChanged$.map(R.merge({
            debounce: 500,
            validateIf: ({ dirty, validated }) => dirty || validated
          })))
          .flatMap(({ name, debounce, validateIf }) =>
            form$
              .take(1)
              .map(R.prop(name))
              .filter(validateIf)
              .map(({ schema, value, defaultValue, defaultTo }) => ({
                name, schema, value, defaultValue, defaultTo, debounce
              }))))

    const inputClearValidity$ = inputValue$.filter(({ validate }) => !validate)

    const inputValidate$ =
      inputDirty$
        .flatMap(groupBy(R.prop('name'), (stream, name) =>
          stream
            .flatMapLatest(({ debounce, schema, value, defaultValue, defaultTo }) =>
              Kefir.later(debounce)
                .flatMapLatest(() =>
                  Kefir.fromPromise(this.validateField(name, schema, value, defaultValue, defaultTo))))
            .map(R.merge({ name }))
            .takeUntilBy(inputUnmount$.filter(R.equals(name)))))
        .merge(formValidate$
          .map(R.mapObjIndexed((validation, name) => ({ ...validation, name })))
          .map(R.values)
          .flatMap(arr => Kefir.sequentially(0, arr)))

    const form$ = persistentProperty(
      Kefir.merge([
        // input mounts
        inputInit$.map(({ name, ...defaults }) =>
          L.set(name, defaults)),
        // input unmounts
        inputUnmount$.map(name =>
          L.remove(name)),
        // input value changes
        inputValue$.map(({ name, value }) =>
          L.modify(name, R.merge(R.__, { value }))),
        inputDirty$.map(({ name }) =>
          L.modify(name, R.merge(R.__, { dirty: true }))),
        inputClearValidity$.map(({ name }) =>
          L.modify(name, R.merge(R.__, { valid: false, errors: [], validated: false }))),
        inputValidate$.map(({ name, valid, errors }) =>
          L.modify(name, R.merge(R.__, { valid, errors, dirty: false, validated: true }))),
        inputError$.map(({ name, error }) =>
          L.modify(name, R.merge(R.__, { valid: false, errors: [{ reason: error }] })))
      ])
      .scan((form, updateF) => updateF(form), {})
      .takeUntilBy(formUnmount$)
    )

    const values$ = form$
      .map(R.toPairs)
      .map(form => R.reduce(
        ({ values, indexCache }, [name, {value, defaultValue, defaultTo, schema}]) => {
          const normalizedValue = this.normalizeValue(value, defaultValue, defaultTo)
          const transformerFn = this.getTransformer(schema, 'save')
          const transformedValue = R.isNil(normalizedValue) ? normalizedValue : transformerFn(normalizedValue)
          const lens = name
            .match(/[^\[\]]+/g)
            .reduce((lens, it) => {
              const path = lens.join('.')
              if (it.match(/^\d+$/)) {
                if (!indexCache[path]) {
                  indexCache[path] = { indices: {}, currentIndex: 0 }
                }
                if (indexCache[path].indices[it] === undefined) {
                  indexCache[path].indices[it] = indexCache[path].currentIndex++
                }
                return lens.concat(indexCache[path].indices[it])
              } else {
                return lens.concat(it)
              }
            }, [])
          return { values: L.set(lens, transformedValue, values), indexCache }
        }, { values: {}, indexCache: {} }, form))
      .map(R.prop('values'))
      .skipDuplicates(R.equals)

    const fields$ = form$.map(Object.values)
    const valid$ = fields$.map(R.all(R.propEq('valid', true))).skipDuplicates()
    const dirty$ = fields$.map(R.any(R.propEq('dirty', true))).skipDuplicates()
    const validated$ = fields$.map(R.all(R.propEq('validated', true))).skipDuplicates()

    return {
      form$,
      values$,
      valid$,
      dirty$,
      validated$,
      inputValidate$,
      submitting$,
      disabled$,
      focusInput,
      focusInput$
    }
  },
  getContext() {
    return {
      form: this,
      schema: this.props.schema || {},
      name: ''
    }
  },
  getTransformer(schema, type) {
    const trans = L.get(['transformers', type], schema)
    if (!trans) {
      return R.identity
    }
    const transFns = trans.map(t => {
      if (transformers[t]) {
        return transformers[t]
      } else {
        console.warn(`Unknown transformer ${t}`)
        return R.identity
      }
    })
    return R.pipe(...transFns)
  },
  getFieldInitialValue(name, schema, defaultValue) {
    const { initialValues = {} } = this.props
    const transformerFn = this.getTransformer(schema, 'load')
    const path = name
      .match(/[^\[\]]+/g)
      .map(it => it.match(/^\d+$/) ? Number(it) : it)
    const value = L.get(path, initialValues)
    return !R.isNil(value)
      ? transformerFn(value)
      : defaultValue
  },
  isFieldArray(name) {
    const fieldSchema = this.props.schema[name] || {}
    return !!fieldSchema.isArray
  },
  getFieldAsProperty(name) {
    return this.state.form$.map(R.prop(name)).skipDuplicates()
  },
  isFieldRequired$(schema) {
    if (typeof schema.required !== 'function') {
      return Kefir.constant(schema.required)
    }
    const { values$ } = this.state
    return values$.map(values => schema.required(values))
  },
  getFieldFocusStream(name) {
    return this.state.focusInput$.filter(R.equals(name))
  },
  getDisabledProperty() {
    return this.state.disabled$
  },
  inputChange(name, schema, value, validate, defaultValue, defaultTo) {
    this.props.events.emit('input:value', { name, schema, value, validate, defaultValue, defaultTo })
  },
  inputBlur(name, validate, defaultValue, defaultTo) {
    this.props.events.emit('input:blur', { name, validate, defaultValue, defaultTo })
  },
  componentWillUnmount() {
    this.props.events.emit('form:unmount')
  },
  componentDidMount() {
    const { events, onChange } = this.props
    const { values$ } = this.state
    const formUnmount$ = Kefir.fromEvents(events, 'form:unmount')

    if (onChange) {
      values$
        .takeUntilBy(formUnmount$)
        .onValue(onChange)
    }
  },
  formInputWillMount(name, schema, defaultValue, defaultTo) {
    this.props.events.emit('input:mount', { name, schema, defaultValue, defaultTo })
  },
  formFieldWillUnmount(name) {
    this.props.events.emit('input:unmount', name)
  },
  normalizeValue(value, defaultValue, defaultTo) {
    return (R.equals(value, defaultValue) && defaultTo !== undefined)
      ? defaultTo
      : value
  },
  async validateField(name, schema, value, defaultValue, defaultTo) {
    const { values$ } = this.state
    if (R.isNil(schema) || R.isEmpty(schema)) {
      console.warn(`Missing schema for field "${name}"`)
      return { valid: true, errors: [] }
    }
    const context = await U.toProperty(this.props.context).take(1).toPromise()
    const values = await values$.take(1).toPromise()
    const normalizedValue = this.normalizeValue(value, defaultValue, defaultTo)
    const transformerFn = this.getTransformer(schema, 'validate')
    const transformedValue = R.isNil(normalizedValue) ? normalizedValue : transformerFn(normalizedValue)
    return validateField(transformedValue, schema, context, values)
  },
  async validateForm() {
    const { form$, inputValidate$ } = this.state
    const invalidFields =
      await form$.map(
        R.filter(({valid, dirty}) => !valid || dirty))
        .take(1)
        .toPromise()
    const fieldNames = Object.keys(invalidFields)

    const validityArr = await Promise.all(fieldNames.map(name => {
      const { schema, value, defaultValue, defaultTo, dirty, validated, errors } = invalidFields[name]
      if (dirty) {
        return inputValidate$
          .filter(R.propEq('name', name))
          .map(R.omit('name'))
          .take(1)
          .toPromise()
      } else if (!validated) {
        return this.validateField(name, schema, value, defaultValue, defaultTo)
      } else {
        return { valid: false, errors }
      }
    }))

    return R.zipObj(fieldNames, validityArr)
  },
  async submit() {
    const { events, onSubmit } = this.props
    const { submitting$, disabled$, values$, focusInput } = this.state

    const submitting = await submitting$.take(1).toPromise()
    const disabled = await disabled$.take(1).toPromise()

    if (submitting || disabled) {
      return
    }

    const addError = (name, error) => {
      events.emit('input:error', { name, error })
      focusInput(name)
    }

    try {
      events.emit('form:submit:start')
      
      const validation = await this.validateForm()
      const invalidFields = R.filter(({valid}) => !valid, validation)

      if (R.isEmpty(invalidFields)) {
        const values = await values$.take(1).toPromise()
        await Promise.resolve(onSubmit(values, addError))
      } else {
        const firstInvalidField = Object.keys(invalidFields)[0]
        events.emit('form:validate', invalidFields)
        focusInput(firstInvalidField)
      }
    } finally {
      events.emit('form:submit:end')
    }
  },
  render() {
    const { className, children, submitStream = U.never } = this.props
    const onSubmit = e => {
      e.preventDefault()
      this.submit()
    }

    return (
      <div>
        <U.Context context={{ formAggregator: this.getContext() }}>
          <form {...U.classes(className, styles.form)} onSubmit={onSubmit}>
            {children}
          </form>
        </U.Context>
        {U.seq(submitStream, U.lift1(() => this.submit()), U.sink)}
      </div>
    )
  }
}))
