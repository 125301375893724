export default [
  {
    isIndex: true,
    path: '/user',
    localizationKey: 'user.usersList'
  },
  {
    path: '/user/new',
    localizationKey: 'user.newUser'
  }
]
