import React from 'karet'
import * as U from 'karet.util'
import { Link } from 'components'
import { loc$ } from 'utils/i18n'
import { isAdmin$ } from 'stores/user'
import styles from './SubNavigation.css'

export default ({ links }) => {
  return (
    <div className={styles.subnavigation}>
      {links.map((link, idx) =>
        U.ift(U.or(!link.isAdmin, isAdmin$), (
          <Link index={link.isIndex} to={link.path} activeClassName={styles.active} key={idx}>
            {loc$(link.localizationKey)}
          </Link>
        ))
      )}
    </div>
  )
}
