import R from 'ramda'
import React from 'karet'

export const classNames = (...args) => R.flatten(args).filter(Boolean).join(' ')

export const cloneElements = (elems, matchFn, props) =>
  React.Children.map(elems, (elem) =>
    React.cloneElement(
      elem,
      matchFn(elem.props) && props,
      cloneElements(elem.props.children, matchFn, props)
    )
  )

export function* idGenerator() {
  let id = 0
  while (true) { // eslint-disable-line
    yield id++
  }
}
