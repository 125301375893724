import { createAction, persistentProperty } from 'utils/store'
const [ changeStatusFilter, changeStatusFilter$ ] = createAction()

const [ sortTable, onTableSorted$ ] = createAction()
const sortedBy$ = persistentProperty(onTableSorted$.scan((prev,next) => {
  const direction = (prev.key === next.key && prev.direction !== 'DESC') ? 'DESC' : 'ASC'
  return { key: next.key, sortFn: next.sortFn, direction }
}), {key: 'name', direction: 'ASC'})

const statusFilter$ = persistentProperty(changeStatusFilter$, 'all')

export {
  sortedBy$,
  sortTable,
  statusFilter$,
  changeStatusFilter
}
