import R from 'ramda'
import React from 'karet'
import { loc$ } from 'utils/i18n'
import styles from './Form.css'
import { withFormAggregator } from 'utils/forms'
import * as U from 'karet.util'
import { ErrorTooltip } from 'components'
import { createAction, persistentProperty } from 'utils/store'
import { replaceLink } from 'utils/misc'

export default withFormAggregator(React.createClass({
  getInitialState() {
    const { formAggregator } = this.props
    const { form, name, schema } = formAggregator

    const [ unmount, unmount$ ] = createAction()
    const [ setFocusableInput, focusableInput$ ] = createAction()

    const focusableInputRef$ = persistentProperty(focusableInput$.takeUntilBy(unmount$))
    const field$ =
      form.getFieldAsProperty(name)
        .filter(R.identity)
        .takeUntilBy(unmount$)

    const value$ = field$.map(R.prop('value')).skipDuplicates()
    const valid$ = field$.map(R.prop('valid')).skipDuplicates()
    const errors$ = field$.map(R.prop('errors')).skipDuplicates()
    const dirty$ = field$.map(R.prop('dirty')).skipDuplicates()
    const validated$ = field$.map(R.prop('validated')).skipDuplicates()

    const defaultValue =
      typeof this.props.defaultValue === 'function'
        ? this.props.defaultValue(this.props.props)
        : this.props.defaultValue

    return {
      form,
      name,
      schema,
      unmount,
      unmount$,
      setFocusableInput,
      focusableInputRef$,
      value$,
      valid$,
      errors$,
      dirty$,
      validated$,
      defaultValue
    }
  },
  componentWillMount() {
    const { value, defaultTo } = this.props
    const { form, name, schema, unmount$, defaultValue} = this.state

    form.formInputWillMount(name, schema, value !== undefined ? value : defaultValue, defaultTo)
    form.getFieldFocusStream(name)
      .takeUntilBy(unmount$)
      .onValue(this.focusInput)
  },
  componentWillUnmount() {
    const { form, name, unmount } = this.state

    form.formFieldWillUnmount(name)
    unmount()
  },
  componentDidUpdate(prevProps) {
    const { value, validateOnSubmitOnly, defaultTo } = this.props
    const { form, name, schema, defaultValue } = this.state
    if (value !== undefined && value !== prevProps.value) {
      form.inputChange(name, schema, value, !validateOnSubmitOnly, defaultValue, defaultTo)
    }
  },
  async focusInput() {
    const inputRef = await this.state.focusableInputRef$.take(1).toPromise()
    if (inputRef) {
      inputRef.focus()
    }
  },
  render() {
    const { label, link, externalLink, className, children, validateOnSubmitOnly, defaultTo } = this.props
    const { form, name, schema, value$, valid$, errors$, dirty$, validated$, setFocusableInput, defaultValue } = this.state

    const disabled$ = form.getDisabledProperty()
    const required = form.isFieldRequired$(schema)

    const onChange = value => form.inputChange(name, schema, value, !validateOnSubmitOnly, defaultValue, defaultTo)
    const onBlur = () => form.inputBlur(name, !validateOnSubmitOnly, defaultValue, defaultTo)

    return (
      <div {...{onBlur}} {...U.classes(styles.input, className)}>
        {U.ift(label, (
          <label {...U.classes(styles.label, U.ift(required, styles.required))}>
            {U.ifte(U.or(link, externalLink),
              replaceLink(loc$(label), U.defaultTo(link, externalLink), externalLink),
              loc$(label)
            )}
          </label>
        ))}
        <ErrorTooltip enabled={U.and(validated$, U.not(dirty$))} errors={errors$}>
          {React.cloneElement(children, {
            value: value$,
            valid: U.or(valid$, U.not(validated$)),
            validated: validated$,
            errors: errors$,
            dirty: dirty$,
            disabled: disabled$,
            setFocusableInput,
            onChange
          })}
        </ErrorTooltip>
      </div>
    )
  }
}))
