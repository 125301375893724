export default {
  fields: {
    clientName: {
      required: true,
      validators: ['isString'],
    },
    clientBusinessId: {
      required: ({ deliveryMethod }) => deliveryMethod === 'einvoice',
      validators: ['isString'],
    },
    clientContact: {
      required: false,
      validators: ['isString'],
    },
    clientAddress: {
      required: true,
      validators: ['isString'],
    },
    clientPostalCode: {
      required: true,
      validators: ['isNumber'],
    },
    clientPostOffice: {
      required: true,
      validators: ['isString'],
    },
    invoiceLanguage: {
      required: true,
      validators: ['validLanguage'],
    },
    deliveryMethod: {
      required: true,
      validators: ['validDeliveryMethod'],
    },
    deliveryEmail: {
      required: ({ deliveryMethod }) => deliveryMethod === 'email',
      validators: ['email'],
    },
    deliveryEmailLanguage: {
      required: ({ deliveryMethod }) => deliveryMethod === 'email',
      validators: ['validLanguage'],
    },
    useEinvoiceAddress: {
      required: false,
      validators: ['isBoolean'],
    },
    einvoiceAddress: {
      required: ({ deliveryMethod, useEinvoiceAddress }) =>
        deliveryMethod === 'einvoice' && useEinvoiceAddress,
    },
    einvoiceOperatorId: {
      required: ({ deliveryMethod, useEinvoiceAddress }) =>
        deliveryMethod === 'einvoice' && useEinvoiceAddress,
    },
    reminderDeliveryEmail: {
      required: ({ deliveryMethod }) => deliveryMethod === 'einvoice',
      validators: ['email'],
    },
    reminderDeliveryEmailLanguage: {
      required: ({ deliveryMethod }) => deliveryMethod === 'einvoice',
      validators: ['validLanguage'],
    },
    dueDate: {
      required: true,
      validators: ['validDueDate'],
      transformers: {
        save: ['toInteger'],
        validate: ['toInteger'],
      },
    },
    description: {
      required: true,
      validators: ['isString'],
    },
    additionalInfo: {
      required: false,
      validators: ['isString'],
    },
    hour: {
      required: true,
      isArray: true,
      fields: {
        date: {
          required: true,
          validators: ['validDate'],
          transformers: {
            load: ['dateFromDb'],
            save: ['dateToDb'],
            validate: ['dateToDb'],
          },
        },
        hours: {
          required: true,
          validators: [['isNumber', { allowDecimals: true }]],
          transformers: {
            load: ['decimalToLocalized'],
            save: ['decimalFromLocalized'],
            validate: ['decimalFromLocalized'],
          },
        },
        sort: {
          required: true,
          validators: [['isNumber', { allowDecimals: false }]],
        },
      },
    },
    product: {
      required: true,
      isArray: true,
      fields: {
        title: {
          required: true,
          validators: ['isString'],
        },
        price: {
          required: true,
          validators: ['price'],
          transformers: {
            load: ['decimalToLocalized'],
            save: ['decimalFromLocalized'],
            validate: ['decimalFromLocalized'],
          },
        },
        amount: {
          required: true,
          validators: [['isNumber', { allowDecimals: true }]],
          transformers: {
            load: ['decimalToLocalized'],
            save: ['decimalFromLocalized'],
            validate: ['decimalFromLocalized'],
          },
        },
        vat: {
          required: true,
          validators: ['validProductVat'],
          transformers: {
            load: ['decimalToLocalized'],
            save: ['decimalFromLocalized'],
            validate: ['decimalFromLocalized'],
          },
        },
        sort: {
          required: true,
          validators: [['isNumber', { allowDecimals: false }]],
        },
      },
    },
    expense: {
      required: false,
      isArray: true,
      fields: {
        title: {
          required: true,
          validators: ['isString'],
        },
        expense: {
          required: true,
          validators: ['price'],
          transformers: {
            load: ['decimalToLocalized'],
            save: ['decimalFromLocalized'],
            validate: ['decimalFromLocalized'],
          },
        },
        receipt: {
          required: true,
          validators: ['validFileUpload'],
          transformers: {
            load: ['fileUploadFromDb'],
            save: ['fileUploadToDb'],
          },
        },
        sort: {
          required: true,
          validators: [['isNumber', { allowDecimals: false }]],
        },
      },
    },
  },
}
