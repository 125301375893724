export default [
  {
    isIndex: true,
    path: '/profile',
    localizationKey: 'profile.info'
  },
  {
    path: '/profile/edit',
    localizationKey: 'profile.edit'
  },
  {
    path: '/profile/change-password',
    localizationKey: 'profile.changePassword'
  }
]
