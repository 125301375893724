import React from 'karet'
import * as U from 'karet.util'
import { loc$ } from 'utils/i18n'
import styles from './Select.css'

export default ({
  value,
  options,
  className,
  disabled,
  onChange,
  setFocusableInput
}) => (
  <div {...U.classes(styles.select, className)}>
    <select
      ref={setFocusableInput}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...{disabled}}
    >
      {U.seq(options, U.mapIndexed((opt, idx) => {
        const [ value, label ] = Array.isArray(opt) ? [opt[0], loc$(opt[1])] : [opt, opt]
        return <option key={idx} value={value}>{label}</option>
      }))}
    </select>
  </div>
)
