import React from 'karet'
import * as U from 'karet.util'
import { loc$, currency$, percentage$ } from 'utils/i18n'
import styles from './Payslip.css'

export default ({ payslip }) => {
  const employerExpenses = U.view('employerExpenses', payslip)
  const employeeExpenses = U.view('employeeExpenses', payslip)
  const expensePercentage = (expenses, key) => U.toProperty(expenses).map(U.view([key, 'percentage'])).flatMapLatest(percentage$).map(percentage => ({ percentage }))

  return (
    <div className={styles.payslip}>
      <h2>{loc$('balanceWithdraw.payslip')}</h2>
      <table>
        <tbody>
          <tr>
            <th>{loc$('balanceWithdraw.withdrawAmount')}</th>
            <td>{U.seq(payslip, U.view('withdrawAmount'), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.travelExpenses')}</th>
            <td>{U.seq(payslip, U.view('taxFreeAmount'), currency$)}</td>
          </tr>
          <tr className={styles.strong}>
            <th>{loc$('balanceWithdraw.taxableIncome')}</th>
            <td><strong>{U.seq(payslip, U.view('taxableAmount'), currency$)}</strong></td>
          </tr>

          <tr><td colSpan="2">&nbsp;</td></tr>
          <tr className={styles.strong}>
            <th>{loc$('balanceWithdraw.employersExpensesAreDeducted')}:</th>
            <td></td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.healthInsurancePayment', expensePercentage(employerExpenses, 'sotu'))}</th>
            <td>{U.seq(employerExpenses, U.view(['sotu', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.employersPensionContribution', expensePercentage(employerExpenses, 'tyel'))}</th>
            <td>{U.seq(employerExpenses, U.view(['tyel', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.employersUnemploymentInsurancePayment', expensePercentage(employerExpenses, 'tvm'))}</th>
            <td>{U.seq(employerExpenses, U.view(['tvm', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.employersAccidentInsurance', expensePercentage(employerExpenses, 'tapaturmavakuutus'))}</th>
            <td>{U.seq(employerExpenses, U.view(['tapaturmavakuutus', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.groupLifeInsurance', expensePercentage(employerExpenses, 'ryhmahenkivakuutus'))}</th>
            <td>{U.seq(employerExpenses, U.view(['ryhmahenkivakuutus', 'amount']), currency$)}</td>
          </tr>
          <tr className={styles.strong}>
            <th><strong>{loc$('balanceWithdraw.grossWage')}</strong></th>
            <td><strong>{U.seq(payslip, U.view('grossWage'), currency$)}</strong></td>
          </tr>
          <tr>
            <th>&nbsp;&nbsp;{loc$('balanceWithdraw.grossWageContractWorkShare')}</th>
            <td>{U.seq(payslip, U.view('grossWageContractWorkShare'), currency$)}</td>
          </tr>
          <tr>
            <th>&nbsp;&nbsp;{loc$('balanceWithdraw.grossWageVacationCompensationShare')}</th>
            <td>{U.seq(payslip, U.view('grossWageVacationCompensationShare'), currency$)}</td>
          </tr>
          <tr><td colSpan="2">&nbsp;</td></tr>
          <tr className={styles.strong}>
            <th>{loc$('balanceWithdraw.deductedFromGrossWage')}:</th>
            <td></td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.advancePayment', expensePercentage(employeeExpenses, 'ennakonpidatys'))}</th>
            <td>{U.seq(employeeExpenses, U.view(['ennakonpidatys', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.employeesPensionPayment', expensePercentage(employeeExpenses, 'tyel'))}</th>
            <td>{U.seq(employeeExpenses, U.view(['tyel', 'amount']), currency$)}</td>
          </tr>
          <tr>
            <th>- {loc$('balanceWithdraw.employeesUnemploymentInsurancePayment', expensePercentage(employeeExpenses, 'tvm'))}</th>
            <td>{U.seq(employeeExpenses, U.view(['tvm', 'amount']), currency$)}</td>
          </tr>
          <tr className={styles.strong}>
            <th><strong>{loc$('balanceWithdraw.netWage')}</strong></th>
            <td><strong>{U.seq(payslip, U.view('netWage'), currency$)}</strong></td>
          </tr>
          <tr><td colSpan="2">&nbsp;</td></tr>
          <tr className={styles.strong}>
            <th>{loc$('balanceWithdraw.isAddedToNetWage')}</th>
            <td></td>
          </tr>
          <tr>
            <th>+ {loc$('balanceWithdraw.travelExpenses')}</th>
            <td>{U.seq(payslip, U.view('taxFreeAmount'), currency$)}</td>
          </tr>
          <tr className={styles.strong}>
            <th><strong>{loc$('balanceWithdraw.toBePaid')}</strong></th>
            <td><strong>{U.seq(payslip, U.view('toBePaid'), currency$)}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
