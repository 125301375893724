import R from 'ramda'
import React from 'karet'
import K, * as U from 'karet.util'
import { loc, loc$ } from 'utils/i18n'
import * as api from 'api'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import { createAction, createActionProperty } from 'utils/store'
import { replaceLink } from 'utils/misc'
import { showApiRequestError } from 'utils/errors'
import { waitForPromises } from 'utils/containers'
import { me$, isAdmin$ } from 'stores/user'
import { InlineNotification, Button } from 'components'
import * as schema from 'shared/schema'
import InvoiceForm from './InvoiceForm.jsx'
import styles from './Invoices.css'

const addInvoice = apiFn =>
  showApiRequestError(async () => {
    const confirmMsg = await loc$('invoice.confirmNewInvoice')
      .take(1)
      .toPromise()
    if (confirm(confirmMsg)) {
      const { invoiceId } = await apiFn()
      addToast('success', 'invoice.creationSuccess')
      navigateTo(`/invoice/view/${invoiceId}`)
    }
  })

const isNotEmptyFn = R.pipe(
  R.isNil,
  R.not
)
const requiredFields = R.keys(
  R.filter(R.propEq('required', true), schema.profile.fields)
)
const requiredFieldsFilled$ = me$
  .map(R.pick(requiredFields))
  .map(R.values)
  .map(R.all(isNotEmptyFn))

const addDraft = data =>
  showApiRequestError(async () => {
    const draftName = prompt(loc('invoice.saveAsDraftConfirm'))
    if (draftName) {
      const draft = await api.addDraft(draftName, data)
      addToast('success', 'invoice.draftSaved')
      navigateTo(`/invoice/draft/${draft.id}`)
      return draft
    }
  })

const editDraft = (id, data) =>
  showApiRequestError(async () => {
    const draft = await api.editDraft(id, data)
    addToast('success', 'invoice.draftSaved')
    return draft
  })

const deleteDraft = id =>
  showApiRequestError(async () => {
    if (confirm(loc('invoice.confirmDeleteDraft'))) {
      await api.deleteDraft(id)
      addToast('success', 'invoice.deleteDraftSuccess')
      navigateTo('/invoice')
    }
  })

export default waitForPromises(
  ({ params: { invoiceId, draftId, userId } }) => {
    if (invoiceId) {
      return {
        invoice: api.getInvoice(invoiceId),
        isAdmin: isAdmin$.take(1).toPromise(),
      }
    } else if (draftId) {
      return { draft: api.getDraft(draftId) }
    } else if (userId) {
      return { user: api.getUser(userId) }
    } else {
      return {}
    }
  },
  waitForPromises(
    ({ invoice, isAdmin }) =>
      invoice && isAdmin ? { user: api.getUser(invoice.userId) } : {},
    ({ invoice, draft, user }) => {
      const creatingInvoiceForAnotherUser = Boolean(user)
      const initialValues = invoice || (draft && draft.data) || { dueDate: 14 }

      const [saveInvoice, onSaveInvoice] = createAction()
      const [updateCurrentInvoice, currentInvoice$] = createActionProperty(
        R.always(initialValues)
      )
      const [saveDraft, draft$] = createActionProperty(
        R.always(draft),
        (currentDraft, currentInvoice) => {
          if (currentDraft) {
            return editDraft(currentDraft.id, currentInvoice)
          } else {
            return addDraft(currentInvoice)
          }
        }
      )

      const draftId$ = U.view('id', draft$)

      const onSubmit = async values => {
        const draftId = await draftId$.take(1).toPromise()
        if (draftId) {
          addInvoice(() => api.addInvoiceFromDraft(draftId, values))
        } else if (creatingInvoiceForAnotherUser) {
          addInvoice(() => api.addInvoiceForUser(user.id, values))
        } else {
          addInvoice(() => api.addInvoice(values))
        }
      }

      return (
        <div>
          <div className={styles.splitHeader}>
            <h1>
              {U.ifte(
                draft$,
                loc$('invoice.newInvoiceDraft', {
                  name: U.view('name', draft$),
                }),
                U.ifte(
                  creatingInvoiceForAnotherUser,
                  loc$('invoice.newInvoiceForUser', {
                    name: `${U.view('firstName', user)} ${U.view(
                      'lastName',
                      user
                    )}`,
                  }),
                  loc$('invoice.newInvoice')
                )
              )}
            </h1>
            <div className={styles.controls}>
              <Button
                onClick={saveInvoice}
                className={styles.button}
                label="invoice.addInvoice"
                theme="blue"
                noMargin
              />
              {U.ift(
                U.not(creatingInvoiceForAnotherUser),
                <Button
                  onClick={K(
                    draft$,
                    currentInvoice$,
                    (draft, currentInvoice) => () =>
                      saveDraft(draft, currentInvoice)
                  )}
                  className={styles.button}
                  label={U.ifte(
                    draft$,
                    'invoice.saveDraft',
                    'invoice.saveAsDraft'
                  )}
                  theme="blue"
                  noMargin
                />
              )}
              {U.ift(
                draft$,
                <a
                  href={`/api/v1/invoice-draft/${U.view('id', draft)}/pdf`}
                  target="_blank"
                >
                  <Button label="invoice.preview" className={styles.button} />
                </a>
              )}
              {U.ift(
                draft$,
                <Button
                  onClick={K(draftId$, draftId => () => deleteDraft(draftId))}
                  className={styles.button}
                  label="invoice.deleteDraft"
                  theme="red"
                />
              )}
            </div>
          </div>
          {U.ift(
            creatingInvoiceForAnotherUser,
            <InlineNotification theme="yellow">
              <p>
                {replaceLink(
                  loc$('invoice.creatingInvoiceForUserNotification', {
                    name: `${U.view('firstName', user)} ${U.view(
                      'lastName',
                      user
                    )}`,
                  }),
                  `/user/overview/${U.view('id', user)}`,
                  false
                )}
              </p>
            </InlineNotification>
          )}
          {U.ifte(
            requiredFieldsFilled$,
            <div>
              <div className={styles.formInstructions}>
                {loc$('invoice.formInstructionsHelp')}{' '}
                <a href="/invoice/instructions" target="_blank">
                  {loc$('invoice.formInstructionsLink')}
                </a>
              </div>
              <InvoiceForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitLabel="invoice.addInvoice"
                onChange={updateCurrentInvoice}
                submitStream={onSaveInvoice}
                saveDraftEnabled={true}
                saveDraftLabel={U.ifte(
                  draft$,
                  'invoice.saveDraft',
                  'invoice.saveAsDraft'
                )}
                onSaveDraft={K(
                  draft$,
                  currentInvoice$,
                  (draft, currentInvoice) => () =>
                    saveDraft(draft, currentInvoice)
                )}
                uploadFilesAsUser={U.view('id', user)}
              />
            </div>,
            <div>
              <InlineNotification
                theme="yellow"
                links={[
                  {
                    label: 'invoice.fillMissingInformation',
                    path: '/profile/edit',
                  },
                ]}
              >
                <p>{loc$('invoice.missingInformation')}</p>
              </InlineNotification>
            </div>
          )}
        </div>
      )
    }
  ),
  (props, nextProps) =>
    props.params.invoiceId !== nextProps.params.invoiceId ||
    props.params.draftId !== nextProps.params.draftId ||
    props.params.userId !== nextProps.params.userId
)
