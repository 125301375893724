import React from 'karet'
import NotFound from 'views/NotFound/NotFound.jsx'

export default function({ error }) {
  if (error && (error.code === 'NOT_FOUND' || error.code === 'FORBIDDEN')) {
    return <NotFound />
  } else {
    return (
      <div>
        Something went wrong. Please try again.
      </div>
    )
  }
}
