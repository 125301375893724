export default function(valid, reason, params) {
  if (valid) {
    return {
      valid: true
    }
  } else {
    return {
      valid: false,
      error: { reason, params }
    }
  }
}
