import React from 'karet'
import * as U from 'karet.util'
import Navigation from './Navigation.jsx'
import Header from './Header.jsx'
import Footer from './Footer.jsx'
import styles from './App.css'
import { ToastContainer } from 'components'

export default ({ children }) => (
  <div className={styles.app}>
    <ToastContainer />
    {children}
  </div>
)
