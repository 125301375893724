import React from 'karet'
import styles from './BalanceHistory.css'
import { loc$, currency$, datetime$ } from 'utils/i18n'
import { Link } from 'components'
import * as U from 'karet.util'
import Decimal from 'decimal.js'

export default ({ history, totalBilling }) => (
  <div className={styles.balanceHistory}>
    <table>
      <thead>
        <tr>
          <th>{loc$('profile.transactionTime')}</th>
          <th>{loc$('profile.transactionAmount')}</th>
          <th>{loc$('profile.transactionType')}</th>
          <th>{loc$('profile.grossSalary')}</th>
          <th>{loc$('balanceHistory.expenses')}</th>
          <th>{loc$('balanceHistory.commissions')}</th>
        </tr>
      </thead>
      <tbody>
        {U.seq(
          history,
          U.mapIndexed((row, idx) => (
            <tr key={idx} className={styles[row.type]}>
              <td>{datetime$(row.time)}</td>
              <td>
                {U.ifte(
                  U.contains(row.type, ['allocation', 'deposit']),
                  '+',
                  '-'
                )}
                {currency$(row.amount)}
              </td>
              <td>
                {U.ifte(
                  U.contains(row.type, ['allocation', 'deposit', 'credit']),
                  <Link to={`/invoice/view/${row.invoiceId}`}>
                    <span>
                      {loc$(`balanceHistory.typeFormat.${row.type}`, {
                        invoiceId: row.invoiceId,
                      })}
                    </span>
                  </Link>,
                  U.ifte(
                    U.equals(row.type, 'withdraw'),
                    <span>{loc$('profile.salaryWithdraw')}</span>,
                    U.ifte(
                      U.equals(row.type, 'reimbursement'),
                      <span>{loc$('profile.reimbursement')}</span>,
                      U.ift(
                        U.equals(row.type, 'balance_fix'),
                        <span>
                          {loc$('balanceHistory.typeFormat.balanceFix')}
                        </span>
                      )
                    )
                  )
                )}
              </td>
              <td>
                {U.ifte(
                  U.equals(row.type, 'balance_fix'),
                  '-',
                  currency$(new Decimal(row.amount).sub(row.reimbursements)),
                  '-'
                )}
              </td>
              <td>
                {U.ifte(
                  U.equals(row.type, 'balance_fix'),
                  '-',
                  currency$(row.reimbursements)
                )}
              </td>
              <td>
                {U.ifte(row.commissions, currency$(row.commissions), '-')}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
    {U.ift(
      totalBilling,
      <div className={styles.totalBilling}>
        <span className={styles.totalBillingTitle}>
          {loc$('user.info.totalBilling')}:
        </span>
        <span className={styles.totalBillingValue}>
          {currency$(totalBilling)}
        </span>
      </div>
    )}
  </div>
)
