export default [
  {
    isIndex: true,
    path: '/',
    localizationKey: 'navigation.frontpage'
  },
  {
    path: '/news',
    localizationKey: 'navigation.news'
  },
  {
    path: '/instructions',
    localizationKey: 'navigation.instructions'
  },
  {
    isAdmin: true,
    path: '/files',
    localizationKey: 'navigation.manageFiles'
  }
]
