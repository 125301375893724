import React from 'karet'
import * as U from 'karet.util'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'

export default ({ step }) => (
  <div className={styles.steps}>
    <div {...U.classes(styles.step, U.ift(U.equals(step, 'personalData'), styles.active))}>
      <span className={styles.stepNumber}>1.</span> <span className={styles.stepName}>{loc$('join.steps.personalData')}</span>
    </div>
    <div {...U.classes(styles.step, U.ift(U.equals(step, 'paymentMethod'), styles.active))}>
      <span className={styles.stepNumber}>2.</span> <span className={styles.stepName}>{loc$('join.steps.paymentMethod')}</span>
    </div>
    <div {...U.classes(styles.step, U.ift(U.equals(step, 'pay'), styles.active))}>
      <span className={styles.stepNumber}>3.</span> <span className={styles.stepName}>{loc$('join.steps.pay')}</span>
    </div>
    <div {...U.classes(styles.step, U.ift(U.equals(step, 'done'), styles.active))}>
      <span className={styles.stepNumber}>4.</span> <span className={styles.stepName}>{loc$('join.steps.done')}</span>
    </div>
  </div>
)
