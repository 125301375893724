import React from 'karet'
import { classes, ift, not } from 'karet.util'
import styles from './TextArea.css'

export default ({
  className,
  value,
  valid,
  disabled,
  onChange,
  setFocusableInput
}) => (
  <div {...classes(
    styles.textarea,
    ift(not(valid), styles.invalid),
    ift(disabled, styles.disabled),
    className
  )}>
    <textarea
      ref={setFocusableInput}
      type="textarea"
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
      {...{value, disabled}}
    />
  </div>
)
