import React from 'karet'
import Kefir from 'kefir'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { balanceInfo$, withdrawBalance$, totalBilling$ } from 'stores/user'
import { BalanceSummary, BalanceHistory, Button } from 'components'
import { loc$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import styles from './BalanceOverview.css'

export default waitForPromises(
  () => ({
    history: api.getBalanceHistory()
  }),
  ({ history }) => {
    const history$ =
      Kefir.constant(history)
        .merge(withdrawBalance$.flatMapLatest(() => Kefir.fromPromise(api.getBalanceHistory())))
        .toProperty()

    return (
      <div>
        <h1>{loc$('balance.summaryTitle')}</h1>
        <BalanceSummary balanceInfo={balanceInfo$} />

        <div className={styles.buttonWrapper}>
          <Button label="profile.withdrawAsSalary" large onClick={() => navigateTo('/balance/withdraw')} />
        </div>
        <div className={styles.buttonWrapper}>
          <Button label="profile.makeReimbursement" large onClick={() => navigateTo('/balance/reimbursements/new')} />
        </div>

        <h1>{loc$('balance.historyTitle')}</h1>
        <BalanceHistory history={history$} totalBilling={totalBilling$} />
      </div>
    )
  }
)
