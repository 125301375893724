import React from 'karet'
import * as L from 'partial.lenses'
import * as C from 'components'

export const TextInput = ({label, defaultValue = '', defaultTo = null, ...props}) =>
  <C.FormInput {...{label, defaultValue, defaultTo}}><C.TextInput {...props} /></C.FormInput>

export const DateInput = ({label, defaultValue = '', defaultTo = null, ...props}) =>
  <C.FormInput {...{label, defaultValue, defaultTo}}><C.DateInput {...props} /></C.FormInput>

export const DateRange = ({label, defaultValue = ['', ''], defaultTo = null, ...props}) =>
  <C.FormInput {...{label, defaultValue, defaultTo}}><C.DateRange {...props} /></C.FormInput>

export const Checkbox = ({label, defaultValue = false, link, externalLink, ...props}) =>
  <C.FormInput {...{label, defaultValue, link, externalLink}}><C.Checkbox {...props} /></C.FormInput>

export const Select = ({label, defaultValue = L.get(['options', 0, L.choose(it => Array.isArray(it) ? 0 : L.identity)]), ...props}) =>
  <C.FormInput {...{label, defaultValue, props}}><C.Select {...props} /></C.FormInput>

export const TextArea = ({label, defaultValue = '', defaultTo = null, ...props}) =>
  <C.FormInput {...{label, defaultValue, defaultTo}}><C.TextArea {...props} /></C.FormInput>

export const FileUploadInput = ({label, defaultValue = null, ...props}) =>
  <C.FormInput {...{label, defaultValue, validateOnSubmitOnly: true}}><C.FileUploadInput {...props} /></C.FormInput>

export const HiddenInput = ({value}) =>
  <C.FormInput {...{value}}><C.HiddenInput type="hidden" /></C.FormInput>
