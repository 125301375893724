import React from 'karet'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, Checkbox, Select, FileUploadInput } from 'utils/forms/controls'
import { loc$ } from 'utils/i18n'
import * as schema from 'shared/schema'
import styles from './Files.css'
import { LANGUAGES } from 'shared/config'

export default ({ initialValues, submitLabel, onSubmit }) => {
  return (
    <Form
      schema={schema.file}
      initialValues={initialValues}
      className={styles.form}
      onSubmit={onSubmit}
    >
      <FormField name="type">
        <Select label="files.info.type" options={[
          ['news', 'files.types.news'],
          ['instructions', 'files.types.instructions']
        ]} />
      </FormField>
      <FormField name="upload">
        <FileUploadInput label="files.info.file" folder="files" isPublic={true} />
      </FormField>
      <FormField name="showInFrontpage">
        <Checkbox label="files.info.showInFrontpage" />
      </FormField>
      <h3>{loc$('files.info.titleTranslations')}</h3>
      <FormField name="translations">
        {LANGUAGES.map(language => (
          <FormField key={language} name={language}>
            <FormField name="title">
              <TextInput label={`languages.${language}`} />
            </FormField>
          </FormField>
        ))}
      </FormField>
      <FormSubmit>
        <Button label={submitLabel} large />
      </FormSubmit>
    </Form>
  )
}
