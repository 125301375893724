import React from 'karet'
import { loc$ } from 'utils/i18n'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput } from 'utils/forms/controls'
import * as api from 'api'
import * as schema from 'shared/schema'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import { showApiError } from 'utils/errors'
import styles from './Profile.css'

const onSubmit = async (values, addError) => {
  try {
    await api.changePassword(values)
    addToast('success', 'profile.passwordChanged')
    navigateTo('/profile')
  } catch (err) {
    if (err.code === 'INVALID_CURRENT_PASSWORD') {
      addError('oldPassword', 'INVALID_CURRENT_PASSWORD')
    } else {
      showApiError(err)
    }
  }
}

export default () => (
  <div>
    <h1>{loc$('profile.changePassword')}</h1>
    <Form
      schema={schema.changePassword}
      className={styles.form}
      onSubmit={onSubmit}
    >
      <FormField name="oldPassword">
        <TextInput type="password" label="profile.oldPassword" />
      </FormField>
      <FormField name="newPassword">
        <TextInput type="password" label="profile.newPassword" />
      </FormField>
      <FormField name="newPasswordAgain">
        <TextInput type="password" label="profile.newPasswordAgain" />
      </FormField>
      <FormSubmit>
        <Button label="profile.changePassword" large />
      </FormSubmit>
    </Form>
  </div>
)
