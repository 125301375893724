import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import K, * as U from 'karet.util'
import * as L from 'partial.lenses'
import styles from './FileUploadInput.css'
import { withUnmount$ } from 'utils/containers'
import { createAction, createActionProperty, persistentProperty } from 'utils/store'
import { FileUploadButton, Button, Loader } from 'components'
import { doAction } from 'shared/utils'
import * as api from 'api'

export default withUnmount$(React.createClass({
  getInitialState() {
    const { folder, onChange, unmount$, isPublic = false, uploadAsUser = null } = this.props
    const [ updateProgress, progress$ ] = createActionProperty(R.always(0))
    const [ selectFile, selectFile$ ] = createAction()

    const onUploadStart = file => onChange({ status: 'uploading', file })
    const onUploadFinish = file => onChange({ status: 'uploaded', file })
    const onUploadError = error => onChange({ status: 'error', error })

    const startUpload$ =
      selectFile$
        .filter(R.identity)
        .map(doAction(onUploadStart))

    const uploadFinish$ =
      startUpload$
        .flatMapLatest(file => Kefir.fromPromise(
          api.uploadFile(file, folder, isPublic, uploadAsUser, progress => updateProgress(Math.round(progress * 100)))
            .then(doAction(onUploadFinish))
            .catch(doAction(onUploadError))))

    const uploading$ = persistentProperty(
      Kefir.constant(false)
        .merge(startUpload$.map(R.always(true)))
        .merge(uploadFinish$.map(R.always(false)))
        .takeUntilBy(unmount$))

    return {
      progress$: persistentProperty(progress$.takeUntilBy(unmount$)),
      selectFile,
      uploading$
    }
  },
  render() {
    const { progress$, selectFile } = this.state
    const {
      value,
      onChange,
      valid,
      disabled,
      className,
      setFocusableInput,
      extensions = []
    } = this.props

    const file = K(value, L.get('file'))
    const status = K(value, L.get('status'))
    const uploading = U.equals(status, 'uploading')
    const name = K(file, L.get('name'))
    const url = K(file, L.get('url'))

    const renderUploadButton = label => (
      <FileUploadButton
        extensions={extensions}
        className={styles.selectFile}
        onSelectFile={selectFile}
      >
        <Button label={label} noMargin fullWidth />
      </FileUploadButton>
    )

    return (
      <div
        {...U.classes(styles.fileUploadInput, className, U.ift(disabled, styles.disabled))}
        tabIndex={-1}
        ref={setFocusableInput}
      >
        {U.ifte(uploading,
          (
            <div className={styles.progress}>
              <Loader className={styles.loader} size={20} />
              {progress$}%
            </div>
          ),
          U.ifte(file,
            (
              <div className={styles.selectedFile}>
                {U.ifte(U.not(disabled),
                  (
                    <div className={styles.hover}>
                      <a href={url} target="_blank">
                        <Button className={styles.preview} label="fileUpload.preview" noMargin fullWidth />
                      </a>
                      {renderUploadButton('fileUpload.changeFile')}
                      <Button theme="red" label="fileUpload.remove" noMargin fullWidth onClick={() => onChange(null)} />
                    </div>
                  ),
                  (
                    <div className={styles.hover}>
                      <a href={url} target="_blank">
                        <Button className={styles.preview} label="fileUpload.preview" noMargin fullWidth />
                      </a>
                    </div>
                  )
                )}
                <div className={styles.fileName}>
                  <span className="icon-file-image" />
                  {name}
                </div>
              </div>
            ),
            renderUploadButton('fileUpload.selectFile')
          )
        )}
        {U.ift(U.not(valid),
          <div {...U.classes('icon-cancel-circled', styles.invalid)} />
        )}
      </div>
    )
  }
}))
