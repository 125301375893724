import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import * as U from 'karet.util'
import * as L from 'partial.lenses'
import { loc, loc$, currency$, date$, dateRange$, percentage$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import { waitForPromises, withUnmount$ } from 'utils/containers'
import { createAction, createActionProperty, persistentProperty } from 'utils/store'
import { showApiRequestError } from 'utils/errors'
import { getPayslip, calculateAgeForTaxingFromSsn } from 'shared/utils'
import Decimal from 'decimal.js'
import OverviewInvoices from './OverviewInvoices.jsx'
import OverviewContracts from './OverviewContracts.jsx'
import * as api from 'api'
import {
    Link,
    Button,
    ProfileInfo,
    BalanceSummary,
    BalanceHistory,
    InlineNotification,
    Payslip
} from 'components'
import styles from './Overview.css'

export default waitForPromises(
  ({ params: { userId }}) => ({
    payRequests: api.getUserPayRequests(userId),
    invoices: api.getUserInvoices(userId),
    contracts: api.getUserContracts(userId),
    history: api.getUserBalanceHistory(userId),
    user: api.getUser(userId)
  }),
  withUnmount$(({ payRequests, invoices, contracts, history, user, unmount$ }) => {
    const [ markPayRequestAsPaid, markPayRequestAsPaid$ ] = createAction(id => api.markPayRequestAsPaid(id))
    const [ rejectPayRequest, rejectPayRequest$ ] = createAction((id, reason) => api.rejectPayRequest(id, reason))

    const payRequests$ = persistentProperty(Kefir.concat([
      Kefir.constant(payRequests),
      Kefir.merge([
        markPayRequestAsPaid$,
        rejectPayRequest$
      ])
        .ignoreErrors()
        .flatMapLatest(() => Kefir.fromPromise(api.getUserPayRequests(user.id)))
    ]).takeUntilBy(unmount$))

    const payRequestSum$ = payRequests$.map(payRequests => payRequests.reduce((sum, { amount }) => sum.add(amount), new Decimal(0)).toFixed(2))

    const history$ = persistentProperty(Kefir.concat([
      Kefir.constant(history),
      Kefir.merge([
        markPayRequestAsPaid$,
        rejectPayRequest$
      ])
        .ignoreErrors()
        .flatMapLatest(() => Kefir.fromPromise(api.getUserBalanceHistory(user.id)))
    ]).takeUntilBy(unmount$))

    return (
      <div>
        <h1>{loc$('profile.userOverview')}: {user.firstName} {user.lastName}</h1>

        {U.ift(U.and(user.taxCard, U.not(user.taxPercentage)), (
          <InlineNotification theme="yellow" links={[{ label: 'user.addTaxPercentage', path: `/user/edit/${user.id}` }]}>
            <p>{loc$('user.taxCardNotVerified')}</p>
          </InlineNotification>
        ))}

        {U.ift(U.not(U.isEmpty(payRequests$)),
          U.ifte(user.taxPercentage,
            U.seq(payRequests$, U.mapIndexed((payRequest, idx) => {
              const payslipVisible = U.atom(false)
              const ageForTaxing = calculateAgeForTaxingFromSsn(user.ssn)
              const payslip = getPayslip(payRequest.amount, payRequest.taxFreeShare, user.taxPercentage, ageForTaxing)

              return (
                <div key={idx} className={styles.payRequestWrapper}>
                  <InlineNotification showAlert={false} theme="yellow" linkDirection="vertical" links={[
                    {
                      label: 'user.showPayslip',
                      action: () => payslipVisible.set(true)
                    },
                    {
                      label: 'user.markPayRequestAsPaid',
                      theme: 'green',
                      action: () => {
                        if (confirm(loc('user.confirmMarkPayRequestAsPaid'))) {
                          showApiRequestError(async () => {
                            await markPayRequestAsPaid(payRequest.id)
                            addToast('success', 'user.markPayRequestAsPaidSuccess')
                          })
                        }
                      }
                    },
                    {
                      label: 'user.rejectPayRequest',
                      theme: 'red',
                      action: () => {
                        const reason = prompt(loc('user.confirmRejectPayRequest'))
                        if (reason) {
                          showApiRequestError(async () => {
                            await rejectPayRequest(payRequest.id, reason)
                            addToast('success', 'user.rejectPayRequestSuccess')
                          })
                        }
                      }
                    }
                  ]}>
                    <p className={styles.paragraphSmallMargin}>Palkkapyyntö odottaa maksua.</p>
                    <p><strong>{loc$('user.payRequestDate')}</strong>: {date$(payRequest.createdAt)}</p>
                    <p><strong>{loc$('user.payRequestAmount')}</strong>: {currency$(payRequest.amount)}</p>
                    <p><strong>{loc$('user.payRequestTaxFreeShare')}</strong>: {currency$(payRequest.taxFreeShare)}</p>
                    <p><strong>{loc$('user.payRequestAgeForTaxing')}</strong>: {ageForTaxing}</p>
                    <p><strong>{loc$('user.payRequestTaxPercentage')}</strong>: {percentage$(user.taxPercentage)}</p>
                  </InlineNotification>
                  <div {...U.classes(styles.payRequestPaySlipWrapper, U.ift(payslipVisible, styles.visible))}>
                    <div className={styles.payRequestPaySlipOverlay} onClick={() => payslipVisible.set(false)}></div>
                    <div className={styles.payRequestPaySlipContent}>
                      <Payslip payslip={payslip} />
                      <Button label="user.closePayslip" onClick={() => payslipVisible.set(false)} />
                    </div>
                  </div>
                </div>
              )
            })),
            (
              <InlineNotification theme="yellow">
                <p>{loc$('user.hasPayRequestsButTaxCardNotVerified', { amount: payRequestSum$ })}</p>
              </InlineNotification>
            )
          )
        )}

        <div className={styles.overviewSection}>
          <div className={styles.profilePage}>
            <div className={styles.infoColumn}>
              <ProfileInfo profile={user} />
            </div>
            <div className={styles.pictureColumn}>
              <div className={styles.picture}>
                <div
                  className={styles.img}
                  style={{ backgroundImage: `url(${R.defaultTo('/static/img/profile-placeholder.png', L.get(['picture', 'url'], user))})` }}
                />
              </div>
              <div className={styles.introduction}>{user.introduction}</div>
            </div>
          </div>
          <Link to={`/user/edit/${user.id}`}><Button label="profile.edit" /></Link>
        </div>

        <div className={styles.overviewSection}>
          <h2>{loc$('invoice.invoicesList')}</h2>
          <OverviewInvoices invoices={invoices} />
          <Link to={`/invoice/new/for-user/${user.id}`}><Button label="profile.createInvoiceForUser" /></Link>
        </div>

        <div className={styles.overviewSection}>
          <h2>{loc$('contracts.contractList')}</h2>
          <OverviewContracts contracts={contracts} />
          <Link to={`/contract/new/for-user/${user.id}`}><Button label="profile.createContractForUser" /></Link>
        </div>

        <div className={styles.overviewSection}>
          <h2>{loc$('profile.balance')}</h2>
          <BalanceSummary balanceInfo={user.balanceInfo} />
        </div>

        <div className={styles.overviewSection}>
          <h2>{loc$('profile.balanceHistory')}</h2>
          <BalanceHistory history={history$} totalBilling={user.totalBilling} />
        </div>
      </div>
    )
  }),
  (props, nextProps) => props.params.userId !== nextProps.params.userId
)
