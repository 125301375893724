import R from 'ramda'
import React from 'karet'
import styles from './ErrorTooltip.css'
import K, * as U from 'karet.util'
import { classes, ift, isEmpty, and, not, compose, mapIndexed, seq } from 'karet.util'
import { createAction } from 'utils/store'
import { loc$ } from 'utils/i18n'

const isNotEmpty = compose(not, isEmpty)

export default ({ enabled, errors, children }) => {
  const [ focus, onFocus ] = createAction()
  const [ blur, onBlur ] = createAction()
  const hasFocus$ =
    onFocus.map(R.always(true))
      .merge(onBlur.map(R.always(false)))
      .toProperty(R.always(false))

  return (
    <div className={styles.container} onFocus={focus} onBlur={blur}>
      <div className={styles.tooltipAnchor}>
        <div {...classes(
          styles.tooltip,
          ift(and(enabled, isNotEmpty(errors)), styles.enabled),
          ift(hasFocus$, styles.visible)
        )}>
          <div className={styles.content}>
            {U.seq(errors, U.flatMapLatest(errors =>
              K(errors.map(({reason, params}) => loc$(['errors', reason], params)), localized => (
                <ul>
                  {seq(localized, mapIndexed((error, idx) => (
                    <li key={idx}>{error}</li>
                  )))}
                </ul>
              ))
            ))}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
