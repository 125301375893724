import React from 'karet'
import { classNames as cx } from 'utils'
import { loc$ } from 'utils/i18n'
import { lang$, isLoggedIn$, changeLanguage, changeLanguageLocally } from 'stores/user'
import { createAction, persistentProperty } from 'utils/store'

const [ toggleLanguageSelection, onLanguageSelectionToggled$ ] = createAction()
const isExpanded$ = persistentProperty(
  onLanguageSelectionToggled$.scan((prev) => !prev, false))

const availableLanguages = ['en', 'fi', 'sv']
const otherLanguages$ = lang$.map((lang) => availableLanguages.filter((l) => l !== lang))

export default ({ styles }) => {
  const handleFlagClick = lang => isLoggedIn$.take(1).onValue(isLoggedIn => {
    if (isLoggedIn) {
      changeLanguage(lang)
    } else {
      changeLanguageLocally(lang)
    }
    toggleLanguageSelection()
  })

  const classes$ = isExpanded$.map(isExpanded => cx([
    styles.language,
    isExpanded && styles.languageActive
  ]))

  return (
    <div className={classes$}>
      <div className={styles.flagWrapper}>
        <Flag karet-lift lang={ lang$ } styles={styles} onClick={toggleLanguageSelection} />
        { isExpanded$.combine(otherLanguages$)
            .map(([isExpanded, otherLanguages]) => isExpanded && otherLanguages
            .map((lang, idx) => <Flag lang={lang} styles={styles} onClick={handleFlagClick} key={idx} />)
        )}
      </div>
    </div>
  )
}

const Flag = ({ lang, styles, onClick }) => {
  const classes = cx([styles.flag, styles[`flag-${lang}`]])

  return (<div onClick={() => onClick(lang)} className={classes}>{loc$(`languages.${lang}`)}</div>)
}
