import React from 'karet'
import K from 'karet.util'
import { showApiRequestError } from 'utils/errors'
import { balanceAfterFees$, addReimbursement } from 'stores/user'
import { loc$ } from 'utils/i18n'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import ReimbursementForm from '../Reimbursements/ReimbursementForm.jsx'

export default () => {
  const onSubmit =
    values => showApiRequestError(async () => {
      await addReimbursement(values)
      addToast('success', 'reimbursements.addSuccess')
      navigateTo('/balance')
    })

  return (
    <div>
      <h1>{loc$('profile.makeReimbursement')}</h1>
      <ReimbursementForm
        submitLabel="reimbursements.submit"
        onSubmit={onSubmit}
        context={K(balanceAfterFees$, (balanceAfterFees) => ({ balanceAfterFees }))}
      />
    </div>
  )
}
