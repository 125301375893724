import React from 'karet'
import K, * as U from 'karet.util'
import styles from './Table.css'
import { loc$ } from 'utils/i18n'
import { createAction } from 'utils/store'

export default ({ fields, data, sortFn, sortedBy, perPage = 25, initialPage = 1, className = '', noRowsLabel = 'table.noRows' }) => {
  const [ changePage, onPageChange ] = createAction()
  const rows = U.length(data)
  const totalPages = K(rows, rows => Math.ceil(rows / perPage))
  const clampFn = U.clamp(1, U.max(1, totalPages))
  const selectedPage = onPageChange.toProperty(() => initialPage)
  const currentPage = clampFn(selectedPage)
  const fromIndex = U.multiply(U.dec(currentPage), perPage)
  const toIndex = U.add(fromIndex, perPage)
  const pageData = U.slice(fromIndex, toIndex, data)

  const HeadCells = U.seq(fields, U.values, U.mapIndexed((field, idx) => {
    const key = U.view('key', field)
    const sortable = U.ift(key, true, false)
    const sortedByKey = U.view('key', sortedBy)
    const direction = U.view('direction', sortedBy)

    return (
      <th
        {...U.classes(
          field.className,
          U.ift(sortable, styles.sortable),
          U.ift(U.equals(key, sortedByKey),
            U.ifte(U.equals(direction, 'ASC'),
              styles.sortedAsc,
              styles.sortedDesc))
        )}
        key={idx}
        onClick={() => sortable && sortFn(field)}
      >
        {field.title && field.title.length && loc$(field.title)}
      </th>
    )
  }))

  const Rows = U.seq(pageData, U.mapIndexed((row, idx) => <Row data={row} fields={fields} key={idx} />), U.toProperty)

  return (
    <div className={styles.tableWrapper}>
      <table {...U.classes(className, styles.table)}>
        <thead>
          <tr>
            {HeadCells}
          </tr>
        </thead>
        <tbody>
          {U.ifte(U.isEmpty(Rows),
            (
              <tr className={styles.noRows}>
                <td colSpan={U.length(fields)}>{loc$(noRowsLabel)}</td>
              </tr>
            ),
            Rows
          )}
        </tbody>
      </table>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={changePage} />
    </div>
  )
}

const Row = ({ data, fields }) => {
  const Cells = Object.values(fields).map((field, idx) => {
    return (
      <td className={field.className} key={idx}>{field.render ? field.render(data) : data[field.key]}</td>
    )
  })

  return (
    <tr>
      {Cells}
    </tr>
  )
}

const PAGINATION_WINDOW = 7

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const showAllPagesBeforeCurrentPage = U.lte(U.subtract(currentPage, PAGINATION_WINDOW), 3)
  const toPage = U.ifte(showAllPagesBeforeCurrentPage, U.subtract(currentPage, PAGINATION_WINDOW), 2)

  const showAllPagesAfterCurrentPage = U.gte(U.add(currentPage, PAGINATION_WINDOW), U.subtract(totalPages, 2))
  const fromPage = U.ifte(showAllPagesAfterCurrentPage, U.inc(U.add(currentPage, PAGINATION_WINDOW)), totalPages)

  const renderPageLink = page => <a key={page} className={styles.page} href="javascript:;" onClick={() => onPageChange(page)}>{page}</a>

  return (
    <div className={styles.pagination}>
      {U.seq(U.range(1, toPage), U.map(renderPageLink))}
      {U.ift(U.not(showAllPagesBeforeCurrentPage), <span className={styles.page}>...</span>)}
      {U.seq(U.range(U.max(U.subtract(currentPage, PAGINATION_WINDOW), 1), currentPage), U.map(renderPageLink))}
      <span {...U.classes(styles.page, styles.active)}>{currentPage}</span>
      {U.seq(U.range(U.inc(currentPage), U.inc(U.min(U.add(currentPage, PAGINATION_WINDOW), totalPages))), U.map(renderPageLink))}
      {U.ift(U.not(showAllPagesAfterCurrentPage), <span className={styles.page}>...</span>)}
      {U.seq(U.range(fromPage, U.inc(totalPages)), U.map(renderPageLink))}
    </div>
  )
}
