import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import * as U from 'karet.util'
import { Loader, FileUploadButton } from 'components'
import { createAction, createActionProperty, persistentProperty } from 'utils/store'
import { loc$ } from 'utils/i18n'
import { profilePicUrl$, changeProfilePic } from 'stores/user'
import { addToast } from 'stores/toasts'
import { withUnmount$ } from 'utils/containers'
import { doAction } from 'shared/utils'
import * as api from 'api'
import styles from './ProfilePicSelector.css'

export default withUnmount$(({ unmount$ }) => {
  const [ updateProgress, progress$ ] = createActionProperty(R.always(0))
  const [ selectFile, selectFile$ ] = createAction()

  const startUpload$ = selectFile$.filter(R.identity)
  const uploadFinish$ =
    startUpload$
      .flatMapLatest(file => Kefir.fromPromise(
        api.uploadFile(file, 'profile', false, null, progress => updateProgress(Math.round(progress * 100)))
          .then(doAction(api.updateProfilePicture))
          .then(doAction(changeProfilePic))
          .catch(error => addToast('error', `errors.${error.code}`, error))))

  const uploading$ = persistentProperty(
    Kefir.constant(false)
      .merge(startUpload$.map(R.always(true)))
      .merge(uploadFinish$.map(R.always(false)))
      .takeUntilBy(unmount$))

  return (
    <div className={styles.wrapper}>
      <div className={styles.picture}>
        <div
          className={styles.img}
          style={{ backgroundImage: U.string`url(${profilePicUrl$})` }}
        />
        {U.ifte(uploading$,
          (
            <div className={styles.uploading}>
              <div className={styles.overlay}></div>
              <div className={styles.progress}>
                <Loader className={styles.loader} theme="white" size={25} />
                <span>{progress$}%</span>
              </div>
            </div>
          ),
          (
            <FileUploadButton
              extensions={['jpg', 'jpeg', 'png']}
              onSelectFile={selectFile}
            >
              <div className={styles.changePicture}>
                <div className={styles.overlay}></div>
                <div className={styles.label}>
                  <span>{loc$('profile.changePicture')}</span>
                </div>
              </div>
            </FileUploadButton>
          )
        )}
      </div>
      <span className={styles.description}>{loc$('profile.changeProfilePicDescription')}</span>
    </div>
  )
})
