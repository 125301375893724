import React from 'karet'
import * as U from 'karet.util'
import styles from './Toast.css'
import Toast from './Toast.jsx'

import { toasts$, dismissToast } from 'stores/toasts'

export default () => (
  <div className={styles.toasts}>
    {U.seq(toasts$, U.mapIndexed((toast, idx) => (
      <Toast
        type={toast.type}
        onClick={() => dismissToast(idx)}
        message={toast.message}
        params={toast.params}
        key={idx}
      />
    )))}
  </div>
)
