import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import Decimal from 'decimal.js'
import * as U from 'karet.util'
import * as L from 'partial.lenses'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { loc$, date$, currency$ } from 'utils/i18n'
import { Table, TableControls, ButtonGroup, FilterTable, Link, Button } from 'components'
import { sortedBy$, sortTable, statusFilter$, changeStatusFilter } from './usersViewStore'
import { addToast } from 'stores/toasts'
import { createAction, createActionProperty } from 'utils/store'
import { isAdmin$ } from 'stores/user'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import styles from './Users.css'

const [ deleteUser, deleteUser$ ] = createAction(
  id => showApiRequestError(
    () => api.deleteUser(id).then(R.always(id)),
    { rethrow: true }
  )
)

export default waitForPromises(() => ({
  users: api.getUsers()
}), ({ users }) => {
  const usersWithFullName = users.map(user => ({ ...user, name: `${user.firstName} ${user.lastName}`}))

  const users$ = deleteUser$.ignoreErrors().scan(
    (users, id) => L.remove([L.required([]), L.find(R.whereEq({ id }))], users),
    usersWithFullName
  )

  const tableFields = [
    {
      title: 'user.info.id',
      key: 'id'
    },
    {
      title: 'user.info.name',
      render: ({ name, id, actionNeeded }) => U.ifte(isAdmin$, <a href="javascript:;" className={actionNeeded ? styles.actionNeeded : ''} onClick={() => navigateTo(`/user/overview/${id}`)}>{name}</a>, name),
      key: 'name'
    },
    {
      title: 'user.info.totalBilling',
      render: ({ totalBilling }) => currency$(totalBilling),
      sortFn: (a, b) => new Decimal(a.totalBilling).comparedTo(b.totalBilling),
      key: 'totalBilling'
    },
    {
      title: 'user.info.pendingPayRequests',
      render: ({ payRequests }) => currency$(payRequests),
      sortFn: (a, b) => new Decimal(a.payRequests).comparedTo(b.payRequests),
      key: 'payRequests'
    },
    {
      title: 'user.info.phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'user.info.language',
      key: 'language',
      render: ({ language }) => loc$(`languages.${language}`)
    },
    {
      title: 'user.info.type',
      key: 'isAdmin',
      render: ({ isAdmin }) => isAdmin ? loc$('user.info.admin') : loc$('user.info.journalist')
    },
    {
      title: 'user.info.joined',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id }) => (
        <TableControls
          onEdit={() => navigateTo(`/user/edit/${id}`)}
          onDelete={async () => {
            const loc = await loc$('user.confirmDelete').take(1).toPromise()
            if (confirm(loc)) {
              await deleteUser(id)
              addToast('success', 'user.deleteSuccess')
            }
          }}
        />
      ),
      className: styles.controls
    }
  ]

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction, sortFn: field.sortFn})
  }

  const [ updateSearchText, searchText ] = createActionProperty(() => '')

  const searchFields = ['id', 'name', 'ssn', 'phoneNumber', 'email', 'jlMemberId']
  const filteredUsers$ = Kefir.combine([users$, statusFilter$, searchText]).map(([users, statusFilter, searchText]) => {
    let filteredUsers = users.filter(user => {
      if (statusFilter === 'actionNeeded') {
        return user.actionNeeded
      }
      if (statusFilter === 'waitingForPay') {
        return new Decimal(user.payRequests).gt(0)
      }
      if (statusFilter === 'isMember') {
        return !user.memberResignedAt
      }
      if (statusFilter === 'isNotMember') {
        return user.memberResignedAt
      }
      return true
    })
    if (searchText === '') {
      return filteredUsers
    }
    const searchFn = field => field && field.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    return filteredUsers.filter(user => R.any(searchFn, R.values(R.pick(searchFields, user))))
  }).toProperty()

  const sortedAndFilteredUsers$ = sortedBy$.combine(filteredUsers$).map(([sorting, users]) => {
    const directionFn = sorting.direction === 'ASC' ? R.identity : R.reverse
    const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
    const sortFn = sorting.sortFn
      ? R.sort(sorting.sortFn)
      : R.sortBy(R.compose(toLowerIfString, R.prop(sorting.key)))
    const sortFnWithDirection = R.compose(directionFn, sortFn)

    return sortFnWithDirection(users)
  }).toProperty()

  return (
    <div>
      <div className={styles.splitHeader}>
        <h1>{loc$('user.usersList')}</h1>
        <div className={styles.controls}>
          <a href={`/api/v1/user/download-csv`} download>
            <Button
              className={styles.button}
              label="user.downloadCsv"
              theme="blue"
              noMargin
            />
          </a>
        </div>
      </div>
      <div className={styles.filters}>
        <ButtonGroup
          className={styles.statusFilter}
          value={statusFilter$}
          onChange={changeStatusFilter}
          buttons={[
            ['all', 'user.filters.all'],
            ['actionNeeded', 'user.filters.actionNeeded'],
            ['waitingForPay', 'user.filters.waitingForPay'],
            ['isMember', 'user.filters.isMember'],
            ['isNotMember', 'user.filters.isNotMember']
          ]}
        />
        <FilterTable value={searchText} onChange={updateSearchText} description="user.filters.searchDescription" className={styles.textFilter} />
      </div>
      <Table fields={tableFields} sortedBy={sortedBy$} data={sortedAndFilteredUsers$} sortFn={sortFn} />
    </div>
  )
})
