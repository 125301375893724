import R from 'ramda'
import * as L from 'partial.lenses'
import { lang$ } from 'stores/user'
import K from 'karet.util'
import localizations from 'shared/localizations'
import numeral from 'numeral'
import moment from 'moment-timezone'

let lang
lang$.onValue(l => (lang = l))

numeral.register('locale', 'fi', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: number => number,
  currency: {
    symbol: '€',
  },
})

numeral.locale('fi')

function _loc(keys, params, lang) {
  const key = R.flatten([keys]).join('.')
  const locs = L.get([...key.split('.')], localizations)
  if (!locs) {
    return `!!${key}!!`
  }
  const loc = locs[lang]
    ? locs[lang]
    : R.values(locs).find(loc => loc.length !== 0)

  return loc.replace(
    /\$\{([a-z0-9_-]+)\}/gi,
    (_, name) => params[name] || `!!${name}!!`
  )
}

export function loc(keys, params) {
  return _loc(keys, params, lang)
}

export function loc$(keys, params) {
  return K(keys, params, lang$, (keys, params, lang) =>
    _loc(keys, params, lang)
  )
}

export function date$(value) {
  return K(value, lang$, (value, lang) => {
    if (value) {
      return moment
        .utc(value)
        .tz('Europe/Helsinki')
        .format('D.M.YYYY')
    } else {
      return ''
    }
  })
}

export function dateRange$(dates) {
  const filterDates = R.compose(
    R.uniq,
    R.filter(R.identity)
  )
  return K(filterDates(dates), lang$, (dates, lang) => {
    const withTimezone = date =>
      moment
        .utc(date)
        .tz('Europe/Helsinki')
        .format('D.M.YYYY')
    return dates.map(withTimezone).join(' - ')
  })
}

export function datetime$(value) {
  return K(value, lang$, (value, lang) => {
    if (value) {
      return moment
        .utc(value)
        .tz('Europe/Helsinki')
        .format('D.M.YYYY HH:mm:ss')
    } else {
      return ''
    }
  })
}

function _decimal(value, lang) {
  return !R.isNil(value)
    ? numeral(value.toString().replace('.', ',')).format('0,0.00')
    : ''
}

export function decimal(value) {
  return _decimal(value, lang)
}

export function decimal$(value) {
  return K(value, lang$, (value, lang) => _decimal(value, lang))
}

function _percentage(value, lang) {
  return !R.isNil(value)
    ? numeral(value.toString().replace('.', ',')).format('0.[00]') + ' %'
    : ''
}

export function percentage(value) {
  return _percentage(value, lang)
}

export function percentage$(value) {
  return K(value, lang$, (value, lang) => _percentage(value, lang))
}

function _currency(value, lang) {
  return !R.isNil(value)
    ? numeral(value.toString().replace('.', ',')).format('0,0.00 $')
    : ''
}

export function currency(value) {
  return _currency(value, lang)
}

export function currency$(value) {
  return K(value, lang$, (value, lang) => _currency(value, lang))
}
