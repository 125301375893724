import { decimal } from 'utils/i18n'

export function decimalToLocalized(val) {
  return decimal(val)
}

export function decimalFromLocalized(val) {
  return val && val.replace(/ /g, '').replace(',', '.')
}

export function fileUploadFromDb(val) {
  return val ? { status: 'uploaded', file: val } : null
}

export function fileUploadToDb(val) {
  return val ? val.file : null
}

export function dateFromDb(val) {
  if (/^\d{4}-\d{2}-\d{2}$/.test(val)) {
    const [yyyy, mm, dd] = val.split('-')
    return `${dd.replace(/^0/, '')}.${mm.replace(/^0/, '')}.${yyyy}`
  } else {
    return val
  }
}

export function dateToDb(val) {
  const normalized = val.trim()
  if (!normalized.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
    return val
  }
  const [dd, mm, yyyy] = normalized.split('.')
  return `${yyyy}-${`0${mm}`.substr(-2)}-${`0${dd}`.substr(-2)}`
}

export function dateRangeFromDb(val) {
  return val.map(dateFromDb)
}

export function dateRangeToDb(val) {
  return val.map(dateToDb)
}

export function toInteger(val) {
  return val && parseInt(val)
}
