import React from 'karet'
import * as U from 'karet.util'
import * as schema from 'shared/schema'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, FileUploadInput } from 'utils/forms/controls'

export default ({disabled, initialValues, submitLabel, onSubmit, context, uploadFilesAsUser}) => {
  return (
    <Form
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={initialValues}
      schema={schema.reimbursement}
      context={context}
    >
      <FormField name="title">
        <TextInput label="reimbursements.info.title" />
      </FormField>
      <FormField name="total">
        <TextInput label="reimbursements.info.total" />
      </FormField>
      <FormField name="taxes">
        <TextInput label="reimbursements.info.taxes" />
      </FormField>
      <FormField name="receipt">
        <FileUploadInput label="reimbursements.info.receipt" folder="reimbursement" extensions={['jpg', 'jpeg', 'png', 'pdf']} uploadAsUser={uploadFilesAsUser} />
      </FormField>
      {U.ift(U.or(U.not(disabled), U.seq(initialValues, U.view('image'))), (
        <FormField name="image">
          <FileUploadInput label="reimbursements.info.image" folder="reimbursement" extensions={['jpg', 'jpeg', 'png', 'pdf']} uploadAsUser={uploadFilesAsUser} />
        </FormField>
      ))}
      {U.ift(U.not(disabled), (
        <FormSubmit>
          <Button label={submitLabel} large />
        </FormSubmit>
      ))}
    </Form>
  )
}
