import Promise from 'bluebird'
import validatorFormatter from './validatorFormatter'

export default function(validateArr) {
  return (val, context) => {
    return Promise.map(
      validateArr,
      async ([validateFn, reason, params]) => {
        const valid = await Promise.resolve(validateFn(val, context))
        return validatorFormatter(valid, reason, params)
      }
    )
  }
}
