import React from 'karet'
import styles from './App.css'
import { ContentWrapper } from 'components'

export default ({ children, subnavi }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {subnavi}
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </div>
  </div>
)
