export default {
  fields: {
    oldPassword: {
      required: true
    },
    newPassword: {
      required: true,
      validators: [
        'password'
      ],
      dependencies: ['newPasswordAgain']
    },
    newPasswordAgain: {
      required: true,
      validators: [
        ['identical', { field: 'newPassword' }]
      ]
    }
  }
}
