import R from 'ramda'
import Kefir from 'kefir'
import Promise from 'bluebird'

export default function(promiseFn = R.identity) {
  let emitter
  const actionStream = Kefir.stream((emitter_) => emitter = emitter_)
  const actionEmitter = (...args) => {
    try {
      const result = promiseFn(...args)
      const resolved = Promise.resolve(result)
      if (emitter) {
        // we want to emit the value instantly if the result is not a promise
        // the reason is that doing it via promise introduces asyncronity
        // which causes certain events such as text input editing to behave weirdly
        if (result && result.then) {
          resolved.then((val) => emitter.value(val), (err) => emitter.error(err))
        } else {
          emitter.value(result)
        }
      }
      return resolved
    } catch (err) {
      if (emitter) {
        emitter.error(err)
      }
      return Promise.reject(err)
    }
  }
  return [ actionEmitter, actionStream ]
}
