import React, { fromKefir } from 'karet'
import * as U from 'karet.util'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import { lang$ } from 'stores/user'
import { showApiRequestError } from 'utils/errors'
import { navigateTo } from 'stores/navigation'
import * as joinPayment from 'stores/join-payment'
import JoinPersonalData from './JoinPersonalData.jsx'
import JoinPay from './JoinPay.jsx'
import JoinSteps from './JoinSteps.jsx'

const onPersonalDataSubmit = values => showApiRequestError(async () => {
  const language = await lang$.take(1).toPromise()
  const data = { ...values, language }
  await joinPayment.createPayment(data)
})

export default () => (
  <div>
    <JoinSteps step={joinPayment.step$} />
    {
      U.ifte(U.equals(joinPayment.step$, 'personalData'),
        (
          <JoinPersonalData onSubmit={onPersonalDataSubmit} />
        ),
        (
          <JoinPay
            personalData={joinPayment.personalData$}
            payment={joinPayment.payment$}
          />
        )
      )
    }
  </div>
)
