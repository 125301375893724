import React from 'karet'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import JoinSteps from './JoinSteps.jsx'
import * as joinPayment from 'stores/join-payment'
import { Link, Button } from 'components'

export default () => {
  joinPayment.clearAllData()

  return (
    <div>
      <JoinSteps step="done" />
      <h1>{loc$('join.thankYouTitle')}</h1>
      <p>{loc$('join.thankYouDescription')}</p>
      <Link to="/"><Button label="join.goToLoginButton" /></Link>
    </div>
  )
}
