import React from 'karet'
import { classes } from 'karet.util'
import { withFormAggregator } from 'utils/forms'
import styles from './Form.css'

export default withFormAggregator(({ formAggregator, className, children }) => {
  const { form } = formAggregator

  return (
    <div {...classes(className)}>
      {React.cloneElement(children, {
        onClick: () => form.submit(),
        isLoading: form.state.submitting$
      })}
      <input type="submit" className={styles.formSubmit} tabIndex={-1} />
    </div>
  )
})
