import React from 'karet'
import { loc$, currency$ } from 'utils/i18n'
import { waitForPromises } from 'utils/containers'
import { Link, Button, ProfileInfo, ProfilePicSelector } from 'components'
import * as api from 'api'
import styles from './Profile.css'

export default waitForPromises(
  () => ({ profile: api.getProfile() }),
  ({ profile }) => (
    <div>
      <h1>{loc$('profile.header')}</h1>
      <div className={styles.profilePage}>
        <div className={styles.infoColumn}>
          <ProfileInfo profile={profile} />
        </div>
        <div className={styles.pictureColumn}>
          <div className={styles.picture}><ProfilePicSelector /></div>
          <div className={styles.introduction}>{profile.introduction}</div>
        </div>
      </div>
      <Link className={styles.profileControlButton} to="/profile/edit"><Button label="profile.edit" /></Link>
      <Link to="/profile/change-password"><Button label="profile.changePassword" /></Link>
    </div>
  )
)
