import React from 'karet'
import * as U from 'karet.util'
import { loc$, percentage$, date$ } from 'utils/i18n'
import styles from './ProfileInfo.css'

export default ({ profile, joinFieldsOnly = false }) => {
  const memberResignedAt = U.view('memberResignedAt', profile)

  return (
    <table {...U.classes(styles.infoTable, styles.infoTableHorizontal)}>
      <tbody>
        <tr>
          <th>{loc$('user.info.jlMemberId')}</th>
          <td>{U.defaultTo('-', U.view('jlMemberId', profile))}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.email')}</th>
          <td>{U.view('email', profile)}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.firstName')}</th>
          <td>{U.view('firstName', profile)}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.lastName')}</th>
          <td>{U.view('lastName', profile)}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.ssn')}</th>
          <td>{U.defaultTo('-', U.view('ssn', profile))}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.phoneNumber')}</th>
          <td>{U.defaultTo('-', U.view('phoneNumber', profile))}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.streetAddress')}</th>
          <td>{U.defaultTo('-', U.view('streetAddress', profile))}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.postalCode')}</th>
          <td>{U.defaultTo('-', U.view('postalCode', profile))}</td>
        </tr>
        <tr>
          <th>{loc$('user.info.postOffice')}</th>
          <td>{U.defaultTo('-', U.view('postOffice', profile))}</td>
        </tr>
        {U.ift(U.not(joinFieldsOnly), (
          <tr>
            <th>{loc$('user.info.bankIban')}</th>
            <td>{U.defaultTo('-', U.view('bankIban', profile))}</td>
          </tr>
        ))}
        {U.ift(U.not(joinFieldsOnly), (
          <tr>
            <th>{loc$('user.info.bankBic')}</th>
            <td>{U.defaultTo('-', U.view('bankBic', profile))}</td>
          </tr>
        ))}
        {U.ift(U.not(joinFieldsOnly), (
          <tr>
            <th>{loc$('user.info.taxCard')}</th>
            <td>
              {U.ifte(U.view('taxCard', profile),
                <a href={U.view(['taxCard', 'url'], profile)} target="_blank">{loc$('user.info.showTaxCard')}</a>,
                <span className={styles.noTaxCard}>{loc$('profile.noTaxCard')}</span>
              )}
            </td>
          </tr>
        ))}
        {U.ift(U.not(joinFieldsOnly), (
          <tr>
            <th>{loc$('user.info.taxPercentage')}</th>
            <td>{U.ifte(U.view('taxPercentage', profile), percentage$(U.view('taxPercentage', profile)), '-')}</td>
          </tr>
        ))}
        <tr>
          <th>{loc$('user.info.memberJoinedAt')}</th>
          <td>{date$(U.view('memberJoinedAt', profile))}</td>
        </tr>
        {U.ift(memberResignedAt, (
          <tr>
            <th>{loc$('user.info.memberResignedAt')}</th>
            <td>{date$(memberResignedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
