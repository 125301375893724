import React from 'karet'
import Kefir from 'kefir'
import R from 'ramda'
import * as U from 'karet.util'
import { waitForPromises } from 'utils/containers'
import { Table, TableControls, ButtonGroup } from 'components'
import { loc$, currency$, date$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import { sortedBy$, sortTable, reimbursementStatusFilter$, changeReimbursementStatusFilter } from './reimbursementsViewStore'
import { isAdmin$ } from 'stores/user'
import styles from './Reimbursements.css'
import * as api from 'api'

export default waitForPromises(() => ({
  reimbursements: api.getReimbursements()
}), ({ reimbursements }) => {

  const tableFields = [
    {
      title: 'reimbursements.info.id',
      key: 'id'
    },
    {
      title: 'reimbursements.info.status',
      key: 'status',
      render: ({ status }) => loc$(`reimbursements.status.${status}`)
    },
    {
      title: 'reimbursements.userName',
      key: 'userName',
      render: ({ userName, userId }) => U.ifte(isAdmin$, <a href="javascript:;" onClick={() => navigateTo(`/user/overview/${userId}`)}>{userName}</a>, userName)
    },
    {
      title: 'reimbursements.info.title',
      key: 'title'
    },
    {
      title: 'reimbursements.info.total',
      key: 'total',
      render: ({total}) => currency$(total)
    },
    {
      title: 'reimbursements.info.taxes',
      key: 'taxes',
      render: ({taxes}) => currency$(taxes)
    },
    {
      title: 'reimbursements.info.receipt',
      key: 'receipt',
      render: ({receipt}) => receipt && receipt.url && <a href={receipt.url} target="_blank">{loc$('reimbursements.info.receipt')}</a>
    },
    {
      title: 'reimbursements.info.image',
      key: 'image',
      render: ({image}) => image && image.url && <a href={image.url} target="_blank">{loc$('reimbursements.info.image')}</a>
    },
    {
      title: 'reimbursements.info.created',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id, status }) => (
        <TableControls
          onView={() => navigateTo(`/reimbursement/view/${id}`)}
          onEdit={() => navigateTo(`/reimbursement/edit/${id}`)}
          editEnabled={U.and(isAdmin$, U.equals(status, 'new'))}
        />
      ),
      className: styles.controls
    }
  ]

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction})
  }

  const reimbursements$ = Kefir.constant(reimbursements)

  const sortedFilteredReimbursements$ = Kefir.combine(
    [reimbursements$, sortedBy$, reimbursementStatusFilter$],
    (reimbursements, sortedBy, statusFilter) => {
      const filteredReimbursements = statusFilter === 'all' ? reimbursements : reimbursements.filter(reimbursement => reimbursement.status === statusFilter)
      const directionFn = sortedBy.direction === 'ASC' ? R.identity : R.reverse
      const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
      const toIntIfNumber = (val) => isNaN(val) ? val : parseInt(val)
      const sortBy = R.compose(directionFn, R.sortBy(R.compose(toLowerIfString, toIntIfNumber, R.prop(sortedBy.key))))

      return sortBy(filteredReimbursements)
    }
  ).toProperty()

  return (
    <div className={styles.reimbursementList}>
      <h1>{loc$('reimbursements.reimbursementsList')}</h1>
      <div className={styles.filters}>
        <ButtonGroup
          value={reimbursementStatusFilter$}
          onChange={changeReimbursementStatusFilter}
          buttons={[
            ['all', 'reimbursements.filters.all'],
            ['new', 'reimbursements.status.new'],
            ['approved', 'reimbursements.status.approved'],
            ['rejected', 'reimbursements.status.rejected'],
          ]}
        />
      </div>
      <Table fields={tableFields} sortedBy={sortedBy$} data={sortedFilteredReimbursements$} sortFn={sortFn} />
    </div>
  )
})
