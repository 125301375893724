import { createAction } from 'utils/store'
import Kefir from 'kefir'
import * as L from 'partial.lenses'

const [ addToast, onAddToast$ ] = createAction((type, message, params = {}) => ({type, message, params}))
const [ dismissToast, onDismissToast$ ] = createAction()

const toasts$ = Kefir.merge([
    onAddToast$.map(toast => toasts => toasts.concat(toast)),
    onDismissToast$.map(idx => L.remove([L.required([]), idx]))
  ])
.scan((toasts, updateFn) => updateFn(toasts), [])

onAddToast$
  .merge(onDismissToast$)
  .flatMapLatest(() => Kefir.later(5000))
  .onValue(() => dismissToast(0))

export {
  addToast,
  dismissToast,
  toasts$
}
