import 'babel-polyfill'
import React from 'karet'
import { render } from 'react-dom'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { onNavigateTo } from 'stores/navigation'
import { me$ } from 'stores/user'
import { makeSubNavi } from 'utils/containers'
import homeSubNavi from 'views/Home/homeSubNavigation'
import profileSubNavi from 'views/Profile/profileSubNavigation'
import usersSubNavi from 'views/Users/usersSubNavigation'
import invoicesSubNavi from 'views/Invoices/invoicesSubNavigation'
import balanceSubNavi from 'views/Balance/balanceSubNavigation'
import contractsSubNavi from 'views/Contracts/contractsSubNavigation'
import * as views from 'views'
import { ApiError } from './api'

const getReleaseStage = () => {
  if (window.location.hostname === 'localhost') {
    return 'development'
  }
  if (window.location.hostname === 'mediakunta-staging.herokuapp.com') {
    return 'staging'
  }
  return 'production'
}

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  releaseStage: getReleaseStage(),
  plugins: [new BugsnagPluginReact()],
  onError: async event => {
    // do not report 403 errors to BugSnag
    if (
      event.originalError instanceof ApiError &&
      event.originalError.code === 'FORBIDDEN'
    ) {
      return false
    }

    const user = await me$.take(1).toPromise()
    if (user) {
      event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`)
    }
    event.addMetadata('originalError', event.originalError)
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const AppContainer = React.createClass({
  componentDidMount() {
    onNavigateTo
      .ignoreErrors()
      .debounce(0)
      .takeWhile(() => this.isMounted())
      .onValue(path => this.props.router.push(path))
  },
  render() {
    return this.props.children
  },
})

render(
  <ErrorBoundary>
    <Router history={browserHistory}>
      <Route component={AppContainer}>
        <Route component={views.App}>
          <Route path="/join" component={views.Join}>
            <IndexRoute component={views.JoinForm} />
            <Route path="thank-you" component={views.JoinThankYou} />
            <Route path="cancel" component={views.JoinCanceled} />
            <Route path="error/:transactionId" component={views.JoinError} />
          </Route>
          <Route component={views.RequireLogin}>
            <Route
              path="/"
              components={{
                main: ({ children }) => children,
                subnavi: makeSubNavi(homeSubNavi),
              }}
            >
              <IndexRoute component={views.Home} />
              <Route path="news" component={views.News} />
              <Route path="instructions" component={views.Instructions} />
              <Route path="files">
                <IndexRoute component={views.FilesList} />
                <Route path="new" component={views.NewFile} />
                <Route path="edit/:fileId" component={views.EditFile} />
              </Route>
            </Route>
            <Route
              path="profile"
              components={{
                main: views.Profile,
                subnavi: makeSubNavi(profileSubNavi),
              }}
            >
              <IndexRoute component={views.ViewProfile} />
              <Route path="edit" component={views.EditProfile} />
              <Route path="change-password" component={views.ChangePassword} />
            </Route>
            <Route
              path="user"
              components={{
                main: views.Users,
                subnavi: makeSubNavi(usersSubNavi),
              }}
            >
              <IndexRoute component={views.UserList} />
              <Route path="new" component={views.NewUser} />
              <Route path="edit/:userId" component={views.EditUser} />
              <Route path="overview/:userId" component={views.Overview} />
            </Route>
            <Route
              path="invoice"
              components={{
                main: views.Invoices,
                subnavi: makeSubNavi(invoicesSubNavi),
              }}
            >
              <IndexRoute component={views.InvoicesList} />
              <Route path="new" component={views.NewInvoice} />
              <Route path="draft/:draftId" component={views.NewInvoice} />
              <Route
                path="new/from-invoice/:invoiceId"
                component={views.NewInvoice}
              />
              <Route path="new/for-user/:userId" component={views.NewInvoice} />
              <Route path="view/:invoiceId" component={views.ViewInvoice} />
              <Route path="edit/:invoiceId" component={views.EditInvoice} />
              <Route path="summary" component={views.InvoicesSummary} />
              <Route
                path="instructions"
                component={views.InvoiceInstructions}
              />
            </Route>
            <Route
              path="reimbursement"
              components={{ main: views.Reimbursements }}
            >
              <IndexRoute component={views.ReimbursementsList} />
              <Route
                path="view/:reimbursementId"
                component={views.ViewReimbursement}
              />
              <Route
                path="edit/:reimbursementId"
                component={views.EditReimbursement}
              />
            </Route>
            <Route
              path="balance"
              components={{
                main: views.Balance,
                subnavi: makeSubNavi(balanceSubNavi),
              }}
            >
              <IndexRoute component={views.BalanceOverview} />
              <Route path="withdraw" component={views.BalanceWithdraw} />
              <Route
                path="reimbursements"
                component={views.BalanceReimbursements}
              />
              <Route
                path="reimbursements/new"
                component={views.BalanceNewReimbursement}
              />
              <Route path="payslip-calculator" component={views.Payslip} />
            </Route>
            <Route
              path="contract"
              components={{
                main: views.Contracts,
                subnavi: makeSubNavi(contractsSubNavi),
              }}
            >
              <IndexRoute component={views.ContractList} />
              <Route path="new" component={views.NewContract} />
              <Route
                path="new/for-user/:userId"
                component={views.NewContract}
              />
              <Route path="edit/:contractId" component={views.EditContract} />
            </Route>
            <Route path="*" components={{ main: views.NotFound }} />
          </Route>
        </Route>
      </Route>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)
