import R from 'ramda'
import React from 'karet'
import { withFormAggregator } from 'utils/forms'
import { classes } from 'karet.util'
import { DynamicList } from 'components'

const arrayLength = R.pipe(R.defaultTo([]), R.length)

export default withFormAggregator(React.createClass({
  render() {
    const { name, className, renderInput, renderFooter, formAggregator } = this.props
    const form = formAggregator.form
    const fieldName = formAggregator.name
      ? `${formAggregator.name}[${name}]`
      : name
    const fieldSchema = R.path(['fields', name], formAggregator.schema) || {}
    const required = fieldSchema.required
    const initialItems = arrayLength(form.getFieldInitialValue(fieldName))
    const disabled$ = form.getDisabledProperty()

    return (
      <DynamicList
        {...classes(className)}
        initialItems={initialItems}
        minItems={required ? 1 : 0}
        disabled={disabled$}
        renderRow={(idx, ...rest) => renderInput(`${name}[${idx}]`, ...rest)}
        renderFooter={addRow => renderFooter(addRow)}
      />
    )
  }
}))
