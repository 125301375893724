import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import Decimal from 'decimal.js'
import K, * as U from 'karet.util'
import * as L from 'partial.lenses'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { loc, loc$, currency$, date$, dateRange$ } from 'utils/i18n'
import {
  Table,
  TableControls,
  ButtonGroup,
  FilterTable,
  InlineNotification,
} from 'components'
import {
  sortedBy$,
  sortTable,
  invoiceStatusFilter$,
  changeInvoiceStatusFilter,
  invoiceTypeFilter$,
  changeInvoiceTypeFilter,
} from './invoicesViewStore'
import { addToast } from 'stores/toasts'
import { createAction, createActionProperty } from 'utils/store'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import { isAdmin$ } from 'stores/user'
import styles from './Invoices.css'

const [deleteInvoice, deleteInvoice$] = createAction(id =>
  showApiRequestError(async () => {
    if (confirm(loc('invoice.confirmDelete'))) {
      await api.deleteInvoice(id)
      addToast('success', 'invoice.deleteSuccess')
      return id
    }
  })
)

const [deleteDraft, deleteDraft$] = createAction(id =>
  showApiRequestError(async () => {
    if (confirm(loc('invoice.confirmDeleteDraft'))) {
      await api.deleteDraft(id)
      addToast('success', 'invoice.deleteDraftSuccess')
      return id
    }
  })
)

const [copyDraft, copyDraft$] = createAction(id =>
  showApiRequestError(async () => {
    const draftName = prompt(loc('invoice.saveAsDraftConfirm'))
    if (draftName) {
      const draft = await api.copyDraft(id, draftName)
      addToast('success', 'invoice.draftCopied')
      navigateTo(`/invoice/draft/${draft.id}`)
      return draft
    }
  })
)

export default waitForPromises(
  () => ({
    invoices: api.getInvoices(),
    drafts: api.getDrafts(),
    isAdmin: isAdmin$.take(1).toPromise(),
  }),
  ({ invoices, drafts, isAdmin }) => {
    const tableFields = R.reject(R.isNil, [
      {
        title: 'invoice.info.id',
        key: 'id',
      },
      {
        title: 'invoice.info.status',
        key: 'status',
        render: ({ status }) => loc$(`invoice.status.${status}`),
      },
      {
        title: 'invoice.info.client',
        key: 'clientName',
      },
      isAdmin
        ? {
            title: 'invoice.info.billerName',
            key: 'billerName',
            render: ({ billerName, userId }) => (
              <a
                href="javascript:;"
                onClick={() => navigateTo(`/user/overview/${userId}`)}
              >
                {billerName}
              </a>
            ),
          }
        : undefined,
      {
        title: 'invoice.info.totalPrice',
        render: ({ totalPrice }) => currency$(totalPrice),
        sortFn: (a, b) => new Decimal(a.totalPrice).comparedTo(b.totalPrice),
        key: 'totalPrice',
      },
      {
        title: 'invoice.info.referenceNumber',
        key: 'referenceNumber',
      },
      {
        title: 'invoice.info.workDates',
        key: 'dateStart',
        render: ({ dateStart, dateEnd }) => dateRange$([dateStart, dateEnd]),
      },
      {
        title: 'invoice.info.created',
        key: 'createdAt',
        render: ({ createdAt }) => date$(createdAt),
      },
      {
        title: 'invoice.info.dueDate',
        key: 'dueAt',
        render: ({ dueAt }) => date$(dueAt),
      },
      {
        title: '',
        render: ({ id, status }) => (
          <TableControls
            onView={() => navigateTo(`/invoice/view/${id}`)}
            onEdit={() => navigateTo(`/invoice/edit/${id}`)}
            onDelete={() => deleteInvoice(id)}
            editEnabled={U.and(isAdmin$, U.equals(status, 'new'))}
            deleteEnabled={U.and(isAdmin$, U.equals(status, 'new'))}
          />
        ),
        className: styles.controls,
      },
    ])

    const sortFn = async field => {
      const sorting = await sortedBy$.take(1).toPromise()
      const direction =
        field.key === sorting.key && sorting.direction !== 'DESC'
          ? 'DESC'
          : 'ASC'

      sortTable({ key: field.key, direction: direction, sortFn: field.sortFn })
    }

    const invoices$ = deleteInvoice$
      .filter(Boolean)
      .ignoreErrors()
      .scan(
        (invoices, id) =>
          L.remove([L.required([]), L.find(R.whereEq({ id }))], invoices),
        invoices
      )

    const drafts$ = deleteDraft$
      .filter(Boolean)
      .ignoreErrors()
      .scan(
        (drafts, id) =>
          L.remove([L.required([]), L.find(R.whereEq({ id }))], drafts),
        drafts
      )

    const [updateSearchText, searchText] = createActionProperty(() => '')
    const searchFields = ['id', 'clientName', 'billerName', 'referenceNumber']
    const sortedFilteredInvoices$ = Kefir.combine(
      [
        invoices$,
        sortedBy$,
        invoiceStatusFilter$,
        invoiceTypeFilter$,
        searchText,
      ],
      (invoices, sortedBy, statusFilter, typeFilter, searchText) => {
        let filteredInvoices =
          statusFilter === 'all'
            ? invoices
            : invoices.filter(invoice => invoice.status === statusFilter)
        if (typeFilter !== 'all') {
          filteredInvoices = filteredInvoices.filter(
            typeFilter === 'invoices'
              ? invoice => !invoice.isCreditNote
              : invoice => invoice.isCreditNote
          )
        }
        if (searchText !== '') {
          const searchFn = field =>
            field &&
            field
              .toString()
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
          filteredInvoices = filteredInvoices.filter(invoice =>
            R.any(searchFn, R.values(R.pick(searchFields, invoice)))
          )
        }

        const directionFn =
          sortedBy.direction === 'ASC' ? R.identity : R.reverse
        const toLowerIfString = val =>
          val && val.toLowerCase ? val.toLowerCase() : val
        const sortFn = sortedBy.sortFn
          ? R.sort(sortedBy.sortFn)
          : R.sortBy(
              R.compose(
                toLowerIfString,
                R.prop(sortedBy.key)
              )
            )
        const sortFnWithDirection = R.compose(
          directionFn,
          sortFn
        )

        return sortFnWithDirection(filteredInvoices)
      }
    ).toProperty()

    return (
      <div className={styles.invoiceList}>
        <h1>{loc$('invoice.invoicesList')}</h1>
        {U.seq(
          drafts$,
          U.mapIndexed((draft, idx) => (
            <InlineNotification
              key={idx}
              links={[
                {
                  label: 'invoice.editDraft',
                  path: `/invoice/draft/${draft.id}`,
                },
                {
                  label: 'invoice.copyDraft',
                  action: () => copyDraft(draft.id),
                },
                {
                  label: 'invoice.deleteDraft',
                  action: () => deleteDraft(draft.id),
                },
              ]}
            >
              <p
                dangerouslySetInnerHTML={K(
                  loc$('invoice.draftDescription', {
                    name: draft.name,
                    createdAt: date$(draft.createdAt),
                    updatedAt: date$(draft.updatedAt),
                  }),
                  __html => ({ __html })
                )}
              />
            </InlineNotification>
          ))
        )}
        <div className={styles.filters}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ButtonGroup
              className={styles.invoiceTypeFilter}
              value={invoiceTypeFilter$}
              onChange={changeInvoiceTypeFilter}
              buttons={[
                ['all', 'invoice.invoiceType.all'],
                ['invoices', 'invoice.invoiceType.invoices'],
                ['creditNotes', 'invoice.invoiceType.creditNotes'],
              ]}
            />
            <ButtonGroup
              className={styles.statusFilter}
              value={invoiceStatusFilter$}
              onChange={changeInvoiceStatusFilter}
              buttons={[
                ['all', 'invoice.filters.all'],
                ['new', 'invoice.status.new'],
                ['approved', 'invoice.status.approved'],
                ['rejected', 'invoice.status.rejected'],
                ['billed', 'invoice.status.billed'],
                ['paid', 'invoice.status.paid'],
                ['credited', 'invoice.status.credited'],
              ]}
            />
          </div>
          <FilterTable
            className={styles.textFilter}
            value={searchText}
            onChange={updateSearchText}
            description="invoice.filters.searchDescription"
          />
        </div>
        <Table
          fields={tableFields}
          sortedBy={sortedBy$}
          data={sortedFilteredInvoices$}
          sortFn={sortFn}
        />
      </div>
    )
  }
)
