import Kefir from 'kefir'

export default function(keyF, transformF) {
  let emitters = {}
  return (value) => {
    const key = keyF(value)
    if (emitters[key]) {
      emitters[key].value(value)
      return Kefir.never()
    }
    return transformF(Kefir.stream(emitter => {
      emitter.value(value)
      emitters[key] = emitter
      return () => delete emitters[key]
    }), key)
  }
}
