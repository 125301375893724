import React from 'karet'
import { loc$ } from 'utils/i18n'
import * as api from 'api'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import FileForm from './FileForm.jsx'

const onSubmit = values => showApiRequestError(async () => {
  await api.addFile(values)
  addToast('success', 'files.creationSuccess')
  navigateTo('/files')
})

export default () => {
  return (
    <div>
      <h1>{loc$('files.newFile')}</h1>
      <FileForm
        onSubmit={onSubmit}
        submitLabel="files.saveFile"
        editing={false}
      />
    </div>
  )
}
