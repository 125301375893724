import R from 'ramda'
import React from 'karet'
import K, * as U from 'karet.util'
import * as L from 'partial.lenses'
import { Form, FormField, FormSubmit, Button, InputRepeater } from 'components'
import {
  TextInput,
  DateInput,
  TextArea,
  Select,
  FileUploadInput,
  HiddenInput,
  Checkbox,
} from 'utils/forms/controls'
import * as schema from 'shared/schema'
import styles from './Invoices.css'
import { classNames as cx } from 'utils'
import { loc$, currency$, decimal$ } from 'utils/i18n'
import { createActionProperty } from 'utils/store'
import { addToast } from 'stores/toasts'
import { copyToClipboard, replaceLink } from 'utils/misc'
import { isAdmin$, lang$ } from 'stores/user'
import Decimal from 'decimal.js'
import moment from 'moment'

export default ({
  initialValues,
  submitLabel,
  onChange,
  onSubmit,
  submitStream,
  disabled,
  invoiceId,
  saveDraftEnabled,
  saveDraftLabel,
  onSaveDraft,
  uploadFilesAsUser,
}) => {
  const [updateForm, formUpdated$] = createActionProperty()

  const deliveryMethod$ = formUpdated$
    .map(R.prop('deliveryMethod'))
    .toProperty()
  const useEinvoiceAddress$ = formUpdated$
    .map(R.prop('useEinvoiceAddress'))
    .toProperty()

  const productsAndTaxesTotal$ = formUpdated$
    .map(R.prop('product'))
    .map(products =>
      R.defaultTo([], products).reduce(
        ([totalPrice, totalTaxes], { price, amount, vat }) => {
          try {
            const productPrice = new Decimal(R.isNil(price) ? 0 : price)
            const productAmount = new Decimal(R.isNil(amount) ? 0 : amount)
            const productVat = new Decimal(R.isNil(vat) ? 0 : vat)
            const combinedPrice = productPrice.mul(productAmount)
            const taxes = combinedPrice.mul(productVat.div(100))
            return [totalPrice.add(combinedPrice), totalTaxes.add(taxes)]
          } catch (err) {
            return [totalPrice, totalTaxes]
          }
        },
        [new Decimal(0), new Decimal(0)]
      )
    )
    .toProperty()

  const productsTotal$ = productsAndTaxesTotal$
    .map(L.get(0))
    .map(R.toString)
    .skipDuplicates()
    .toProperty()

  const taxesTotal$ = productsAndTaxesTotal$
    .map(L.get(1))
    .map(R.toString)
    .skipDuplicates()
    .toProperty()

  const productsTotalWithTaxes$ = productsTotal$
    .combine(taxesTotal$, (products, taxes) => new Decimal(products).add(taxes))
    .map(R.toString)
    .skipDuplicates()
    .toProperty()

  const expensesTotal$ = formUpdated$
    .map(R.prop('expense'))
    .map(expenses =>
      R.defaultTo([], expenses).reduce((totalExpenses, { expense }) => {
        try {
          const expenseAmount = new Decimal(R.isNil(expense) ? 0 : expense)
          return totalExpenses.add(expenseAmount)
        } catch (err) {
          return totalExpenses
        }
      }, new Decimal(0))
    )
    .map(R.toString)
    .skipDuplicates()
    .toProperty()

  const hoursUpdated$ = formUpdated$
    .map(R.prop('hour'))
    .map(hours => R.defaultTo([], hours))

  const hoursTotal$ = hoursUpdated$
    .map(hours =>
      hours.reduce((totalHours, { hours }) => {
        try {
          const hourAmount = new Decimal(R.isNil(hours) ? 0 : hours)
          return totalHours.add(hourAmount)
        } catch (err) {
          return totalHours
        }
      }, new Decimal(0))
    )
    .map(R.toString)
    .skipDuplicates()
    .toProperty()

  const hoursClipboardText$ = hoursUpdated$
    .map(hours =>
      hours.map(
        ({ date, hours }) => `${moment(date).format('D.M.YYYY')}: ${hours}`
      )
    )
    .map(hours => hours.join('\n'))
    .map(hours => `Työpäivät ja -tunnit (#${invoiceId}):\n${hours}`)
    .skipDuplicates()
    .toProperty()

  return (
    <Form
      schema={schema.invoice}
      context={{ invoiceId }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitStream={submitStream}
      className={styles.form}
      onChange={updateForm}
      disabled={disabled}
    >
      <div className={styles.formSection}>
        <h2>{loc$('invoice.client.header')}</h2>
        <div className={styles.row}>
          <FormField name="clientName" className={styles.col33}>
            <TextInput label="invoice.client.name" />
          </FormField>
          <FormField name="clientBusinessId" className={styles.col33}>
            <TextInput label="invoice.client.businessId" />
          </FormField>
          <FormField name="clientContact" className={styles.col33}>
            <TextInput label="invoice.client.contact" />
          </FormField>
        </div>
        <div className={styles.row}>
          <FormField name="clientAddress" className={styles.col33}>
            <TextInput label="invoice.client.address" />
          </FormField>
          <FormField name="clientPostalCode" className={styles.col33}>
            <TextInput label="invoice.client.postalCode" />
          </FormField>
          <FormField name="clientPostOffice" className={styles.col33}>
            <TextInput label="invoice.client.postOffice" />
          </FormField>
        </div>
      </div>

      <div className={styles.formSection}>
        <h2>{loc$('invoice.job.header')}</h2>

        <FormField name="invoiceLanguage">
          <Select
            label="invoice.job.invoiceLanguage"
            options={[
              '',
              ['fi', 'languages.fi'],
              ['en', 'languages.en'],
              ['sv', 'languages.sv'],
            ]}
          />
        </FormField>
        <FormField name="deliveryMethod">
          <Select
            label="invoice.job.deliveryMethod"
            options={[
              ['einvoice', 'invoice.deliveryMethod.einvoice'],
              ['email', 'invoice.deliveryMethod.email'],
              ['letter', 'invoice.deliveryMethod.letter'],
            ]}
          />
        </FormField>
        {U.ift(
          U.equals(deliveryMethod$, 'einvoice'),
          <div style={{ marginBottom: '8px' }}>
            <p
              className={styles.inputDescription}
              dangerouslySetInnerHTML={K(
                loc$('invoice.job.deliveryMethodDescription'),
                __html => ({ __html })
              )}
            />
            <FormField name="useEinvoiceAddress">
              <label className={styles.useEinvoiceLabel}>
                <Checkbox />
                {loc$('invoice.job.useEinvoiceAddress')}
              </label>
            </FormField>
          </div>
        )}
        {U.ift(
          useEinvoiceAddress$,
          <div>
            <FormField name="einvoiceAddress">
              <TextInput label="invoice.job.einvoiceAddress" />
            </FormField>
            <FormField name="einvoiceOperatorId">
              <TextInput label="invoice.job.einvoiceOperatorId" />
            </FormField>
          </div>
        )}
        {U.ift(
          U.equals(deliveryMethod$, 'email'),
          <div>
            <FormField name="deliveryEmail">
              <TextInput label="invoice.job.deliveryEmail" />
            </FormField>
            <FormField name="deliveryEmailLanguage">
              <Select
                label="invoice.job.deliveryEmailLanguage"
                options={[
                  '',
                  ['fi', 'languages.fi'],
                  ['en', 'languages.en'],
                  ['sv', 'languages.sv'],
                ]}
              />
            </FormField>
          </div>
        )}
        {U.ift(
          U.equals(deliveryMethod$, 'einvoice'),
          <div>
            <FormField name="reminderDeliveryEmail">
              <TextInput label="invoice.job.reminderDeliveryEmail" />
              <div className={styles.inputDescription}>
                {loc$('invoice.job.reminderDeliveryEmailDescription')}
              </div>
            </FormField>
            <FormField name="reminderDeliveryEmailLanguage">
              <Select
                label="invoice.job.reminderDeliveryEmailLanguage"
                options={[
                  '',
                  ['fi', 'languages.fi'],
                  ['en', 'languages.en'],
                  ['sv', 'languages.sv'],
                ]}
              />
            </FormField>
          </div>
        )}
        <FormField name="dueDate">
          <Select
            label="invoice.job.paymentTime"
            options={[7, 14, 21, 30, 60]}
          />
          <div className={styles.inputDescription}>
            {loc$('invoice.job.paymentTimeDescription')}
          </div>
        </FormField>
        <FormField name="description">
          <TextArea label="invoice.job.description" />
          <div className={styles.inputDescription}>
            {loc$('invoice.job.descriptionDescription')}
          </div>
        </FormField>
        <FormField name="additionalInfo">
          <TextArea label="invoice.job.additionalInfo" />
          <div className={styles.inputDescription}>
            {loc$('invoice.job.additionalInfoDescription')}
          </div>
        </FormField>
        <InputRepeater
          name="hour"
          className={styles.hours}
          renderInput={(name, { del, moveUp, moveDown }, idx) => (
            <FormField key={name} name={name} className={styles.flexRow}>
              <FormField name="date" className={styles.flexCol}>
                <DateInput label="invoice.job.date" />
              </FormField>
              <FormField name="hours" className={styles.flexCol}>
                <TextInput label="invoice.job.hours" />
              </FormField>
              <FormField name="sort" className={styles.hidden}>
                <HiddenInput value={idx} />
              </FormField>
              {moveUp || moveDown ? (
                <div className={cx([styles.controls, styles.orderControls])}>
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderUp,
                      moveUp ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveUp}
                  />
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderDown,
                      moveDown ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveDown}
                  />
                </div>
              ) : null}
              {del && (
                <div className={cx([styles.flexCol, styles.controls])}>
                  <Button
                    className={styles.removeInput}
                    label="invoice.info.deleteRow"
                    onClick={del}
                    theme="transparent"
                  />
                </div>
              )}
            </FormField>
          )}
          renderFooter={add => (
            <div>
              {U.ift(
                U.and(disabled, isAdmin$),
                <Button
                  noMargin
                  label="invoice.info.copyToClipboard"
                  onClick={() =>
                    hoursClipboardText$.take(1).onValue(text => {
                      copyToClipboard(text)
                      addToast('success', 'invoice.info.copiedToClipboard')
                    })
                  }
                />
              )}
              <div className={styles.totalsDescription}>
                <div>
                  {loc$('invoice.info.hoursTotal')}: {decimal$(hoursTotal$)}
                </div>
              </div>
              <div className={styles.inputDescription}>
                {loc$('invoice.job.datesDescription')}
              </div>
              {add && (
                <Button label="invoice.info.addRow" onClick={add} noMargin />
              )}
            </div>
          )}
        />
      </div>

      <div className={styles.formSection}>
        <h2>{loc$('invoice.productsAndExpensesBilledFromCustomer')}</h2>
        <InputRepeater
          name="product"
          className={styles.products}
          renderInput={(name, { del, moveUp, moveDown }, idx) => (
            <FormField key={name} name={name} className={styles.flexRow}>
              <div className={styles.repeaterNumber}>{idx + 1}.</div>
              <FormField name="title" className={styles.flexCol}>
                <TextInput label="invoice.info.productTitle" />
              </FormField>
              <FormField name="price" className={styles.flexCol}>
                <TextInput label="invoice.info.productPrice" />
              </FormField>
              <FormField name="amount" className={styles.flexCol}>
                <TextInput label="invoice.info.productAmount" />
              </FormField>
              <FormField name="vat" className={styles.flexColSmall}>
                <Select
                  label="invoice.info.productVAT"
                  options={['', '0,00', '25,50']}
                />
              </FormField>
              <FormField name="sort" className={styles.hidden}>
                <HiddenInput value={idx} />
              </FormField>
              {moveUp || moveDown ? (
                <div className={cx([styles.controls, styles.orderControls])}>
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderUp,
                      moveUp ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveUp}
                  />
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderDown,
                      moveDown ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveDown}
                  />
                </div>
              ) : null}
              {del && (
                <div className={cx([styles.flexCol, styles.controls])}>
                  <Button
                    className={styles.removeInput}
                    label="invoice.info.deleteRow"
                    onClick={del}
                    theme="transparent"
                  />
                </div>
              )}
            </FormField>
          )}
          renderFooter={add => (
            <div>
              <div className={styles.totalsDescription}>
                <div>
                  {loc$('invoice.info.productsTotal')}:{' '}
                  {currency$(productsTotal$)}
                </div>
                <div>
                  {loc$('invoice.info.productsTotalWithTaxes')}:{' '}
                  {currency$(productsTotalWithTaxes$)}
                </div>
              </div>
              {add && (
                <Button label="invoice.info.addRow" onClick={add} noMargin />
              )}
            </div>
          )}
        />

        <div className={styles.inputDescription} style={{ marginTop: 10 }}>
          {loc$('invoice.formInstructionsTaxation')}
        </div>
      </div>

      <div className={styles.formSection}>
        <h2>{loc$('invoice.expensesNotBilledFromCustomer')}</h2>
        <div className={styles.inputDescription}>
          {replaceLink(
            loc$('invoice.expensesNotBilledFromCustomerDescription'),
            U.string`/static/files/templates/travel_expense_${lang$}.xlsx`,
            true
          )}
        </div>

        <InputRepeater
          name="expense"
          className={styles.expenses}
          renderInput={(name, { del, moveUp, moveDown }, idx) => (
            <FormField key={name} name={name} className={styles.flexRow}>
              <FormField name="title" className={styles.flexCol}>
                <TextInput label="invoice.info.expenseTitle" />
              </FormField>
              <FormField name="expense" className={styles.flexCol}>
                <TextInput label="invoice.info.expenseAmount" />
              </FormField>
              <FormField name="receipt" className={styles.receiptCol}>
                <FileUploadInput
                  label="invoice.info.receipt"
                  folder="receipt"
                  extensions={['jpg', 'jpeg', 'png', 'pdf']}
                  uploadAsUser={uploadFilesAsUser}
                />
              </FormField>
              <FormField name="sort" className={styles.hidden}>
                <HiddenInput value={idx} />
              </FormField>
              {moveUp || moveDown ? (
                <div className={cx([styles.controls, styles.orderControls])}>
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderUp,
                      moveUp ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveUp}
                  />
                  <Button
                    className={cx([
                      styles.reorder,
                      styles.reorderDown,
                      moveDown ? styles.enabled : styles.disabled,
                    ])}
                    onClick={moveDown}
                  />
                </div>
              ) : null}
              {del && (
                <div className={cx([styles.flexCol, styles.controls])}>
                  <Button
                    className={styles.removeInput}
                    label="invoice.info.deleteRow"
                    onClick={del}
                    theme="transparent"
                  />
                </div>
              )}
            </FormField>
          )}
          renderFooter={add => (
            <div>
              <div className={styles.totalsDescription}>
                <div>
                  {loc$('invoice.info.expensesTotal')}:{' '}
                  {currency$(expensesTotal$)}
                </div>
              </div>
              {add && (
                <Button label="invoice.info.addRow" onClick={add} noMargin />
              )}
            </div>
          )}
        />
      </div>

      {U.ift(
        U.not(disabled),
        <FormSubmit className={styles.submitButton}>
          <Button label={submitLabel} theme="blue" large />
        </FormSubmit>
      )}

      {U.ift(
        saveDraftEnabled,
        <Button
          onClick={onSaveDraft}
          label={saveDraftLabel}
          theme="blue"
          large
        />
      )}

      {U.ift(
        U.not(U.isNil(onChange)),
        U.seq(formUpdated$, U.lift1(onChange), U.sink)
      )}
    </Form>
  )
}
