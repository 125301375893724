import React from 'karet'
import { classes, ifte } from 'karet.util'

export default ({
  className,
  value,
  onChange,
  setFocusableInput
}) => (
  <div {...classes(className)}>
    {ifte(value,
      <input
        ref={setFocusableInput}
        type="checkbox"
        checked={true}
        onChange={() => onChange(false)}
      />,
      <input
        ref={setFocusableInput}
        type="checkbox"
        checked={false}
        onChange={() => onChange(true)}
      />
    )}
  </div>
)
