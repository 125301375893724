import Decimal from 'decimal.js'

// 25.12% TYEL + 0.25% hoitokulumaksu
const TYEL_TOTAL_PERCENTAGE = '25.28' // Updated from 25.37% to 25.28% for 2025. The 2024 one included hoitokulumaksu (0.25%), which is not included in 25.28%.

const SOTU_MIN_AGE = 16
const SOTU_MAX_AGE = 67

const TYEL_MIN_AGE = 17
const TYEL_MAX_AGE = 67

const TVM_MIN_AGE = 17
const TVM_MAX_AGE = 64

// TODO: "Alle 16-vuotiaiden ja 68 vuotta täyttäneiden palkat on vapautettu maksusta."
const EMPLOYER_SOTU_PERCENTAGE = '1.87'
const EMPLOYER_TVM_PERCENTAGE_17_TO_64 = '0.20'
const EMPLOYER_TAPATURMA_VAKUUTUS_PERCENTAGE = '0.263'
const EMPLOYER_RYHMAHENKI_VAKUUTUS_PERCENTAGE = '0.06'

const EMPLOYEE_TYEL_PERCENTAGE_17_TO_52 = '7.15'
const EMPLOYEE_TYEL_PERCENTAGE_53_TO_62 = '8.65'
const EMPLOYEE_TYEL_PERCENTAGE_63_TO_67 = '7.15'
const EMPLOYEE_TVM_PERCENTAGE_17_TO_64 = '0.59'

const getEmployeeTyelPercentage = ageInYears => {
  if (ageInYears < 53) {
    return EMPLOYEE_TYEL_PERCENTAGE_17_TO_52
  }
  if (ageInYears < 63) {
    return EMPLOYEE_TYEL_PERCENTAGE_53_TO_62
  }
  if (ageInYears < 68) {
    return EMPLOYEE_TYEL_PERCENTAGE_63_TO_67
  }
}

export default function(
  withdrawAmount,
  reimbursements,
  taxPercentage,
  ageInYears
) {
  const taxFreeAmount = Decimal.min(withdrawAmount, reimbursements)
  const taxableAmount = new Decimal(withdrawAmount).sub(taxFreeAmount)

  const needsToPaySotu =
    ageInYears >= SOTU_MIN_AGE && ageInYears <= SOTU_MAX_AGE
  const needsToPayTyel =
    ageInYears >= TYEL_MIN_AGE && ageInYears <= TYEL_MAX_AGE
  const needsToPayTvm = ageInYears >= TVM_MIN_AGE && ageInYears <= TVM_MAX_AGE

  const employerSotuPercentage = needsToPaySotu ? EMPLOYER_SOTU_PERCENTAGE : 0

  const employeeTyelPercentage = needsToPayTyel
    ? getEmployeeTyelPercentage(ageInYears)
    : 0
  const employerTyelPercentage = needsToPayTyel
    ? new Decimal(TYEL_TOTAL_PERCENTAGE).sub(employeeTyelPercentage)
    : 0

  const employeeTvmPercentage = needsToPayTvm
    ? EMPLOYEE_TVM_PERCENTAGE_17_TO_64
    : 0
  const employerTvmPercentage = needsToPayTvm
    ? EMPLOYER_TVM_PERCENTAGE_17_TO_64
    : 0

  const employerExpensesTotalPercentage = new Decimal(0)
    .add(employerSotuPercentage)
    .add(EMPLOYER_TAPATURMA_VAKUUTUS_PERCENTAGE)
    .add(EMPLOYER_RYHMAHENKI_VAKUUTUS_PERCENTAGE)
    .add(employerTyelPercentage)
    .add(employerTvmPercentage)
    .div(100)

  // the double decimal is for rounding the gross wage to 2 decimals
  const grossWage = new Decimal(
    new Decimal(taxableAmount)
      .div(new Decimal(1).add(employerExpensesTotalPercentage))
      .toFixed(2)
  )
  const grossWageContractWorkShare = grossWage.div('1.115')
  const grossWageVacationCompensationShare = grossWage.sub(
    grossWageContractWorkShare
  )

  const calculateExpense = percentage => ({
    percentage: new Decimal(percentage).toFixed(2),
    amount: grossWage.mul(new Decimal(percentage).div(100)).toFixed(2),
  })

  const employerExpenses = {
    sotu: calculateExpense(employerSotuPercentage),
    tyel: calculateExpense(employerTyelPercentage),
    tvm: calculateExpense(employerTvmPercentage),
    tapaturmavakuutus: calculateExpense(EMPLOYER_TAPATURMA_VAKUUTUS_PERCENTAGE),
    ryhmahenkivakuutus: calculateExpense(
      EMPLOYER_RYHMAHENKI_VAKUUTUS_PERCENTAGE
    ),
  }

  const employeeExpenses = {
    ennakonpidatys: calculateExpense(taxPercentage),
    tyel: calculateExpense(employeeTyelPercentage),
    tvm: calculateExpense(employeeTvmPercentage),
  }

  const employeeExpensesTotalAmount = new Decimal(0)
    .add(employeeExpenses.ennakonpidatys.amount)
    .add(employeeExpenses.tyel.amount)
    .add(employeeExpenses.tvm.amount)

  const netWage = grossWage.sub(employeeExpensesTotalAmount)
  const toBePaid = netWage.add(taxFreeAmount)

  return {
    withdrawAmount: new Decimal(withdrawAmount).toFixed(2),
    taxableAmount: taxableAmount.toFixed(2),
    taxFreeAmount: taxFreeAmount.toFixed(2),
    grossWage: grossWage.toFixed(2),
    grossWageContractWorkShare: grossWageContractWorkShare.toFixed(2),
    grossWageVacationCompensationShare: grossWageVacationCompensationShare.toFixed(
      2
    ),
    netWage: netWage.toFixed(2),
    toBePaid: toBePaid.toFixed(2),
    employerExpenses,
    employeeExpenses,
  }
}
