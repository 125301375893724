import React from 'karet'
import moment from 'moment-timezone'
import styles from './Footer.css'

const currentYear = moment().tz('Europe/Helsinki').format('Y')

export default () => (
  <div className={styles.footer}>
    <div className={styles.footerContent}>
      &copy; Mediakunta Osk 2016-{currentYear}
    </div>
  </div>
)
