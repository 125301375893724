export default [
  {
    isIndex: true,
    path: '/contract',
    localizationKey: 'contracts.contractList'
  },
  {
    path: '/contract/new',
    localizationKey: 'contracts.newContract'
  }
]
