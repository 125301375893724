import React from 'karet'
import * as U from 'karet.util'
import styles from './Table.css'
import { classNames as cx } from 'utils'
import { loc$ } from 'utils/i18n'

export default ({
  onView,
  onEdit,
  onDelete,
  viewEnabled = true,
  editEnabled = true,
  deleteEnabled = true
}) => (
  <div className={styles.controls}>
    <div {...U.classes(styles.controlsHandle, 'icon-cog')} />
    <div className={styles.controlContainer}>
      {U.ift(U.and(viewEnabled, onView),     <ViewControl onView={onView} />)}
      {U.ift(U.and(editEnabled, onEdit),     <EditControl onEdit={onEdit} />)}
      {U.ift(U.and(deleteEnabled, onDelete), <DeleteControl onDelete={onDelete} />)}
    </div>
  </div>
)

const ViewControl = ({ onView }) => {
  const classes = cx([styles.control, styles.view])
  return (
    <a className={classes} onClick={onView}>{loc$('controls.view')}</a>
  )
}

const EditControl = ({ onEdit }) => {
  const classes = cx([styles.control, styles.edit])
  return (
    <a className={classes} onClick={onEdit}>{loc$('controls.edit')}</a>
  )
}

const DeleteControl = ({ onDelete }) => {
  const classes = cx([styles.control, styles.delete])
  return (
    <a className={classes} onClick={onDelete}>{loc$('controls.delete')}</a>
  )
}
