import Promise from 'bluebird'
import validatorFormatter from './validatorFormatter'

export default function(...validators) {
  return async (val, context) => {
    for (let i = 0; i < validators.length; ++i) {
      const [ validateFn, reason, params ] = validators[i]
      const valid = await Promise.resolve(validateFn(val, context))
      if (!valid) {
        return validatorFormatter(false, reason, params)
      }
    }
    return validatorFormatter(true)
  }
}
