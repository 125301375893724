import React from 'karet'
import { DateInput } from 'components'
import * as L from 'partial.lenses'
import K, * as U from 'karet.util'
import styles from './DateRange.css'

export default ({ value, valid, disabled, onChange, setFocusableInput }) => {
  return (
    <div className={styles.daterange}>
      <DateInput
        value={U.nth(0, value)}
        valid={valid}
        disabled={disabled}
        onChange={K(value, dateRange => date => onChange(L.set(0, date, dateRange)))}
        setFocusableInput={setFocusableInput}
        className={styles.startDate}
      />
      <div className={styles.separator}>-</div>
      <DateInput
        value={U.nth(1, value)}
        valid={valid}
        disabled={disabled}
        onChange={K(value, dateRange => date => onChange(L.set(1, date, dateRange)))}
        className={styles.endDate}
      />
    </div>
  )
}
