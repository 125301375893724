import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import * as L from 'partial.lenses'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { loc, loc$, date$ } from 'utils/i18n'
import { Link, Table, TableControls, ButtonGroup, Button } from 'components'
import { sortedBy$, sortTable } from './contractsViewStore'
import { addToast } from 'stores/toasts'
import { createAction } from 'utils/store'
import { isAdmin$ } from 'stores/user'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import styles from './Contracts.css'

const [ deleteContract, deleteContract$ ] = createAction(id => showApiRequestError(async () => {
  if (confirm(loc('contracts.confirmDelete'))) {
    await api.deleteContract(id)
    addToast('success', 'contracts.deleteSuccess')
    return id
  }
}))

export default waitForPromises(() => ({
  contracts: api.getContracts(),
  isAdmin: isAdmin$.take(1).toPromise()
}), ({ contracts, isAdmin }) => {
  const tableFields = R.reject(R.isNil, [
    isAdmin
      ? {
        title: 'contracts.info.user',
        key: 'userName',
        render: ({ userName, userId }) => <Link to={`/user/overview/${userId}`}>{userName}</Link>
      }
      : undefined,
    {
      title: 'contracts.info.description',
      key: 'description'
    },
    {
      title: 'contracts.info.file',
      key: 'upload.name',
      render: ({ upload }) => <a href={upload.url} target="_blank">{upload.name}</a>
    },
    {
      title: 'contracts.info.created',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id }) => (
        <TableControls
          onEdit={() => navigateTo(`/contract/edit/${id}`)}
          onDelete={() => deleteContract(id)}
        />
      ),
      className: styles.controls
    }
  ])

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction})
  }

  const contracts$ = deleteContract$.filter(Boolean).ignoreErrors().scan(
    (contracts, id) => L.remove([L.required([]), L.find(R.whereEq({ id }))], contracts),
    contracts
  )

  const sortedFilteredContracts$ = Kefir.combine(
    [contracts$, sortedBy$],
    (contracts, sortedBy) => {
      const directionFn = sortedBy.direction === 'ASC' ? R.identity : R.reverse
      const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
      const toIntIfNumber = (val) => isNaN(val) ? val : parseInt(val)
      const sortBy = R.compose(directionFn, R.sortBy(R.compose(toLowerIfString, toIntIfNumber, R.prop(sortedBy.key))))

      return sortBy(contracts)
    }
  ).toProperty()

  return (
    <div className={styles.contractList}>
      <h1>{loc$('contracts.contractList')}</h1>
      {/*<div className={styles.filters}>
        TODO: free filtering for admins?        
      </div>*/}
      <Table
        fields={tableFields}
        sortedBy={sortedBy$}
        data={sortedFilteredContracts$}
        sortFn={sortFn}
      />

      <Button label="contracts.newContract" onClick={() => navigateTo('/contract/new')} large />
    </div>
  )
}, () => false)
