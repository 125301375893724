import React from 'karet'
import R from 'ramda'
import K from 'karet.util'
import Decimal from 'decimal.js'
import { getPayslip } from 'shared/utils'
import { loc$ } from 'utils/i18n'
import { createActionProperty } from 'utils/store'
import { decimalToLocalized, decimalFromLocalized } from 'transformers'
import { Payslip, TextInput } from 'components'
import { waitForPromises } from 'utils/containers'
import {
  balanceInfo$,
  balanceAfterFees$,
  age$,
  taxPercentage$,
} from 'stores/user'
import styles from './Withdraw.css'

// TODO: this is so ugly, should make waitForObservables or something
export default waitForPromises(() => ({
  balanceInfo: balanceInfo$.take(1).toPromise(),
  taxPercentage: taxPercentage$.take(1).toPromise(),
  age: age$.take(1).toPromise()
}), ({ balanceInfo, balanceAfterFees, age, taxPercentage }) => {
  const [ changeAmount, amountInput$ ] = createActionProperty(R.always(decimalToLocalized(balanceInfo.available.balance)))
  const [ changeReimbursements, reimbursementsInput$ ] = createActionProperty(R.always(decimalToLocalized(balanceInfo.available.reimbursements)))
  const [ changeTaxPercentage, taxPercentageInput$ ] = createActionProperty(R.always(taxPercentage))
  const [ changeAge, ageInput$ ] = createActionProperty(R.always((age || 18).toString()))

  const amount$ = amountInput$.map(it => {
    try {
      const d = new Decimal(decimalFromLocalized(it))
      return d.gte(0) ? d.toString() : null
    } catch (err) {
      return null
    }
  })
  const isValidAmount$ = amount$.map(Boolean)
  const validAmount$ = amount$.filter(Boolean)

  const reimbursements$ = K(reimbursementsInput$, validAmount$, (reimbursements, amount) => {
    try {
      const d = new Decimal(decimalFromLocalized(reimbursements))
      return d.gte(0) && d.lte(amount) ? d.toString() : null
    } catch (err) {
      return null
    }
  })
  const isValidReimbursements$ = reimbursements$.map(Boolean)
  const validReimbursements$ = reimbursements$.filter(Boolean)

  const taxPercentage$ = taxPercentageInput$.map(it => {
    try {
      const d = new Decimal(decimalFromLocalized(it))
      return d.gte(0) && d.lte(100) ? d.toString() : null
    } catch (err) {
      return null
    }
  })
  const isValidTaxPercentage$ = taxPercentage$.map(Boolean)
  const validTaxPercentage$ = taxPercentage$.filter(Boolean)

  const age$ = ageInput$.map(it => {
    try {
      if (!it || !it.match(/^\d+$/)) {
        return null
      }
      const d = Number(it)
      return d >= 0 ? d : null
    } catch (err) {
      return null
    }
  })
  const isValidAge$ = age$.map(Boolean)
  const validAge$ = age$.filter(Boolean)

  const payslip$ = K(validAmount$, validReimbursements$, validTaxPercentage$, validAge$, getPayslip)

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h1>{loc$('balanceWithdraw.payslipCalculator')}</h1>
          <p>{loc$('balanceWithdraw.payslipCalculatorExplanation')}</p>

          <div className={styles.inputWrapper}>
            <label>{loc$('balanceWithdraw.payslipCalculatorPayRequest')}</label>
            <div className={styles.amount}>
              <TextInput inputClassName={styles.input} value={amountInput$} onChange={changeAmount} valid={isValidAmount$} />
            </div>
          </div>

          <div className={styles.inputWrapper}>
            <label>{loc$('balanceWithdraw.payslipCalculatorTravelExpenses')}</label>
            <div className={styles.amount}>
              <TextInput inputClassName={styles.input} value={reimbursementsInput$} onChange={changeReimbursements} valid={isValidReimbursements$} />
            </div>
          </div>
          
          <div className={styles.inputWrapper}>
            <label>{loc$('balanceWithdraw.payslipCalculatorTaxPercentage')}</label>
            <div className={styles.percentage}>
              <TextInput inputClassName={styles.input} value={taxPercentageInput$} onChange={changeTaxPercentage} valid={isValidTaxPercentage$} />
            </div>
          </div>

          <div className={styles.inputWrapper}>
            <label>{loc$('balanceWithdraw.payslipCalculatorAge')}</label>
            <div className={styles.age}>
              <TextInput inputClassName={styles.input} value={ageInput$} onChange={changeAge} valid={isValidAge$} />
            </div>
            <div className={styles.ageDescription}>
              {loc$('balanceWithdraw.payslipCalculatorAgeDescription')}
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <Payslip payslip={payslip$} />
        </div>
      </div>
    </div>
  )
})
