import React from 'karet'
import UserForm from './UserForm.jsx'
import { loc$ } from 'utils/i18n'
import { addUser } from 'api'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'

const onSubmit = values => showApiRequestError(async () => {
  const { userId } = await addUser(values)
  addToast('success', 'user.creationSuccess')
  navigateTo(`/user/edit/${userId}`)
})

const generatePassword = length => Math.random().toString(36).slice(-length)

export default () => {
  return (
    <div>
      <h1>{loc$('user.newUser')}</h1>
      <UserForm
        onSubmit={onSubmit}
        submitLabel="user.form.newUserSubmit"
        editing={false}
        initialValues={{ password: generatePassword(8) }}
      />
    </div>
  )
}
