export default {
  fields: {
    jlMemberId: {
      required: true,
      validators: ['isString']
    },
    email: {
      required: true,
      validators: ['email', 'emailIsUnique']
    },
    firstName: {
      required: true,
      validators: [
        ['minLength', { length: 2 }]
      ]
    },
    lastName: {
      required: true,
      validators: [
        ['minLength', { length: 2 }]
      ]
    },
    ssn: {
      required: true,
      validators: ['validSSN']
    },
    phoneNumber: {
      required: true,
      validators: ['isString']
    },
    streetAddress: {
      required: true,
      validators: ['isString']
    },
    postalCode: {
      required: true,
      validators: ['isNumber']
    },
    postOffice: {
      required: true,
      validators: ['isString']
    },
    language: {
      required: true,
      validators: ['validLanguage']
    },
    verifyBelongToJournalistiliitto: {
      required: true,
      validators: ['isBoolean']
    },
    verifyMemberAgreement: {
      required: true,
      validators: ['isBoolean']
    },
    verifyCooperativeRules: {
      required: true,
      validators: ['isBoolean']
    }
  }
}
