import React from 'karet'
import * as U from 'karet.util'
import { loc$ } from 'utils/i18n'
import styles from './FilterTable.css'

export default ({ className, value, onChange, description }) => (
  <input
    {...U.classes(className, styles.input)}
    type="text"
    value={value}
    onChange={e => onChange(e.target.value)}
    placeholder={loc$(description)}
  />
)
