export default {
  fields: {
    email: {
      required: true,
      validators: ['email', 'emailIsUnique'],
    },
    password: {
      required: true,
      validators: ['password'],
    },
    firstName: {
      required: true,
      validators: [['minLength', { length: 2 }]],
    },
    lastName: {
      required: true,
      validators: [['minLength', { length: 2 }]],
    },
    ssn: {
      required: false,
      validators: ['validSSN'],
    },
    phoneNumber: {
      required: false,
      validators: ['isString'],
    },
    streetAddress: {
      required: false,
      validators: ['isString'],
    },
    postalCode: {
      required: false,
      validators: ['isNumber'],
    },
    postOffice: {
      required: false,
      validators: ['isString'],
    },
    bankIban: {
      required: false,
      validators: ['isString', 'validIban'],
    },
    bankBic: {
      required: ({ bankIban }) =>
        bankIban && !bankIban.toString().match(/^FI/i),
      validators: ['isString', 'validBic'],
    },
    introduction: {
      validators: ['isString'],
    },
    jlMemberId: {
      required: false,
      validators: ['isString'],
    },
    taxCard: {
      required: false,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb'],
      },
    },
    taxPercentage: {
      required: false,
      validators: [['isNumber', { allowDecimals: true }]],
      transformers: {
        load: ['decimalToLocalized'],
        save: ['decimalFromLocalized'],
        validate: ['decimalFromLocalized'],
      },
    },
    picture: {
      required: false,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb'],
      },
    },
    language: {
      required: true,
      validators: ['validLanguage'],
    },
    isAdmin: {
      required: false,
      validators: ['isBoolean'],
    },
    memberJoinedAt: {
      required: true,
      validators: ['validDate'],
      transformers: {
        load: ['dateFromDb'],
        save: ['dateToDb'],
        validate: ['dateToDb'],
      },
    },
    memberResignedAt: {
      required: false,
      validators: ['validDate'],
      transformers: {
        load: ['dateFromDb'],
        save: ['dateToDb'],
        validate: ['dateToDb'],
      },
    },
  },
}
