import React from 'karet'
import Kefir from 'kefir'
import R from 'ramda'
import * as L from 'partial.lenses'
import * as api from 'api'
import { loc, date$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import { addToast } from 'stores/toasts'
import { createAction } from 'utils/store'
import { showApiRequestError } from 'utils/errors'
import {
    Table,
    TableControls
} from 'components'
import styles from './Overview.css'

const [ deleteContract, deleteContract$ ] = createAction(id => showApiRequestError(async () => {
  if (confirm(loc('contracts.confirmDelete'))) {
    await api.deleteContract(id)
    addToast('success', 'contracts.deleteSuccess')
    return id
  }
}))

export default ({ contracts }) => {
  const tableFields = [
    {
      title: 'contracts.info.description',
      key: 'description'
    },
    {
      title: 'contracts.info.file',
      key: 'upload.name',
      render: ({ upload }) => <a href={upload.url} target="_blank">{upload.name}</a>
    },
    {
      title: 'contracts.info.created',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id }) => (
        <TableControls
          onEdit={() => navigateTo(`/contract/edit/${id}`)}
          onDelete={() => deleteContract(id)}
        />
      ),
      className: styles.controls
    }
  ]

  const [ sortTable, onTableSorted$ ] = createAction()

  const sortedBy$ = onTableSorted$.scan(
    (prev,next) => {
      const direction = (prev.key === next.key && prev.direction !== 'DESC') ? 'DESC' : 'ASC'
      return { key: next.key, direction }
    },
    {key: 'description', direction: 'ASC'}
  )

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction})
  }

  const contracts$ = deleteContract$.filter(Boolean).ignoreErrors().scan(
    (contracts, id) => L.remove([L.required([]), L.find(R.whereEq({ id }))], contracts),
    contracts
  )

  const sortedFilteredContracts$ = Kefir.combine(
    [contracts$, sortedBy$],
    (contracts, sortedBy) => {
      const directionFn = sortedBy.direction === 'ASC' ? R.identity : R.reverse
      const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
      const toIntIfNumber = (val) => isNaN(val) ? val : parseInt(val)
      const sortBy = R.compose(directionFn, R.sortBy(R.compose(toLowerIfString, toIntIfNumber, R.prop(sortedBy.key))))

      return sortBy(contracts)
    }
  ).toProperty()

  return (
    <Table
      fields={tableFields}
      sortedBy={sortedBy$}
      data={sortedFilteredContracts$}
      sortFn={sortFn} />
  )
}