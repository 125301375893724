import React, { fromKefir } from 'karet'
import * as U from 'karet.util'
import Navigation from './Navigation.jsx'
import Header from './Header.jsx'
import Content from './Content.jsx'
import Footer from './Footer.jsx'
import styles from './App.css'
import { isLoggedIn$ } from 'stores/user'
import { waitForPromises } from 'utils/containers'
import { Login } from 'views'

export default waitForPromises(() => ({
  isLoggedIn: isLoggedIn$.take(1).toPromise()
}), ({ main, subnavi }) => {
  return fromKefir(U.ifte(isLoggedIn$,
    (
      <div key="loggedIn">
        <Header />
        <Navigation styles={styles} />
        <Content subnavi={subnavi}>
          {main}
        </Content>
        <Footer />
      </div>
    ),
    (
      <div key="loggedOut">
        <Login />
      </div>
    )
  ))
})
