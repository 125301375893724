import React from 'karet'
import K from 'karet.util'
import { showApiRequestError } from 'utils/errors'
import { getReimbursement, getUser, editReimbursement } from 'api'
import { loc$ } from 'utils/i18n'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'
import { checkAccess, waitForPromises } from 'utils/containers'
import { isAdmin } from 'utils/acl'
import ReimbursementForm from '../Reimbursements/ReimbursementForm.jsx'
import Decimal from 'decimal.js'

export default checkAccess(isAdmin, waitForPromises(
  ({ params: { reimbursementId }}) => ({ reimbursement: getReimbursement(reimbursementId) }),
  waitForPromises(
    ({ reimbursement }) => ({ user: getUser(reimbursement.userId) }),
    ({ reimbursement, user }) => {
      const onSubmit = values => showApiRequestError(async () => {
        await editReimbursement(reimbursement.id, values)
        addToast('success', 'reimbursements.editSuccess')
        navigateTo('/reimbursement')
      })

      // we need to add the current reimbursement total to the balance so the validation will pass
      const balanceAfterFees = new Decimal(user.balanceAfterFees).add(reimbursement.total).toString()

      return (
        <div>
          <h1>{loc$('reimbursements.editReimbursementHeader', { id: reimbursement.id })}</h1>
          <ReimbursementForm
            initialValues={reimbursement}
            submitLabel="reimbursements.editReimbursement"
            onSubmit={onSubmit}
            context={{ balanceAfterFees }}
            uploadFilesAsUser={user.id}
          />
        </div>
      )
    }
  )
))
