import React from 'karet'
import { loc$ } from 'utils/i18n'
import * as api from 'api'
import { addToast } from 'stores/toasts'
import { waitForPromises } from 'utils/containers'
import { showApiRequestError } from 'utils/errors'
import { checkAccess } from 'utils/containers'
import { navigateTo } from 'stores/navigation'
import { isAdmin } from 'utils/acl'
import ContractForm from './ContractForm.jsx'

const onSubmit = contractId => values =>
  showApiRequestError(async () => {
    await api.editContract(contractId, values)
    addToast('success', 'contracts.editSuccess')
    navigateTo('/contract')
  })

export default waitForPromises(
  ({ params: { contractId } }) => ({ contract: api.getContract(contractId) }),
  ({ contract }) => (
    <div>
      <h1>{loc$('contracts.editContract')}</h1>
      <ContractForm
        initialValues={contract}
        onSubmit={onSubmit(contract.id)}
        submitLabel="contracts.saveContract"
        uploadFilesAsUser={contract.userId}
      />
    </div>
  )
)
