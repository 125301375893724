import React from 'karet'

export default function() {
  return (
    <div>
      <h1>VÄLKOMMEN TILL MEDIELAGETS ELEKTRONISKA MEDLEMSTJÄNST!</h1>

      <p>
        Välkommen till Medielagets interna medlemstjänst. Det här är
        andelslagets kärna där du bland annat sköter fakturering och flyttar lön
        till ditt eget konto. Alla ärenden som berör Medielaget sköts via denna
        tjänst – du kan inte sköta ärenden per e-post eller telefon.
      </p>

      <p>Så här fungerar tjänsten:</p>
      <ol>
        <li>
          Fyll i din profil noggrant. Granska bland annat att din e-post är
          korrekt och att du bifogat ditt skattekort.
        </li>
        <li>Kom överens med din kund om uppdraget.</li>
        <li>
          Gör jobbet och skapa en nya faktura via den elektroniska
          medlemstjänsten.
        </li>
        <li>
          Medielaget skickar en faktura för ditt utförda jobb till kunden.
        </li>
        <li>Kunden betalar fakturan.</li>
        <li>Du får arbetsavtalet till din e-post.</li>
        <li>Medielaget betalar lönen in på ditt konto när du så önskar.</li>
      </ol>

      <p>
        Mer detaljerade anvisningar om hur tjänsten används får du när du
        tillämpar dess funktioner. Ifall det uppstår problem kan du kontakta
        Medielaget på{' '}
        <a href="mailto:mediakunta@mediakunta.fi">mediakunta@mediakunta.fi</a>.
      </p>

      <p>
        Vill du skicka respons?{' '}
        <a href="http://mediakunta.fi/ota-yhteytta/">Kontakta oss</a>.
      </p>
    </div>
  )
}
