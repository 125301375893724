import R from 'ramda'
import Kefir from 'kefir'
import * as L from 'partial.lenses'
import { createAction, persistentProperty } from 'utils/store'
import { calculateAgeForTaxingFromSsn } from 'shared/utils'
import * as api from 'api'

const DEFAULT_LANGUAGE = 'fi'

function getStoredLocalLanguage() {
  if (window.localStorage) {
    try {
      const selectedLanguage = window.localStorage.getItem('selectedLanguage')
      if (selectedLanguage) {
        return selectedLanguage
      }
    } catch (err) {}
  }
  return DEFAULT_LANGUAGE
}

function storeLanguageLocally(lang) {
  if (window.localStorage) {
    try {
      window.localStorage.setItem('selectedLanguage', lang)
    } catch (err) {}
  }
  return lang
}

const [ login, onLogin ] = createAction((email, password) => api.login(email, password).then(() => api.getProfile()))
const [ logout, onLogout ] = createAction(() => api.logout())
const [ changeLanguage, onChangeLanguage$ ] = createAction(lang => api.changeLanguage(lang).then(R.always(lang)))
const [ changeLanguageLocally, onChangeLanguageLocally$ ] = createAction(storeLanguageLocally)
const [ updateProfile, updateProfile$ ] = createAction(profile => api.updateProfile(profile).then(() => api.getProfile()))
const [ changeProfilePic, changeProfilePic$ ] = createAction()
const [ withdrawBalance, withdrawBalance$ ] = createAction(amount => api.withdrawBalance(amount))
const [ addReimbursement, addReimbursement$ ] = createAction(data => api.addReimbursement(data))

const me$ = persistentProperty(
  Kefir.fromPromise(api.getProfile())
    .flatMapErrors(R.always(Kefir.constant(null)))
    .merge(updateProfile$)
    .merge(withdrawBalance$)
    .merge(addReimbursement$)
    .merge(onLogin)
    .merge(onLogout.map(R.always(null)))
    .scan((prev, next) => next && R.merge(prev, next), null)
    .ignoreErrors())

const isLoggedIn$ = me$.map(Boolean)

const lang$ = persistentProperty(
  me$
    .map(me => me
      ? me.language
      : getStoredLocalLanguage())
    .merge(onChangeLanguage$)
    .merge(onChangeLanguageLocally$),
  DEFAULT_LANGUAGE)

const isAdmin$ = me$.map(L.get('isAdmin')).map(Boolean)

const balanceAfterFees$ = me$.map(L.get('balanceAfterFees'))
const reservedBalance$ = me$.map(L.get('reservedBalance'))
const reimbursementsBalance$ = me$.map(L.get('reimbursements'))
const balanceInfo$ = me$.map(L.get('balanceInfo'))

const profilePicUrl$ = persistentProperty(
  me$
    .map(L.get(['picture', 'url']))
    .map(R.defaultTo('/static/img/profile-placeholder.png'))
    .merge(changeProfilePic$.map(L.get('url'))))

const hasTaxCard$ = me$.map(L.get('taxCard')).map(Boolean)
const hasTaxPercentage$ = me$.map(L.get('taxPercentage')).map(Boolean)

const ssn$ = me$.map(L.get('ssn'))
const age$ = ssn$.map(calculateAgeForTaxingFromSsn)

const taxPercentage$ = me$.map(L.get('taxPercentage'))
const isTaxCardChecked$ = taxPercentage$.map(Boolean)

const totalBilling$ = me$.map(L.get('totalBilling'))

export {
  login,
  logout,
  changeLanguage,
  changeLanguageLocally,
  updateProfile,
  changeProfilePic,
  me$,
  isLoggedIn$,
  lang$,
  isAdmin$,
  profilePicUrl$,
  balanceAfterFees$,
  reservedBalance$,
  reimbursementsBalance$,
  balanceInfo$,
  withdrawBalance,
  withdrawBalance$,
  addReimbursement,
  hasTaxCard$,
  hasTaxPercentage$,
  age$,
  taxPercentage$,
  isTaxCardChecked$,
  totalBilling$
}
