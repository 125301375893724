import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import * as L from 'partial.lenses'
import K, * as U from 'karet.util'
import { createAction, persistentProperty } from 'utils/store'

export default React.createClass({
  getInitialState() {
    const { initialItems, minItems = 0 } = this.props

    const [ unmount, unmount$ ] = createAction()
    const [ addRow, addRow$ ] = createAction()
    const [ delRow, delRow$ ] = createAction()
    const [ move, move$ ] = createAction()

    const initialItems$ = U.toProperty(U.times(R.identity, R.max(initialItems, minItems)))
    const addRowWithIdx$ =
      initialItems$
        .map(R.length)
        .flatMap(length =>
          addRow$
            .scan(R.inc, length-1)
            .changes())

    const items$ =
      persistentProperty(
        Kefir.merge([
          initialItems$.map(initialItems => R.always(initialItems)),
          move$.map(item => items => {
            const { dir, val } = item
            const offset = dir === 'up' ? -1 : 1
            const idx = R.indexOf(val, items)

            if(idx + offset < 0 || idx + offset > items.length - 1)
              return items

            const orderedItems = R.pipe(
              R.update(idx,          items[idx + offset]),
              R.update(idx + offset, items[idx])
            )(items)
            return orderedItems
          }),
          addRowWithIdx$.map(idx => items => items.concat(idx)),
          delRow$.map(idx => L.remove([L.required([]), L.find(R.equals(idx))]))
        ])
        .scan((items, updateF) => updateF(items), [])
        .takeUntilBy(unmount$)
      )

    return { items$, addRow, delRow, move, unmount }
  },
  componentWillUnmount() {
    this.state.unmount()
  },
  render() {
    const { className, renderRow, renderFooter, minItems = 0, disabled = false } = this.props
    const { items$, addRow, delRow, move } = this.state

    return (
      <div {...U.classes(className)}>
        {K(items$, disabled, (items, disabled) => items.map((idx, counter) => {
          const del = (R.length(items) > minItems)
            ? () => delRow(idx)
            : undefined
          const moveUp = (R.length(items) > 1 && counter !== 0)
            ? () => move({ dir: 'up', val: idx })
            : undefined
          const moveDown = (R.length(items) > 1 && (counter + 1) !== R.length(items))
            ? () => move({ dir: 'down', val: idx })
            : undefined
          return renderRow(idx, disabled ? {} : { del, moveUp, moveDown }, counter)
        }))}
        {U.ifte(U.not(disabled),
          renderFooter(addRow),
          renderFooter())}
      </div>
    )
  }
})
