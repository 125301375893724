import React from 'karet'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { loc$, currency$ } from 'utils/i18n'
import styles from './Invoices.css'

export default waitForPromises(() => ({
  summary: api.getInvoicesSummary()
}), ({ summary }) => {
  return (
    <div className={styles.invoicesSummary}>
      {summary.map(({year, details, totals}) => (
        <div className={styles.summaryYear} key={year}>
          <h2>{year}</h2>
          <table>
            <thead>
              <tr>
                <th>{loc$('invoice.summary.month')}</th>
                <td>{loc$('invoice.summary.billed')}</td>
                <td>{loc$('invoice.summary.billedSum')}</td>
                <td>{loc$('invoice.summary.billedSumVat')}</td>
                <td>{loc$('invoice.summary.paid')}</td>
                <td>{loc$('invoice.summary.paidSum')}</td>
                <td>{loc$('invoice.summary.paidSumVat')}</td>
              </tr>
            </thead>
            <tbody>
              {details.map(({month, billed, paid}) => (
                <tr key={month}>
                  <th>{month}</th>
                  <td>{billed.invoices}</td>
                  <td>{currency$(billed.sumWithoutVat)}</td>
                  <td>{currency$(billed.sumWithVat)}</td>
                  <td>{paid.invoices}</td>
                  <td>{currency$(paid.sumWithoutVat)}</td>
                  <td>{currency$(paid.sumWithVat)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>{loc$('invoice.summary.total')}</th>
                <td>{totals.billed.invoices}</td>
                <td>{currency$(totals.billed.sumWithoutVat)}</td>
                <td>{currency$(totals.billed.sumWithVat)}</td>
                <td>{totals.paid.invoices}</td>
                <td>{currency$(totals.paid.sumWithoutVat)}</td>
                <td>{currency$(totals.paid.sumWithVat)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      ))}
    </div>
  )
})


