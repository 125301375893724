import R from 'ramda'
import Promise from 'bluebird'
import validateField from './validateField'

function validateSchema(value, schema, context, data = null, fieldName = '') {
  if (schema.isArray) {
    value = value !== undefined ? value : []
    if (!R.is(Object, value)) {
      return {
        valid: false,
        errors: {
          [fieldName]: [{ reason: 'NOT_ARRAY' }]
        }
      }
    } else if (value.length === 0 && schema.required) {
      return {
        valid: false,
        errors: {
          [fieldName]: [{ reason: 'REQUIRED' }]
        }
      }
    }
  }

  let valuePromises = []
  if (schema.validators) {
    if (schema.isArray) {
      valuePromises.push(...value.map((value, idx) => {
        const field = `${fieldName}[${idx}]`
        return validateField(value, schema, context, data)
          .then(validity => ({ field, validity }))
      }))
    } else {
      valuePromises.push(
        validateField(value, schema, context, data)
          .then(validity => ({ field: fieldName, validity }))
      )
    }
  }

  const fieldsNotInSchemaErrors = (value, parentField) =>
    Object.keys(value).reduce((errors, field) =>
      schema.fields[field]
        ? errors
        : (
          errors.concat({
            valid: false,
            errors: {
              [`${parentField}[${field}]`]: [{ reason: 'UNKNOWN_FIELD' }]
            }
          })
        ),
      [])

  let fieldPromises = []
  if (schema.fields) {
    if (schema.isArray) {
      value.forEach((value, idx) => {
        Object.keys(schema.fields).forEach(field => {
          fieldPromises.push(validateSchema(
            value[field],
            schema.fields[field],
            context,
            data || value,
            `${fieldName}[${idx}][${field}]`)
          )
        })
        fieldPromises.push(...fieldsNotInSchemaErrors(value, `${fieldName}[${idx}]`))
      })
    } else {
      Object.keys(schema.fields).forEach(field => {
        fieldPromises.push(validateSchema(
          value[field],
          schema.fields[field],
          context,
          data || value,
          fieldName ? `${fieldName}[${field}]` : field
        ))
      })
      fieldPromises.push(...fieldsNotInSchemaErrors(value, fieldName))
    }
  }

  const result = Promise.reduce(valuePromises, (result, { field, validity }) => {
    if (validity.valid) {
      return result
    } else {
      return {
        valid: false,
        errors: {
          ...result.errors,
          [field]: validity.errors
        }
      }
    }
  }, { valid: true, errors: {} })

  return Promise.reduce(fieldPromises, (result, { valid, errors }) => {
    if (valid) {
      return result
    } else {
      return {
        valid: false,
        errors: {
          ...result.errors,
          ...errors
        }
      }
    }
  }, result)
}

export default validateSchema
