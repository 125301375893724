import React from 'karet'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, Checkbox } from 'utils/forms/controls'
import { withUnmount$, waitForPromises } from 'utils/containers'
import { createActionProperty } from 'utils/store'
import { replaceLink } from 'utils/misc'
import * as schema from 'shared/schema'
import * as joinPayment from 'stores/join-payment'

const JOURNALISTILIITTO_JOIN_LINK = 'https://journalistiliitto.fi/fi/jasenyys/lomakkeet/'

export default withUnmount$(waitForPromises(
  () => ({
    initialValues: joinPayment.unvalidatedPersonalData$.take(1).toPromise()
  }),
  ({ initialValues, onSubmit, unmount$ }) => {
    const [ updateForm, form$ ] = createActionProperty()

    form$
      .takeUntilBy(unmount$)
      .onValue(values => joinPayment.updateUnvalidatedPersonalData(values))

    return (
      <div>
        <h1>{loc$('join.personalDataTitle')}</h1>
        <p className={styles.description}>
          {replaceLink(loc$('join.personalDataDescription'), JOURNALISTILIITTO_JOIN_LINK, false)}
        </p>
        <Form
          initialValues={initialValues}
          schema={schema.join}
          className={styles.form}
          onSubmit={onSubmit}
          onChange={updateForm}
        >
          <FormField name="jlMemberId">
            <TextInput label="user.info.jlMemberId" />
          </FormField>
          <FormField name="email">
            <TextInput label="user.info.email" autoComplete="off" />
          </FormField>
          <FormField name="firstName">
            <TextInput label="user.info.firstName" />
          </FormField>
          <FormField name="lastName">
            <TextInput label="user.info.lastName" />
          </FormField>
          <FormField name="ssn">
            <TextInput label="user.info.ssn" />
          </FormField>
          <FormField name="phoneNumber">
            <TextInput label="user.info.phoneNumber" />
          </FormField>
          <FormField name="streetAddress">
            <TextInput label="user.info.streetAddress" />
          </FormField>
          <FormField name="postalCode">
            <TextInput label="user.info.postalCode" />
          </FormField>
          <FormField name="postOffice">
            <TextInput label="user.info.postOffice" />
          </FormField>
          <FormField name="verifyBelongToJournalistiliitto">
            <Checkbox label="join.verifyBelongToJournalistiliitto" />
          </FormField>
          <FormField name="verifyMemberAgreement">
            <Checkbox
              label="join.verifyMemberAgreement"
              externalLink="/static/files/jasensopimus.pdf"
            />
          </FormField>
          <FormField name="verifyCooperativeRules">
            <Checkbox
              label="join.verifyCooperativeRules"
              externalLink="/static/files/saannot.pdf"
            />
          </FormField>
          <FormSubmit>
            <Button label="join.continueToPay" large />
          </FormSubmit>
        </Form>
      </div>
    )
  })
)
