export default {
  fields: {
    title: {
      required: true,
      validators: ['isString']
    },
    total: {
      required: true,
      validators: [
        'price',
        ['validWithdraw', { minimumWithdraw: 1 }]
      ],
      transformers: {
        load: ['decimalToLocalized'],
        save: ['decimalFromLocalized'],
        validate: ['decimalFromLocalized']
      }
    },
    taxes: {
      required: true,
      validators: ['price'],
      transformers: {
        load: ['decimalToLocalized'],
        save: ['decimalFromLocalized'],
        validate: ['decimalFromLocalized']
      }
    },
    receipt: {
      required: true,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb']
      }
    },
    image: {
      required: false,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb']
      }
    }
  }
}
