import React from 'karet'
import K from 'karet.util'
import styles from './Join.css'
import { loc$ } from 'utils/i18n'
import JoinSteps from './JoinSteps.jsx'
import * as joinPayment from 'stores/join-payment'
import { Link, Button } from 'components'

export default ({ params: { transactionId }}) => {
  joinPayment.clearPayment()

  return (
    <div>
      <JoinSteps step="pay" />
      <h1>{loc$('join.errorTitle')}</h1>
      <p dangerouslySetInnerHTML={K(loc$('join.errorDescription', { transactionId }), __html => ({ __html }))} />
      <Link to="/join"><Button label="join.backToBeginningButton" /></Link>
    </div>
  )
}
