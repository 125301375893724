export default {
  fields: {
    description: {
      required: true,
      validators: ['isString']
    },
    upload: {
      required: true,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb']
      }
    }
  }
}
