import R from 'ramda'
import React from 'karet'
import { withFormAggregator } from 'utils/forms'
import { Context, classes } from 'karet.util'
import styles from './Form.css'

export default withFormAggregator(React.createClass({
  getContext() {
    const { formAggregator } = this.props
    const [ , baseName, idx ] = this.props.name.match(/^([a-zA-Z_]+)(?:\[(\d+)\])?/)
    const fieldName = formAggregator.name
      ? `${formAggregator.name}[${baseName}]` + (idx ? `[${idx}]` : '')
      : this.props.name
    const fieldSchema = R.path(['fields', baseName], formAggregator.schema) || {}

    return {
      form: formAggregator.form,
      schema: fieldSchema,
      name: fieldName
    }
  },
  render() {
    const { className, children } = this.props

    return (
      <Context context={{ formAggregator: this.getContext() }}>
        <div {...classes(styles.formField, className)}>
          {children}
        </div>
      </Context>
    )
  }
}))
