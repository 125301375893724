import React from 'karet'
import { loc$ } from 'utils/i18n'
import { getFile, editFile } from 'api'
import { addToast } from 'stores/toasts'
import { waitForPromises } from 'utils/containers'
import { showApiRequestError } from 'utils/errors'
import { checkAccess } from 'utils/containers'
import { navigateTo } from 'stores/navigation'
import { isAdmin } from 'utils/acl'
import FileForm from './FileForm.jsx'

const onSubmit = fileId => values => showApiRequestError(async () => {
  await editFile(fileId, values)
  addToast('success', 'files.editSuccess')
  navigateTo('/files')
})

export default checkAccess(isAdmin, waitForPromises(
  ({ params: { fileId }}) => ({ file: getFile(fileId) }),
  ({ file }) => (
    <div>
      <h1>{loc$('files.editFile')}</h1>
      <FileForm
        initialValues={file}
        onSubmit={onSubmit(file.id)}
        submitLabel="files.saveFile"
      />
    </div>
  )
))
