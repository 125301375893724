import React from 'karet'
import * as U from 'karet.util'
import { currency$, percentage$, loc$ } from 'utils/i18n'
import styles from './Invoices.css'

export default ({ invoice }) => (
  <div>
    <h2>{loc$('invoice.commissions.title')}</h2>
    <table className={styles.invoiceCommissions}>
      <thead>
        <tr>
          <th>{loc$('invoice.commissions.amount')}</th>
          <th>{loc$('invoice.commissions.commission')}</th>
          <th>{loc$('invoice.commissions.percentage')}</th>
          <th>{loc$('invoice.commissions.reason')}</th>
        </tr>
      </thead>
      <tbody>
        {U.seq(invoice, U.view('commission'), U.mapIndexed((entry, idx) => (
          <tr key={idx}>
            <td>{currency$(entry.amount)}</td>
            <td>{currency$(entry.commission)}</td>
            <td>{entry.percentage ? percentage$(entry.percentage) : '-'}</td>
            <td>{loc$(`invoice.commissions.reasons.${entry.reason}`)}</td>
          </tr>
        )))}
      </tbody>
    </table>
  </div>
)
