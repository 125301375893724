import React from 'karet'
import * as U from 'karet.util'
import { loc$ } from 'utils/i18n'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { addToast } from 'stores/toasts'
import { isAdmin$ } from 'stores/user'
import { replaceLink } from 'utils/misc'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import ContractForm from './ContractForm.jsx'
import { InlineNotification } from 'components'

export default waitForPromises(
  ({ params: { userId }}) => {
    if (userId) {
      return { user: api.getUser(userId) }
    } else {
      return {}
    }
  },
  ({ user }) => {
    const creatingInvoiceForAnotherUser = Boolean(user)

    const onSubmit = values => showApiRequestError(async () => {
      if (creatingInvoiceForAnotherUser) {
        await api.addContractForUser(user.id, values)
        addToast('success', 'contracts.saveSuccess')
        navigateTo(`/user/overview/${user.id}`)
      } else {
        await api.addContract(values)
        addToast('success', 'contracts.saveSuccess')
        navigateTo('/contract')
      }
    })

    return (
      <div>
        <h1>{
          U.ifte(creatingInvoiceForAnotherUser,
            loc$('contracts.newContractForUser', { name: `${U.view('firstName', user)} ${U.view('lastName', user)}` }),
            loc$('contracts.newContract'))
        }</h1>

        {U.ift(creatingInvoiceForAnotherUser, (
          <InlineNotification theme="yellow">
            <p>{replaceLink(
              loc$('contracts.creatingContractForUserNotification', { name: `${U.view('firstName', user)} ${U.view('lastName', user)}` }),
              `/user/overview/${U.view('id', user)}`,
              false
            )}</p>
          </InlineNotification>
        ))}

        {U.ifte(
          U.and(isAdmin$, U.not(creatingInvoiceForAnotherUser)),
          loc$('contracts.newContractFromUserPage'),
          (
            <ContractForm
              onSubmit={onSubmit}
              submitLabel="contracts.saveContract"
              uploadFilesAsUser={U.view('id', user)}
            />
          )
        )}
      </div>
    )
  }
)