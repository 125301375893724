import React from 'karet'
import Kefir from 'kefir'
import K, * as U from 'karet.util'
import * as api from 'api'
import { loc, loc$, datetime$ } from 'utils/i18n'
import { waitForPromises, withUnmount$ } from 'utils/containers'
import { Button, Link } from 'components'
import { isAdmin$ } from 'stores/user'
import { createAction, persistentProperty } from 'utils/store'
import { addToast } from 'stores/toasts'
import { showApiRequestError } from 'utils/errors'
import InvoiceForm from './InvoiceForm.jsx'
import InvoiceHistory from './InvoiceHistory.jsx'
import InvoiceCommissions from './InvoiceCommissions.jsx'
import styles from './Invoices.css'

export default waitForPromises(
  ({ params: { invoiceId } }) => ({ invoice: api.getInvoice(invoiceId) }),
  withUnmount$(({ invoice, unmount$ }) => {
    const [approveInvoice, approveInvoice$] = createAction(() =>
      api.approveInvoice(invoice.id).then(() => api.getInvoice(invoice.id))
    )
    const [rejectInvoice, rejectInvoice$] = createAction(reason =>
      api
        .rejectInvoice(invoice.id, reason)
        .then(() => api.getInvoice(invoice.id))
    )
    const [markAsBilled, markAsBilled$] = createAction(() =>
      api.markInvoiceAsBilled(invoice.id).then(() => api.getInvoice(invoice.id))
    )
    const [markAsPaid, markAsPaid$] = createAction(() =>
      api.markInvoiceAsPaid(invoice.id).then(() => api.getInvoice(invoice.id))
    )
    const [creditInvoice, creditInvoice$] = createAction(reason =>
      api
        .creditInvoice(invoice.id, reason)
        .then(() => api.getInvoice(invoice.id))
    )

    const invoice$ = persistentProperty(
      Kefir.merge([
        Kefir.constant(invoice),
        approveInvoice$.ignoreErrors(),
        rejectInvoice$.ignoreErrors(),
        markAsBilled$.ignoreErrors(),
        markAsPaid$.ignoreErrors(),
        creditInvoice$.ignoreErrors(),
      ]).takeUntilBy(unmount$)
    )

    const status$ = U.view('status', invoice$)
    const statusLoc$ = U.seq(status$, U.concat('invoice.status.'), loc$)

    const creditInvoiceId$ = U.view('creditInvoiceId', invoice$)
    const originalInvoiceId$ = U.view('originalInvoiceId', invoice$)
    const isCreditNote$ = U.view('isCreditNote', invoice$)

    // TODO: remove this later
    const hasBeenBilled$ = K(U.view('billedAt', invoice$), billedAt =>
      Boolean(billedAt)
    )

    const DownloadInvoice = () => (
      <a href={`/api/v1/invoice/${invoice.id}/pdf`} download>
        <Button
          className={styles.button}
          label="invoice.downloadPdf"
          theme="blue"
          noMargin
        />
      </a>
    )

    const InvoiceActions = () => (
      <div className={styles.controls}>
        <Link to={`/invoice/new/from-invoice/${invoice.id}`}>
          <Button
            className={styles.button}
            label="invoice.cloneInvoice"
            theme="blue"
            noMargin
          />
        </Link>
        <DownloadInvoice />
        <a href={`/api/v1/invoice/${invoice.id}/certificate-pdf`} download>
          <Button
            className={styles.button}
            label="invoice.downloadCertificatePdf"
            theme="blue"
            noMargin
          />
        </a>
        {U.ift(
          U.and(isAdmin$, U.equals(status$, 'new')),
          <Link to={`/invoice/edit/${invoice.id}`}>
            <Button
              className={styles.button}
              label="invoice.edit"
              theme="blue"
              noMargin
            />
          </Link>
        )}
        {U.ift(
          U.and(isAdmin$, U.equals(status$, 'new')),
          <Button
            onClick={() => {
              if (confirm(loc('invoice.confirmApprove'))) {
                showApiRequestError(async () => {
                  await approveInvoice()
                  addToast('success', 'invoice.approveSuccess')
                })
              }
            }}
            className={styles.button}
            label="invoice.approve"
            theme="green"
            noMargin
          />
        )}
        {U.ift(
          U.and(isAdmin$, U.equals(status$, 'approved')),
          <Button
            onClick={() => {
              if (confirm(loc('invoice.confirmMarkAsBilled'))) {
                showApiRequestError(async () => {
                  await markAsBilled()
                  addToast('success', 'invoice.markAsBilledSuccess')
                })
              }
            }}
            className={styles.button}
            label="invoice.markAsBilled"
            theme="green"
            noMargin
          />
        )}
        {U.ift(
          U.and(
            isAdmin$,
            U.or(
              U.equals(status$, 'billed'),
              U.equals(status$, 'paid_mismatch')
            )
          ),
          <Button
            onClick={() => {
              if (confirm(loc('invoice.confirmMarkAsPaid'))) {
                showApiRequestError(async () => {
                  await markAsPaid()
                  addToast('success', 'invoice.markAsPaidSuccess')
                })
              }
            }}
            className={styles.button}
            label="invoice.markAsPaid"
            theme="green"
            noMargin
          />
        )}
        {U.ift(
          U.and(isAdmin$, U.contains(status$, ['new', 'approved', 'billed'])),
          <Button
            onClick={() => {
              const reason = prompt(loc('invoice.confirmReject'))
              if (reason) {
                showApiRequestError(async () => {
                  await rejectInvoice(reason)
                  addToast('success', 'invoice.rejectSuccess')
                })
              }
            }}
            className={styles.button}
            label="invoice.reject"
            theme="red"
            noMargin
          />
        )}
        {U.ift(
          U.and(
            isAdmin$,
            // TODO: remove hasBeenBilled$ later
            U.or(
              U.and(U.equals(status$, 'rejected'), hasBeenBilled$),
              U.contains(status$, ['billed', 'paid'])
            )
          ),
          <Button
            onClick={() => {
              const reason = prompt(loc('invoice.confirmCredit'))
              if (reason) {
                showApiRequestError(async () => {
                  await creditInvoice(reason)
                  addToast('success', 'invoice.creditSuccess')
                })
              }
            }}
            className={styles.button}
            label="invoice.credit"
            theme="orange"
            noMargin
          />
        )}
      </div>
    )

    return (
      <div>
        <div className={styles.splitHeader}>
          <h1>
            {U.ifte(
              isCreditNote$,
              loc$('invoice.viewCreditInvoice', { id: invoice.id }),
              loc$('invoice.viewInvoice', { id: invoice.id })
            )}
          </h1>
          {U.ifte(
            U.not(isCreditNote$),
            <InvoiceActions />,
            <DownloadInvoice />
          )}
        </div>
        <table className={styles.infoTable}>
          <thead>
            <tr>
              <th style={{ width: '15%' }}>{loc$('invoice.info.status')}</th>
              {U.ift(
                isCreditNote$,
                <th style={{ width: '15%' }}>
                  {loc$('invoice.info.originalInvoice')}
                </th>
              )}
              <th style={{ width: '15%' }}>
                {loc$('invoice.info.referenceNumber')}
              </th>
              <th>{loc$('invoice.info.created')}</th>
              <th>{loc$('invoice.info.updated')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {U.ifte(
                  U.isNil(creditInvoiceId$),
                  statusLoc$,
                  <Link to={U.string`/invoice/view/${creditInvoiceId$}`}>
                    {statusLoc$}
                  </Link>
                )}
              </td>
              {U.ift(
                isCreditNote$,
                <td>
                  <Link to={U.string`/invoice/view/${originalInvoiceId$}`}>
                    #{originalInvoiceId$}
                  </Link>
                </td>
              )}
              <td>{U.seq(invoice$, U.view('referenceNumber'))}</td>
              <td>{U.seq(invoice$, U.view('createdAt'), datetime$)}</td>
              <td>{U.seq(invoice$, U.view('updatedAt'), datetime$)}</td>
            </tr>
          </tbody>
        </table>
        {U.ift(
          U.and(isAdmin$, U.not(U.isEmpty(U.view('commission', invoice$)))),
          <InvoiceCommissions invoice={invoice$} />
        )}
        <InvoiceHistory invoice={invoice$} />
        <InvoiceForm
          initialValues={invoice}
          invoiceId={invoice.id}
          disabled={true}
        />
      </div>
    )
  }),
  (oldProps, newProps) =>
    oldProps.params.invoiceId !== newProps.params.invoiceId
)
