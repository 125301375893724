import React from 'karet'
import Kefir from 'kefir'
import R from 'ramda'
import * as L from 'partial.lenses'
import K, * as U from 'karet.util'
import Decimal from 'decimal.js'
import { getPayslip, validateField } from 'shared/utils'
import { loc, loc$ } from 'utils/i18n'
import { createAction } from 'utils/store'
import { replaceLink } from 'utils/misc'
import { addToast } from 'stores/toasts'
import {
  balanceInfo$,
  balanceAfterFees$,
  isTaxCardChecked$,
  age$,
  taxPercentage$,
  withdrawBalance
} from 'stores/user'
import { decimalFromLocalized, decimalToLocalized } from 'transformers'
import { Button, InlineNotification, Payslip } from 'components'
import { Success } from 'icons'
import styles from './Withdraw.css'

const MINIMUM_WITHDRAWAL_AMOUNT = 1

const BalanceWithdraw = () => {
  const [ changeAmount, onChangeAmount$ ] = createAction()

  const showBalanceWithdrawnMessage = U.atom(false)

  const reimbursements$ = balanceInfo$.map(L.get(['available', 'reimbursements']))
  const amount$ = balanceAfterFees$
    .map(decimalToLocalized)
    .merge(
      onChangeAmount$
        .combine(balanceAfterFees$, (newAmount, balance) => {
          try {
            if (newAmount === '') {
              return newAmount
            }
            const unlocalized = decimalFromLocalized(newAmount)
            const amount = new Decimal(unlocalized)
            return amount.lte(balance) ? newAmount : decimalToLocalized(balance)
          } catch (err) {
            return null
          }
        })
        .filter(it => it !== null)
    )
    .toProperty(R.always(''))

  const unlocalizedAmount$ = amount$
    .map(decimalFromLocalized)
    .map(amount => amount === '' ? 0 : amount)

  const validation$ = Kefir.combine(
      [unlocalizedAmount$, balanceAfterFees$, reimbursements$],
      (amount, balanceAfterFees, reimbursements) => {
        return validateField(
          amount,
          { required: true, validators: [['validWithdraw', { minimumWithdraw: MINIMUM_WITHDRAWAL_AMOUNT }]] },
          { balanceAfterFees, reimbursements }
        )
      }
    )
    .flatMapLatest(promise => Kefir.fromPromise(promise))
    .toProperty()

  const isValid$ = validation$.map(validation => validation.valid)
  const error$ = U.seq(
      validation$,
      U.view('errors'),
      U.head,
      U.skipUnless(Boolean),
      U.flatMapLatest(({reason, params}) => loc$(['errors', reason], params)))

  const onWithdraw = () => {
    unlocalizedAmount$.take(1).onValue(amount => {
      if (confirm(loc('profile.confirmWithdraw', { amount: decimalToLocalized(amount) }))) {
        withdrawBalance(amount)
          .then(() => showBalanceWithdrawnMessage.set(true))
          .catch(err => addToast('error', `errors.${err.code}`))
      }
    })
  }

  const payslip$ = K(unlocalizedAmount$, reimbursements$, taxPercentage$, age$, getPayslip)

  return (
    <div {...U.classes(styles.wrapper, U.ift(showBalanceWithdrawnMessage, styles.balanceWithdrawnVisible))}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h1>{loc$('balanceWithdraw.withdrawAsSalary')}</h1>
          <p>{loc$('balanceWithdraw.withdrawAsSalaryExplanation')}</p>

          <div className={styles.amount}>
            <input className={styles.input} value={amount$} onChange={e => changeAmount(e.target.value)} />
            <Button theme="balance" disabled={isValid$.map(R.not)} className={styles.button} onClick={onWithdraw} label="profile.withdraw" fullWidth />
          </div>
          
          {U.ift(isValid$.map(R.not), (
            <div className={styles.error}>{error$}</div>
          ))}

          <p>{loc$('balanceWithdraw.withdrawAsSalaryExplanationMore')}</p>

          <div className={styles.goToPayslipCalculator}>
            {replaceLink(
              loc$('balanceWithdraw.goToPayslipCalculator'),
              '/balance/payslip-calculator',
              false
            )}
          </div>
        </div>

        <div className={styles.right}>
          <Payslip payslip={payslip$} />
        </div>
      </div>
      <div className={styles.balanceWithdrawn}>
        <div className={styles.balanceWithdrawnContent}>
          <Success className={styles.successIcon} color="#fff" />
          <div className={styles.balanceWithdrawnTitle}>
            {loc$('balance.balanceWithdrawnTitle')}
          </div>
          <div className={styles.balanceWithdrawnMessage}>
            {loc$('balance.balanceWithdrawnMessage')}
          </div>
        </div>
      </div>
    </div>
  )
}

const TaxCardNotChecked = () => (
  <InlineNotification theme="yellow">
    <p>{loc$('balance.taxCardNotChecked')}</p>
  </InlineNotification>
)

const MissingSsn = () => (
  <InlineNotification theme="yellow" links={[{ label: 'invoice.fillMissingInformation', path: '/profile/edit' }]}>
    <p>{loc$('invoice.missingInformation')}</p>
  </InlineNotification>
)

export default () => {
  return (
    <div>
      {U.ifte(U.not(age$),
        <MissingSsn />,
        U.ifte(U.not(isTaxCardChecked$),
          <TaxCardNotChecked />,
          <BalanceWithdraw />
        )
      )}
    </div>
  )
}
