import React from 'karet'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, TextArea, Checkbox, Select, FileUploadInput, DateInput } from 'utils/forms/controls'
import * as U from 'karet.util'
import * as schema from 'shared/schema'
import { loc$ } from 'utils/i18n'
import styles from './Users.css'

export default ({ initialValues, submitLabel, onSubmit, editing, userId }) => {
  return (
    <Form
      schema={editing ? schema.editUser : schema.newUser}
      context={{ userId }}
      initialValues={initialValues}
      className={styles.form}
      onSubmit={onSubmit}
    >
      <FormField name="email">
        <TextInput label="user.info.email" autoComplete="off" />
      </FormField>
      <FormField name="password">
        <TextInput label="user.info.password" type="password" autoComplete="off" />
        {U.ift(U.not(editing), <div className={styles.inputDescription}>{loc$('user.info.passwordDescription')}</div>)}
      </FormField>
      <FormField name="firstName">
        <TextInput label="user.info.firstName" />
      </FormField>
      <FormField name="lastName">
        <TextInput label="user.info.lastName" />
      </FormField>
      <FormField name="ssn">
        <TextInput label="user.info.ssn" />
      </FormField>
      <FormField name="phoneNumber">
        <TextInput label="user.info.phoneNumber" />
      </FormField>
      <FormField name="streetAddress">
        <TextInput label="user.info.streetAddress" />
      </FormField>
      <FormField name="postalCode">
        <TextInput label="user.info.postalCode" />
      </FormField>
      <FormField name="postOffice">
        <TextInput label="user.info.postOffice" />
      </FormField>
      <FormField name="bankIban">
        <TextInput label="user.info.bankIban" />
      </FormField>
      <FormField name="bankBic">
        <TextInput label="user.info.bankBic" />
      </FormField>
      <FormField name="introduction">
        <TextArea label="user.info.introduction" />
      </FormField>
      <FormField name="jlMemberId">
        <TextInput label="user.info.jlMemberId" />
      </FormField>
      <FormField name="taxCard">
        <FileUploadInput label="user.info.taxCard" folder="taxcard" extensions={['jpg', 'jpeg', 'png', 'pdf']} uploadAsUser={userId} />
      </FormField>
      <FormField name="taxPercentage">
        <TextInput label="user.info.taxPercentage" />
      </FormField>
      <FormField name="picture">
        <FileUploadInput label="user.info.picture" folder="profile" extensions={['jpg', 'jpeg', 'png']} uploadAsUser={userId} />
      </FormField>
      <FormField name="language">
        <Select label="user.info.language" options={[
          ['fi', 'languages.fi'],
          ['en', 'languages.en'],
          ['sv', 'languages.sv']
        ]} />
      </FormField>
      <FormField name="isAdmin">
        <Checkbox label="user.info.isAdmin" />
      </FormField>
      <FormField name="memberJoinedAt">
        <DateInput label="user.info.memberJoinedAt" />
      </FormField>
      <FormField name="memberResignedAt">
        <DateInput label="user.info.memberResignedAt" />
      </FormField>
      <FormSubmit>
        <Button label={submitLabel} large />
      </FormSubmit>
    </Form>
  )
}
