import React from 'karet'
import UserForm from './UserForm.jsx'
import { loc$ } from 'utils/i18n'
import { getUser, editUser } from 'api'
import { showApiRequestError } from 'utils/errors'
import { waitForPromises } from 'utils/containers'
import { addToast } from 'stores/toasts'
import { navigateTo } from 'stores/navigation'

const onSubmit = userId =>
  values => showApiRequestError(async () => {
    await editUser(userId, values)
    addToast('success', 'user.editSuccess')
    navigateTo(`/user/overview/${userId}`)
  })

export default waitForPromises(
  ({ params: { userId }}) => ({ user: getUser(userId) }),
  ({ user }) => (
    <div>
      <h1>{loc$('user.editUser')}</h1>
      <UserForm
        initialValues={user}
        onSubmit={onSubmit(user.id)}
        submitLabel="user.form.editUserSubmit"
        editing={true}
        userId={user.id}
      />
    </div>
  )
)
