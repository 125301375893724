import React from 'karet'
import * as U from 'karet.util'
import { Button } from 'components'
import styles from './ButtonGroup.css'

export default function({ className, value, buttons, onChange }) {
  return (
    <div {...U.classes(className, styles.buttonGroup)}>
      {U.seq(buttons, U.mapIndexed(([val, label], idx) => (
        <Button
          key={idx}
          className={styles.button}
          label={label}
          theme="blue"
          selected={U.equals(val, value)}
          small
          onClick={() => onChange(val)}
        />
      )))}
    </div>
  )
}
