import React from 'karet'
import * as U from 'karet.util'
import { loc$, currency$, datetime$ } from 'utils/i18n'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { Link, Button } from 'components'
import { balance$, reservedBalance$ } from 'stores/user'
import styles from './Reimbursement.css'

export default waitForPromises(
  () => ({
    reimbursements: api.getOwnReimbursements()
  }),
  ({ reimbursements }) => {
    return (
      <div>
        <h2>{loc$('reimbursements.title')}</h2>
        <p className={styles.reimbursementDescription}>{loc$('reimbursements.titleDescription')}</p>
        {reimbursements && (
          <table className={styles.reimbursementHistory}>
            <thead>
              <tr>
              <th>{loc$('reimbursements.info.created')}</th>
                <th>{loc$('reimbursements.info.title')}</th>
                <th>{loc$('reimbursements.info.status')}</th>
                <th>{loc$('reimbursements.info.total')}</th>
                <th>{loc$('reimbursements.info.receipt')}</th>
                <th>{loc$('reimbursements.info.image')}</th>
              </tr>
            </thead>
            <tbody>
              {reimbursements.map((reimbursement, idx) => (
                <tr key={idx}>
                <td>{datetime$(reimbursement.createdAt)}</td>
                  <td>{reimbursement.title}</td>
                  <td>{loc$(`reimbursements.status.${reimbursement.status}`)}</td>
                  <td>{currency$(reimbursement.total)}</td>
                  <td>
                    {reimbursement.receipt && reimbursement.receipt.url
                      ? <a href={reimbursement.receipt.url} target="_blank">{loc$('reimbursements.info.receipt')}</a>
                      : '-'
                    }
                  </td>
                  <td>
                    {reimbursement.image && reimbursement.image.url
                      ? <a href={reimbursement.image.url} target="_blank">{loc$('reimbursements.info.image')}</a>
                      : '-'
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Link to="/balance/reimbursements/new">
          <Button label="profile.makeReimbursement" disabled={U.equals(balance$, 0)} noMargin />
        </Link>

        <p className={styles.reimbursementDescription}>{loc$('profile.reimbursementDescription')}</p>
      </div>
    )
  }
)

