import React from 'karet'
import Kefir from 'kefir'
import R from 'ramda'
import { loc$, currency$, date$, dateRange$ } from 'utils/i18n'
import { navigateTo } from 'stores/navigation'
import { createAction, createActionProperty } from 'utils/store'
import {
    Table,
    TableControls,
    ButtonGroup,
    FilterTable
} from 'components'
import styles from './Overview.css'

export default ({ invoices }) => {
  const tableFields = [
    {
      title: 'invoice.info.id',
      key: 'id'
    },
    {
      title: 'invoice.info.status',
      key: 'status',
      render: ({ status }) => loc$(`invoice.status.${status}`)
    },
    {
      title: 'invoice.info.client',
      key: 'clientName'
    },
    {
      title: 'invoice.info.totalPrice',
      render: ({ totalPrice }) => currency$(totalPrice),
      key: 'totalPrice'
    },
    {
      title: 'invoice.info.referenceNumber',
      key: 'referenceNumber'
    },
    {
      title: 'invoice.info.workDates',
      key: 'dateStart',
      render: ({ dateStart, dateEnd }) => dateRange$([dateStart, dateEnd])
    },
    {
      title: 'invoice.info.created',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id }) => (
        <TableControls
          onView={() => navigateTo(`/invoice/view/${id}`)}
        />
      ),
      className: styles.controls
    }
  ]

  const [ sortTable, onTableSorted$ ] = createAction()
  const [ changeInvoiceStatusFilter, invoiceStatusFilter$ ] = createActionProperty(R.always('all'))

  const sortedBy$ = onTableSorted$.scan(
    (prev,next) => {
      const direction = (prev.key === next.key && prev.direction !== 'DESC') ? 'DESC' : 'ASC'
      return { key: next.key, direction }
    },
    {key: 'id', direction: 'ASC'}
  )

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction})
  }


  const invoices$ = Kefir.constant(invoices).toProperty()

  const [ updateSearchText, searchText ] = createActionProperty(() => '')
  const searchFields = ['id', 'clientName', 'billerName', 'referenceNumber']
  const sortedFilteredInvoices$ = Kefir.combine(
    [invoices$, sortedBy$, invoiceStatusFilter$, searchText],
    (invoices, sortedBy, statusFilter, searchText) => {
      let filteredInvoices = statusFilter === 'all' ? invoices : invoices.filter(invoice => invoice.status === statusFilter)
      if (searchText !== '') {
        const searchFn = field => field && field.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        filteredInvoices = filteredInvoices.filter(invoice => R.any(searchFn, R.values(R.pick(searchFields, invoice))))
      }

      const directionFn = sortedBy.direction === 'ASC' ? R.identity : R.reverse
      const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
      const toIntIfNumber = (val) => isNaN(val) ? val : parseInt(val)
      const sortBy = R.compose(directionFn, R.sortBy(R.compose(toLowerIfString, toIntIfNumber, R.prop(sortedBy.key))))

      return sortBy(filteredInvoices)
    }
  ).toProperty()

  return (
    <div>
      <div className={styles.filters}>
        <ButtonGroup
          className={styles.statusFilter}
          value={invoiceStatusFilter$}
          onChange={changeInvoiceStatusFilter}
          buttons={[
            ['all', 'invoice.filters.all'],
            ['new', 'invoice.status.new'],
            ['approved', 'invoice.status.approved'],
            ['rejected', 'invoice.status.rejected'],
            ['billed', 'invoice.status.billed'],
            ['paid', 'invoice.status.paid']
          ]}
        />
        <FilterTable
          className={styles.textFilter}
          value={searchText}
          onChange={updateSearchText}
          description="invoice.filters.searchDescription"
        />
      </div>
      <Table fields={tableFields} sortedBy={sortedBy$} data={sortedFilteredInvoices$} sortFn={sortFn} />
    </div>
  )
}