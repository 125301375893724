import React from 'karet'
import { classes, ifte, ift, not, isNil } from 'karet.util'
import { Loader } from 'components'
import styles from './TextInput.css'

export default ({
  type = 'text',
  value,
  placeholder,
  className,
  inputClassName,
  valid,
  validated,
  dirty,
  disabled,
  onChange,
  autoComplete,
  setFocusableInput
}) => (
  <div {...classes(
    styles.textinput,
    ift(not(valid), styles.invalid),
    ift(disabled, styles.disabled),
    className
  )}>
    <input
      ref={setFocusableInput}
      value={ifte(isNil(value), '', value)}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      {...classes(inputClassName)}
      {...{type, disabled, autoComplete}}
    />
    <div className={styles.info}>
      {ifte(dirty,
        <Loader size={18} />,
        ift(validated,
          ifte(valid,
            <div {...classes('icon-ok-circled', styles.valid)} />,
            <div {...classes('icon-cancel-circled', styles.invalid)} />)))}
    </div>
  </div>
)
