import React from 'karet'
import * as U from 'karet.util'
import styles from './Home.css'
import { ContentWrapper, FilesList } from 'components'
import { waitForPromises } from 'utils/containers'
import { lang$ } from 'stores/user'
import * as api from 'api'
import En from './En.jsx'
import Fi from './Fi.jsx'
import Sv from './Sv.jsx'

export default waitForPromises(
    () => ({ files: api.getFrontpageFiles() }),
    ({ files }) => (
      <ContentWrapper className={styles.container}>
        {U.ift(U.equals(lang$, 'en'), <En/>)}
        {U.ift(U.equals(lang$, 'fi'), <Fi/>)}
        {U.ift(U.equals(lang$, 'sv'), <Sv/>)}
        <FilesList
            label="home.news.title"
            files={files.news}
        />
        <FilesList
            label="home.instructions.title"
            files={files.instructions}
        />
      </ContentWrapper>
    )
)
