import R from 'ramda'
import React from 'karet'
import Kefir from 'kefir'
import * as L from 'partial.lenses'
import { waitForPromises } from 'utils/containers'
import * as api from 'api'
import { loc, loc$, date$ } from 'utils/i18n'
import { Table, TableControls, ButtonGroup, Button } from 'components'
import { sortedBy$, sortTable, fileTypeFilter$, changeFileTypeFilter } from './filesViewStore'
import { addToast } from 'stores/toasts'
import { createAction } from 'utils/store'
import { navigateTo } from 'stores/navigation'
import { showApiRequestError } from 'utils/errors'
import styles from './Files.css'

const [ deleteFile, deleteFile$ ] = createAction(id => showApiRequestError(async () => {
  if (confirm(loc('files.confirmDelete'))) {
    await api.deleteFile(id)
    addToast('success', 'files.deleteSuccess')
    return id
  }
}))

export default waitForPromises(() => ({
  files: api.getFiles()
}), ({ files }) => {
  const tableFields = [
    {
      title: 'files.info.id',
      key: 'id'
    },
    {
      title: 'files.info.type',
      key: 'type',
      render: ({ type }) => loc$(`files.types.${type}`)
    },
    {
      title: 'files.info.file',
      key: 'upload.name',
      render: ({ upload }) => <a href={upload.url} target="_blank">{upload.name}</a>
    },
    {
      title: 'files.info.showInFrontpage',
      key: 'showInFrontpage',
      render: ({ showInFrontpage }) => loc$(`general.${showInFrontpage ? 'yes' : 'no'}`)
    },
    {
      title: 'files.info.created',
      key: 'createdAt',
      render: ({ createdAt }) => date$(createdAt)
    },
    {
      title: '',
      render: ({ id }) => (
        <TableControls
          onEdit={() => navigateTo(`/files/edit/${id}`)}
          onDelete={() => deleteFile(id)}
        />
      ),
      className: styles.controls
    }
  ]

  const sortFn = async (field) => {
    const sorting = await sortedBy$.take(1).toPromise()
    const direction = (field.key === sorting.key && sorting.direction !== 'DESC')
      ? 'DESC'
      : 'ASC'

    sortTable({key: field.key, direction: direction})
  }

  const files$ = deleteFile$.filter(Boolean).ignoreErrors().scan(
    (files, id) => L.remove([L.required([]), L.find(R.whereEq({ id }))], files),
    files
  )

  const sortedFilteredFiles$ = Kefir.combine(
    [files$, sortedBy$, fileTypeFilter$],
    (files, sortedBy, typeFilter) => {
      const filteredFiles = typeFilter === 'all' ? files : files.filter(file => file.type === typeFilter)
      const directionFn = sortedBy.direction === 'ASC' ? R.identity : R.reverse
      const toLowerIfString = (val) => (val && val.toLowerCase) ? val.toLowerCase() : val
      const toIntIfNumber = (val) => isNaN(val) ? val : parseInt(val)
      const sortBy = R.compose(directionFn, R.sortBy(R.compose(toLowerIfString, toIntIfNumber, R.prop(sortedBy.key))))

      return sortBy(filteredFiles)
    }
  ).toProperty()

  return (
    <div className={styles.filesList}>
      <h1>{loc$('files.filesList')}</h1>
      <div className={styles.filters}>
        <ButtonGroup
          className={styles.typeFilter}
          value={fileTypeFilter$}
          onChange={changeFileTypeFilter}
          buttons={[
            ['all', 'files.filters.all'],
            ['news', 'files.types.news'],
            ['instructions', 'files.types.instructions']
          ]}
        />
      </div>
      <Table
        fields={tableFields}
        sortedBy={sortedBy$}
        data={sortedFilteredFiles$}
        sortFn={sortFn}
      />

      <Button label="files.newFile" onClick={() => navigateTo('/files/new')} large />
    </div>
  )
}, () => false)
