import React from 'karet'
import K, * as U from 'karet.util'
import { Button, Link } from 'components'
import styles from './InlineNotification.css'

export default ({ children, links, showAlert = true, theme = 'yellow', linkDirection = 'horizontal', className }) => {
  return (
    <div {...U.classes(className, styles.notification, U.ift(showAlert, styles.alert), styles[`theme-${theme}`])}>
      <div className={styles.content}>
        {children}
      </div>
      {U.ift(links, (
        <div {...U.classes(styles.buttons, U.ift(linkDirection === 'vertical', styles.vertical))}>
          {U.seqPartial(links, U.mapIndexed((link, idx) => K(link, link => (
            link.path
              ? (
                <div key={idx} className={styles.buttonWrapper}>
                  <Link to={link.path}>
                    <Button
                      className={styles.button}
                      label={link.label}
                      disabled={link.disabled}
                      theme={U.defaultTo('blue', link.theme)}
                      noMargin
                    />
                  </Link>
                </div>
              )
              : (
                <div key={idx} className={styles.buttonWrapper}>
                  <Button
                    className={styles.button}
                    label={link.label}
                    disabled={link.disabled}
                    onClick={link.action}
                    theme={U.defaultTo('blue', link.theme)}
                    noMargin
                  />
                </div>
              )
          ))))}
        </div>
      ))}
    </div>
  )
}
