import React from 'karet'
import { loc$ } from 'utils/i18n'
import { getInvoice, editInvoice } from 'api'
import { addToast } from 'stores/toasts'
import { checkAccess, waitForPromises } from 'utils/containers'
import { showApiRequestError } from 'utils/errors'
import { navigateTo } from 'stores/navigation'
import { isAdmin } from 'utils/acl'
import InvoiceForm from './InvoiceForm.jsx'

const onSubmit = invoiceId => values => showApiRequestError(async () => {
  await editInvoice(invoiceId, values)
  addToast('success', 'invoice.editSuccess')
  navigateTo(`/invoice/view/${invoiceId}`)
})

export default checkAccess(isAdmin, waitForPromises(
  ({ params: { invoiceId }}) => ({ invoice: getInvoice(invoiceId) }),
  ({ invoice }) => (
    <div>
      <h1>{loc$('invoice.editInvoiceHeader', { id: invoice.id })}</h1>
      <InvoiceForm
        initialValues={invoice}
        onSubmit={onSubmit(invoice.id)}
        submitLabel="invoice.editInvoice"
        invoiceId={invoice.id}
        uploadFilesAsUser={invoice.userId}
      />
    </div>
  )
))
