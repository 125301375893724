import Promise from 'bluebird'
import 'isomorphic-fetch'

export function login(email, password) {
  return apiRequest('POST', '/login', { email, password })
}

export function logout() {
  return apiRequest('POST', '/logout')
}

export function createJoinPayment(data) {
  return apiRequest('POST', '/join-payment', data)
}

export function getUsers() {
  return apiRequest('GET', '/user')
}

export function getUser(id) {
  return apiRequest('GET', `/user/${id}`)
}

export function addUser(data) {
  return apiRequest('POST', '/user', data)
}

export function editUser(id, data) {
  return apiRequest('PUT', `/user/${id}`, data)
}

export function deleteUser(id) {
  return apiRequest('DELETE', `/user/${id}`)
}

export function getInvoices() {
  return apiRequest('GET', '/invoices')
}

export function getInvoicesSummary() {
  return apiRequest('GET', '/invoices/summary')
}

export async function getInvoiceInstructions(lang) {
  const res = await fetch(`/static/files/instructions/${lang}.md`)
  const instructions = await res.text()
  return instructions
}

export function getUserInvoices(id) {
  return apiRequest('GET', `/invoices/${id}`)
}

export function getInvoice(id) {
  return apiRequest('GET', `/invoice/${id}`)
}

export function addInvoice(data) {
  return apiRequest('POST', '/invoice', data)
}

export function addInvoiceFromDraft(draftId, data) {
  return apiRequest('POST', `/invoice/from-draft/${draftId}`, data)
}

export function addInvoiceForUser(userId, data) {
  return apiRequest('POST', `/invoice/for-user/${userId}`, data)
}

export function editInvoice(id, data) {
  return apiRequest('PUT', `/invoice/${id}`, data)
}

export function deleteInvoice(id) {
  return apiRequest('DELETE', `/invoice/${id}`)
}

export function approveInvoice(id) {
  return apiRequest('PUT', `/invoice/${id}/approve`)
}

export function rejectInvoice(id, reason) {
  return apiRequest('PUT', `/invoice/${id}/reject`, { reason })
}

export function creditInvoice(id, reason) {
  return apiRequest('PUT', `/invoice/${id}/credit`, { reason })
}

export function markInvoiceAsBilled(id) {
  return apiRequest('PUT', `/invoice/${id}/mark-as-billed`)
}

export function markInvoiceAsPaid(id) {
  return apiRequest('PUT', `/invoice/${id}/mark-as-paid`)
}

export function validateEmail(email, userId) {
  return apiRequest(
    'GET',
    userId
      ? `/validate/user/email/${encodeURIComponent(email)}/${encodeURIComponent(
          userId
        )}`
      : `/validate/user/email/${encodeURIComponent(email)}`
  )
}

export function getProfile() {
  return apiRequest('GET', '/profile')
}

export function updateProfile(data) {
  return apiRequest('PUT', '/profile', data)
}

export function updateProfilePicture(picture) {
  return apiRequest('PUT', '/profile/picture', { picture })
}

export function changeLanguage(language) {
  return apiRequest('PUT', '/profile/language', { language })
}

export function changePassword(data) {
  return apiRequest('PUT', '/profile/password', data)
}

export function withdrawBalance(amount) {
  return apiRequest('POST', '/balance/withdraw', { amount })
}

export function getBalanceHistory() {
  return apiRequest('GET', '/balance/history')
}

export function getUserBalanceHistory(id) {
  return apiRequest('GET', `/balance/history/${id}`)
}

export function getReimbursements() {
  return apiRequest('GET', '/reimbursement')
}

export function getOwnReimbursements() {
  return apiRequest('GET', '/profile/reimbursement')
}

export function getReimbursement(id) {
  return apiRequest('GET', `/reimbursement/${id}`)
}

export function addReimbursement(data) {
  return apiRequest('POST', '/reimbursement', data)
}

export function editReimbursement(id, data) {
  return apiRequest('PUT', `/reimbursement/${id}`, data)
}

export function approveReimbursement(id) {
  return apiRequest('PUT', `/reimbursement/${id}/approve`)
}

export function rejectReimbursement(id, reason) {
  return apiRequest('PUT', `/reimbursement/${id}/reject`, { reason })
}

function prepareFileUpload(
  folderName,
  isPublic,
  uploadAsUser,
  fileName,
  fileType
) {
  const data = { folderName, isPublic, uploadAsUser, fileName, fileType }
  return apiRequest('POST', '/upload/prepare', data)
}

function doUpload(file, signedUrl, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve()
        } else {
          reject()
        }
      }
    }
    xhr.upload.addEventListener('progress', ({ loaded, total }) =>
      onProgress(loaded / total)
    )
    xhr.open('PUT', signedUrl)
    xhr.send(file)
  })
}

export async function uploadFile(
  file,
  folderName,
  isPublic,
  uploadAsUser,
  onProgress
) {
  const { id, url, signedUrl } = await prepareFileUpload(
    folderName,
    isPublic,
    uploadAsUser,
    file.name,
    file.type
  )
  await doUpload(file, signedUrl, onProgress)
  return { id, name: file.name, url }
}

export function getDrafts() {
  return apiRequest('GET', '/invoice-draft')
}

export function getDraft(id) {
  return apiRequest('GET', `/invoice-draft/${id}`)
}

export function addDraft(name, data) {
  return apiRequest('POST', '/invoice-draft', { name, data })
}

export function copyDraft(id, name) {
  return apiRequest('POST', `/invoice-draft/copy/${id}`, { name })
}

export function editDraft(id, data) {
  return apiRequest('PUT', `/invoice-draft/${id}`, { data })
}

export function deleteDraft(id) {
  return apiRequest('DELETE', `/invoice-draft/${id}`)
}

export function getFiles() {
  return apiRequest('GET', '/files')
}

export function getFile(id) {
  return apiRequest('GET', `/files/${id}`)
}

export function getFrontpageFiles() {
  return apiRequest('GET', '/files/frontpage')
}

export function getFilesByType(type) {
  return apiRequest('GET', `/files/by-type/${type}`)
}

export function addFile(data) {
  return apiRequest('POST', '/files', data)
}

export function editFile(id, data) {
  return apiRequest('PUT', `/files/${id}`, data)
}

export function deleteFile(id) {
  return apiRequest('DELETE', `/files/${id}`)
}

export function getContracts() {
  return apiRequest('GET', '/contract')
}

export function getContract(id) {
  return apiRequest('GET', `/contract/${id}`)
}

export function addContract(data) {
  return apiRequest('POST', '/contract', data)
}

export function addContractForUser(userId, data) {
  return apiRequest('POST', `/contract/for-user/${userId}`, data)
}

export function editContract(id, data) {
  return apiRequest('PUT', `/contract/${id}`, data)
}

export function deleteContract(id) {
  return apiRequest('DELETE', `/contract/${id}`)
}

export function getUserContracts(userId) {
  return apiRequest('GET', `/contract?userId=${userId}`)
}

export function getUserPayRequests(userId) {
  return apiRequest('GET', `/pay-request/user/${userId}`)
}

export function markPayRequestAsPaid(id) {
  return apiRequest('PUT', `/pay-request/${id}/mark-as-paid`)
}

export function rejectPayRequest(id, reason) {
  return apiRequest('PUT', `/pay-request/${id}/reject`, { reason })
}

export function ApiError(response) {
  this.code = response.code
  this.error = response
  this.message = 'ApiError'
  this.stack = new Error().stack
}
ApiError.prototype = Object.create(Error.prototype)
ApiError.prototype.constructor = ApiError

function apiRequest(method, endpoint, data) {
  return fetch(`/api/v1${endpoint}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    body: data && JSON.stringify(data),
    credentials: 'include',
  })
    .then(res => res.json())
    .then(response => {
      if (response.status === 'ok') {
        return response.data
      } else {
        return Promise.reject(new ApiError(response))
      }
    })
}
