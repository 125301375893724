import React from 'karet'
import { Form, FormField, FormSubmit, Button } from 'components'
import { TextInput, FileUploadInput } from 'utils/forms/controls'
import { loc$ } from 'utils/i18n'
import * as schema from 'shared/schema'
import styles from './Contracts.css'

export default ({ initialValues, submitLabel, onSubmit, uploadFilesAsUser }) => {
  return (
    <Form
      schema={schema.contract}
      initialValues={initialValues}
      className={styles.form}
      onSubmit={onSubmit}
    >
      <FormField name="description">
        <TextInput label="contracts.info.description" />
      </FormField>
      <FormField name="upload">
        <FileUploadInput
          label="contracts.info.file"
          folder="contracts"
          extensions={['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx']}
          uploadAsUser={uploadFilesAsUser}
        />
      </FormField>
      <FormSubmit>
        <Button label={submitLabel} large />
      </FormSubmit>
    </Form>
  )
}
