import R from 'ramda'
import React, { fromKefir } from 'karet'
import K, * as U from 'karet.util'
import { loc$, date$ } from 'utils/i18n'
import { lang$ } from 'stores/user'
import * as styles from './FilesList.css'

export default ({ label, files, sortBy = R.descend(R.prop('createdAt')) }) =>
  fromKefir(K(files, files => {
    if (!files) {
      return undefined
    }

    const sortedFiles = K(
      sortBy, lang$,
      (sortBy, lang) => R.pipe(
        R.filter(({ translations }) => translations[lang]),
        R.map(({ translations, ...rest }) => ({ ...rest, ...translations[lang] })),
        R.sortBy(sortBy)
      )(files)
    )

    return (
      <div className={styles.files}>
        <h3 className={styles.title}>{loc$(label)}</h3>
        {U.seq(sortedFiles, U.mapIndexed((file, idx) => (
          <div className={styles.file} key={idx}>
            <div className={styles.date}>{date$(file.createdAt)}</div>
            <a href={file.upload.url} target="_blank">{file.title}</a>
          </div>
        )))}
      </div>
    )
  }))
