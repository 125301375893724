import { addToast } from 'stores/toasts'

export function showApiError(err) {
  if (err.code) {
    addToast('error', `errors.${err.code}`)
  }
}

export function showApiRequestError(apiRequestFn, opts = {}) {
  return apiRequestFn().catch(err => {
    showApiError(err)
    if (opts.rethrow) {
      throw err
    }
  })
}
