import { LANGUAGES } from 'shared/config'

export default {
  fields: {
    type: {
      required: true,
      validators: ['validFileType']
    },
    upload: {
      required: true,
      validators: ['validFileUpload'],
      transformers: {
        load: ['fileUploadFromDb'],
        save: ['fileUploadToDb']
      }
    },
    showInFrontpage: {
      required: false,
      validators: ['isBoolean']
    },
    translations: {
      required: true,
      fields: LANGUAGES.reduce((acc, it) => ({
        ...acc,
        [it]: {
          required: true,
          fields: {
            title: {
              required: true,
              validators: ['isString']
            }
          }
        }
      }), {})
    }
  }
}
